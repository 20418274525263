import React, { useState, useEffect, Fragment, useCallback } from 'react';
import Layout from '../layout';
import { Dialog, Transition } from '@headlessui/react';
import { axiosAuth } from "../utils/axios";
import { db } from '../firebase'; // Importa tu instancia de Firestore
import {
    collection,
    doc,
    getDoc,
    getDocs,
    updateDoc,
    onSnapshot,
} from 'firebase/firestore';

const Table = ({ table, onSelectTable }) => {
    // Definir los colores según el estado
    const statusColors = {
        abierto: 'text-green-600',
        reservado: 'text-red-600',
        ocupado: 'text-yellow-600',
    };

    // Definir los colores de fondo según el estado
    const bgColors = {
        abierto: 'bg-green-100',
        reservado: 'bg-red-100',
        ocupado: 'bg-yellow-100',
    };

    // Obtener los colores correspondientes al estado de la mesa
    const textColor = statusColors[table.status] || 'text-green-600';
    const bgColor = bgColors[table.status] || 'bg-green-100';

    return (
        <div
            onClick={() => onSelectTable(table)}
            className="absolute cursor-pointer flex items-center justify-center"
            style={{
                left: table.position.left,
                top: table.position.top,
                width: '80px',
                height: '80px',
                borderRadius: '12px',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 12px 15px -10px',
                background: 'rgba(255, 255, 255, 1)',
            }}
        >
            <div
                className={`flex items-center justify-center rounded-full ${bgColor}`}
                style={{
                    width: '33px',
                    height: '33px',
                }}
            >
                <div className={`text-2xl ${textColor}`}>{table.label}</div>
            </div>
        </div>
    );
};

const TablesPage = () => {
    const [zones, setZones] = useState([]);
    const [selectedZoneId, setSelectedZoneId] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);

    const [isTableModalOpen, setIsTableModalOpen] = useState(false);
    const [selectedTable, setSelectedTable] = useState(null);

    const [searchResults, setSearchResults] = useState([]);
    const [itemSearch, setItemSearch] = useState("");
    const [debouncedItemSearch, setDebouncedItemSearch] = useState(itemSearch);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const [isAddCommentModalOpen, setIsAddCommentModalOpen] = useState(false);
    const [selectedItemToAdd, setSelectedItemToAdd] = useState(null);
    const [itemComment, setItemComment] = useState('');

    const [isJoinTablesModalOpen, setIsJoinTablesModalOpen] = useState(false);
    const [isChangeTableModalOpen, setIsChangeTableModalOpen] = useState(false);

    // Estados para nuevas funcionalidades
    const [customerSearch, setCustomerSearch] = useState("");
    const [debouncedCustomerSearch, setDebouncedCustomerSearch] = useState(customerSearch);
    const [customerResults, setCustomerResults] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [waiters, setWaiters] = useState([]);
    const [selectedWaiter, setSelectedWaiter] = useState("");

    const [orderNumber, setOrderNumber] = useState("");

    // Definir los colores según el estado (para la guía)
    const statusColors = {
        abierto: { text: 'text-green-600', bg: 'bg-green-600', label: 'Abierto' },
        reservado: { text: 'text-red-600', bg: 'bg-red-600', label: 'Reservado' },
        ocupado: { text: 'text-yellow-600', bg: 'bg-yellow-400', label: 'Ocupado' },
    };

    // Función para obtener las zonas desde Firebase
    useEffect(() => {
        const fetchZones = async () => {
            try {
                const zonesCollection = collection(db, 'zones');
                const zonesSnapshot = await getDocs(zonesCollection);
                const zonesData = zonesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                // Inicializar el estado de las mesas si no está definido
                const initializedZones = zonesData.map((zone) => ({
                    ...zone,
                    tables: zone.tables.map((table) => ({
                        ...table,
                        status: table.status || 'abierto',
                        items: table.items || [],
                    })),
                }));

                setZones(initializedZones);
                if (initializedZones.length > 0) {
                    setSelectedZoneId(initializedZones[0].id);
                    setSelectedZone(initializedZones[0]);
                }
            } catch (error) {
                console.error('Error fetching zones:', error);
            }
        };

        fetchZones();

        // Fetch waiters from API or define them statically
        const fetchWaiters = async () => {
            // Mock data si falla la llamada
            setWaiters([
                { id: 1, name: 'Mozo 1' },
                { id: 2, name: 'Mozo 2' },
                { id: 3, name: 'Mozo 3' },
            ]);
        };

        fetchWaiters();
    }, []);

    useEffect(() => {
        const zone = zones.find((z) => z.id === selectedZoneId);
        setSelectedZone(zone);
    }, [selectedZoneId, zones]);

    // Debounce para la búsqueda de artículos
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedItemSearch(itemSearch);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [itemSearch]);

    // Debounce para la búsqueda de clientes
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedCustomerSearch(customerSearch);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [customerSearch]);

    // Búsqueda de artículos
    useEffect(() => {
        if (debouncedItemSearch.trim() !== '') {
            searchItems(debouncedItemSearch);
        } else {
            setSearchResults([]);
        }
    }, [debouncedItemSearch]);

    // Búsqueda de clientes
    useEffect(() => {
        if (debouncedCustomerSearch.trim() !== '') {
            searchCustomers(debouncedCustomerSearch);
        } else {
            setCustomerResults([]);
        }
    }, [debouncedCustomerSearch]);

    // Función para manejar la selección de una mesa
    const handleTableSelect = (table) => {
        setSelectedTable(table);
        setIsTableModalOpen(true);
        setItemSearch(''); // Limpiar el campo de búsqueda al abrir el modal
        setSearchResults([]); // Limpiar los resultados previos

        // Asignar un número de pedido si no lo tiene
        if (!table.orderNumber) {
            const newOrderNumber = generateOrderNumber();
            setOrderNumber(newOrderNumber);
            updateTableOrderNumber(table, newOrderNumber);
        } else {
            setOrderNumber(table.orderNumber);
        }
    };

    // Función para generar un número de pedido (puedes personalizarla)
    const generateOrderNumber = (() => {
        let contador = 0;
        return () => {
            contador = (contador + 1) % 100; // Incrementa y reinicia después de 99
            const dosDigitos = contador.toString().padStart(2, '0');
            return `${dosDigitos}`;
        };
    })();

    // Función para actualizar el número de pedido en Firebase
    const updateTableOrderNumber = async (table, orderNum) => {
        const updatedTable = { ...table, orderNumber: orderNum };
        await updateTableInFirebase(updatedTable);
    };

    // Función para buscar artículos
    const searchItems = useCallback(async (query) => {
        try {
            const response = await axiosAuth.get(`/searchItems?search=${query}&page=1&boole=1`);
            if (response.data.items.rows) {
                setSearchResults(response.data.items.rows);
                setHighlightedIndex(-1); // Restablecer el índice resaltado
            }
        } catch (error) {
            console.error('Error searching items:', error);
        }
    }, []);

    // Función para buscar clientes
    const searchCustomers = useCallback(async (query) => {
        try {
            const response = await axiosAuth.get(`/searchClients?search=${query}`);
            if (response.data.clients.rows) {
                setCustomerResults(response.data.clients.rows);
                console.log(response);
                setHighlightedIndex(-1); // Restablecer el índice resaltado
            }
        } catch (error) {
            console.error('Error searching customers:', error);
        }
    }, []);

    // Manejadores de eventos de teclado
    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
                Math.min(prevIndex + 1, searchResults.length - 1)
            );
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (e.key === "Enter") {
            e.preventDefault();
            if (highlightedIndex >= 0 && searchResults[highlightedIndex]) {
                handleItemSelect(searchResults[highlightedIndex]);
            }
        }
    };

    const handleCustomerKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
                Math.min(prevIndex + 1, customerResults.length - 1)
            );
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (e.key === "Enter") {
            e.preventDefault();
            if (highlightedIndex >= 0 && customerResults[highlightedIndex]) {
                handleCustomerSelect(customerResults[highlightedIndex]);
            }
        }
    };

    // Funciones para manejar la selección de artículos y clientes
    const handleItemSelect = (item) => {
        setSelectedItemToAdd(item);
        setItemComment(''); // Limpiar el comentario previo
        setIsAddCommentModalOpen(true);
    };

    const handleCustomerSelect = (customer) => {
        setSelectedCustomer(customer);
        setCustomerSearch('');
        setCustomerResults([]);
    };

    // Función para agregar un artículo a la mesa y actualizar en Firebase
    const addItemToTable = async () => {
        const itemWithComment = {
            ...selectedItemToAdd,
            comment: itemComment,
            quantity: 1,
            subtotal: selectedItemToAdd.price,
        };

        const updatedTable = {
            ...selectedTable,
            items: [...(selectedTable.items || []), itemWithComment],
            status: 'ocupado', // Cambiar el estado a 'ocupado' al agregar un artículo
        };

        await updateTableInFirebase(updatedTable);

        // Limpiar el buscador y los resultados
        setItemSearch('');
        setSearchResults([]);
        setHighlightedIndex(-1);

        // Cerrar el modal de agregar comentario
        setIsAddCommentModalOpen(false);
    };

    // Función para eliminar un artículo de la mesa y actualizar en Firebase
    const removeItemFromTable = async (index) => {
        const updatedItems = [...selectedTable.items];
        updatedItems.splice(index, 1);

        const updatedTable = {
            ...selectedTable,
            items: updatedItems,
            status: updatedItems.length > 0 ? selectedTable.status : 'abierto',
        };

        await updateTableInFirebase(updatedTable);
    };

    // Funciones para manejar el estado de la mesa
    const handleReserveTable = () => {
        updateTableStatus('reservado');
    };

    const handleFreeTable = async () => {
        const updatedTable = { ...selectedTable, status: 'abierto', items: [], orderNumber: "" };
        await updateTableInFirebase(updatedTable);
        setIsTableModalOpen(false);
    };

    const updateTableStatus = async (status) => {
        const updatedTable = { ...selectedTable, status };
        await updateTableInFirebase(updatedTable);
    };

    // Funciones para unir y cambiar mesas
    const handleJoinTables = () => {
        setIsJoinTablesModalOpen(true);
    };

    const joinTables = async (targetTable) => {
        if (targetTable.id === selectedTable.id) return;

        // Solo unir mesas que están 'ocupado'
        if (targetTable.status !== 'ocupado') {
            alert('Solo puedes unir mesas que estén ocupadas.');
            return;
        }

        const updatedTargetTable = {
            ...targetTable,
            items: [...(targetTable.items || []), ...(selectedTable.items || [])],
        };

        const updatedCurrentTable = {
            ...selectedTable,
            items: [],
            status: 'abierto',
        };

        await updateTableInFirebase(updatedTargetTable);
        await updateTableInFirebase(updatedCurrentTable);

        setIsJoinTablesModalOpen(false);
        setIsTableModalOpen(false);
    };

    const handleChangeTable = () => {
        setIsChangeTableModalOpen(true);
    };

    const changeTable = async (targetTable) => {
        if (targetTable.id === selectedTable.id) return;

        // Solo cambiar a mesas que están 'abierto'
        if (targetTable.status !== 'abierto') {
            alert('Solo puedes cambiar a mesas que estén libres.');
            return;
        }

        const updatedTargetTable = {
            ...targetTable,
            items: [...(selectedTable.items || [])],
            status: 'ocupado',
            orderNumber: selectedTable.orderNumber,
        };

        const updatedCurrentTable = {
            ...selectedTable,
            items: [],
            status: 'abierto',
            orderNumber: "",
        };

        await updateTableInFirebase(updatedTargetTable);
        await updateTableInFirebase(updatedCurrentTable);

        setIsChangeTableModalOpen(false);
        setIsTableModalOpen(false);
    };

    // Función para manejar el cambio de cantidad y actualizar en Firebase
    const handleQuantityChange = async (index, newQuantity) => {
        const updatedItems = [...selectedTable.items];
        const item = updatedItems[index];
        item.quantity = newQuantity;
        item.subtotal = item.price * newQuantity;

        const updatedTable = {
            ...selectedTable,
            items: updatedItems,
        };

        await updateTableInFirebase(updatedTable);
    };

    const handleBillTable = () => {
        alert('Funcionalidad de facturación aún no implementada.');
    };

    // Función auxiliar para actualizar la mesa en Firebase y el estado local
    const updateTableInFirebase = async (updatedTable) => {
        try {
            const zoneDocRef = doc(db, 'zones', selectedZone.id);
            const zoneDoc = await getDoc(zoneDocRef);

            if (zoneDoc.exists()) {
                const zoneData = zoneDoc.data();
                const updatedTables = zoneData.tables.map((t) =>
                    t.id === updatedTable.id ? updatedTable : t
                );

                await updateDoc(zoneDocRef, { tables: updatedTables });

                // Actualizar el estado local
                setZones((prevZones) =>
                    prevZones.map((zone) =>
                        zone.id === selectedZone.id ? { ...zone, tables: updatedTables } : zone
                    )
                );
                setSelectedTable(updatedTable);
            }
        } catch (error) {
            console.error('Error updating table in Firebase:', error);
        }
    };

    // Actualizaciones en tiempo real (opcional)
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'zones'), (snapshot) => {
            const zonesData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            setZones(zonesData);
        });

        return () => unsubscribe();
    }, []);
    return (
        <>
            <Layout>
                <div className="flex h-full">
                    {/* Sidebar de Zonas */}
                    <div className="w-1/4 p-4 overflow-y-auto">
                        <h2 className="text-2xl font-semibold mb-4">Zonas</h2>
                        <div className="flex flex-col space-y-2">
                            {zones.map((zone) => (
                                <button
                                    key={zone.id}
                                    onClick={() => setSelectedZoneId(zone.id)}
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        paddingLeft: '0.75rem',
                                        paddingRight: '0.75rem',
                                        paddingTop: '1rem',
                                        paddingBottom: '1rem',
                                        borderRadius: '0.375rem',
                                        borderWidth: '3px',
                                        borderStyle: 'solid',
                                        color: selectedZoneId === zone.id ? '#2563EB' : '#D1D5DB',
                                        borderColor: selectedZoneId === zone.id ? '#2563EB' : '#D1D5DB',
                                        backgroundColor: selectedZoneId === zone.id ? '#FFFFFF' : '#FFFFFF',
                                        marginTop: '0.5rem',
                                        fontWeight: '700',
                                    }}

                                >
                                    {zone.name}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Contenedor de Mesas */}
                    <div className="flex-grow bg-gray-100 p-6 relative">
                        {selectedZone ? (
                            <>
                                <div className="flex items-center mb-6 space-x-4">
                                    <h1 className="text-3xl font-bold">{selectedZone.name}</h1>
                                    {/* Guía de colores */}
                                    <div className="flex space-x-4">
                                        {Object.keys(statusColors).map((statusKey) => {
                                            const { bg, label } = statusColors[statusKey];
                                            return (
                                                <div key={statusKey} className="flex items-center space-x-1">
                                                    <div
                                                        className={`w-4 h-4 rounded-full ${bg}`}
                                                        title={label}
                                                    ></div>
                                                    <span className="text-sm">{label}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div
                                    className="table-container relative w-full h-full"
                                    style={{
                                        height: '60vh',
                                        position: 'relative',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {selectedZone.tables.map((table) => (
                                        <Table
                                            key={table.id}
                                            table={table}
                                            onSelectTable={handleTableSelect}
                                        />
                                    ))}
                                </div>
                            </>
                        ) : (
                            <p className="text-gray-600">
                                Por favor, selecciona una zona para ver sus mesas.
                            </p>
                        )}
                    </div>
                </div>
            </Layout>

            {/* Modal de Mesa */}
            <Transition.Root show={isTableModalOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setIsTableModalOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10">
                        <div className="flex h-full items-center justify-center p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-6xl h-full sm:max-h-[calc(100vh-4rem)] flex flex-col"
                                >
                                    {/* Cabecera del modal */}
                                    <div className="px-8 pt-6 pb-6 sm:p-8 sm:pb-6 flex-none">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-4 text-left sm:mt-0 sm:text-left w-full">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="flex justify-between items-center !text-3xl leading-8 font-bold text-gray-900 mb-6"
                                                >
                                                    <h3>Mesa {selectedTable?.label} </h3>
                                                    {selectedCustomer && (
                                                        <h3>Cliente: {selectedCustomer.name}</h3>
                                                    )}
                                                    <h3 id="orderNumber">
                                                        Pedido ({orderNumber || ' Generando...'})
                                                    </h3>
                                                </Dialog.Title>

                                                {/* Cabecera del Modal: Buscar Cliente y Seleccionar Mozo */}
                                                <div className="mb-8">
                                                    <div className="flex flex-wrap -mx-2 space-y-4 sm:space-y-0">
                                                        {/* Buscar Cliente */}
                                                        <div className="w-full sm:w-1/2 px-2">
                                                            <div className="flex items-center space-x-4">
                                                                <label
                                                                    className="text-gray-700 !text-2xl font-semibold"
                                                                    htmlFor="customerSearch"
                                                                >
                                                                    Cliente:
                                                                </label>
                                                                <div className="relative flex-grow">
                                                                    <input
                                                                        type="text"
                                                                        id="customerSearch"
                                                                        value={customerSearch}
                                                                        onChange={(e) =>
                                                                            setCustomerSearch(e.target.value)
                                                                        }
                                                                        onKeyDown={handleCustomerKeyDown}
                                                                        className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                                                        placeholder="Buscar cliente..."
                                                                    />
                                                                    {customerResults.length > 0 && (
                                                                        <ul className="absolute z-10 border border-gray-300 rounded mt-1 max-h-48 overflow-y-auto bg-white shadow-lg w-full">
                                                                            {customerResults.map((customer, index) => (
                                                                                <li
                                                                                    key={customer.id}
                                                                                    onClick={() =>
                                                                                        handleCustomerSelect(customer)
                                                                                    }
                                                                                    className={`px-4 py-3 hover:bg-gray-200 cursor-pointer transition duration-200 ${index === highlightedIndex
                                                                                        ? 'bg-gray-100'
                                                                                        : ''
                                                                                        }`}
                                                                                >
                                                                                    {customer.name} - {customer.email}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Seleccionar Mozo */}
                                                        <div className="w-full sm:w-1/2 px-2">
                                                            <div className="flex items-center space-x-4">
                                                                <label
                                                                    className="text-gray-700 !text-2xl font-semibold"
                                                                    htmlFor="waiterSelect"
                                                                >
                                                                    Mozo:
                                                                </label>
                                                                <select
                                                                    id="waiterSelect"
                                                                    value={selectedWaiter}
                                                                    onChange={(e) => setSelectedWaiter(e.target.value)}
                                                                    className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                                                >
                                                                    <option value="">Selecciona un mozo</option>
                                                                    {waiters.map((waiter) => (
                                                                        <option key={waiter.id} value={waiter.id}>
                                                                            {waiter.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Buscador de Artículos */}
                                                <div className="mb-8">
                                                    <div className="flex items-center space-x-4">
                                                        <label
                                                            className="text-gray-700 !text-2xl font-semibold"
                                                            htmlFor="itemSearch"
                                                        >
                                                            Buscar:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="itemSearch"
                                                            value={itemSearch}
                                                            onChange={(e) => setItemSearch(e.target.value)}
                                                            onKeyDown={handleKeyDown}
                                                            className="shadow appearance-none border rounded-lg flex-grow py-3 px-4 text-gray-700 text-xl leading-tight focus:outline-none focus:shadow-outline"
                                                            placeholder="Escribe para buscar artículos"
                                                        />
                                                    </div>
                                                    {searchResults.length > 0 && (
                                                        <ul className="absolute z-10 border border-gray-300 rounded mt-1 max-h-48 overflow-y-auto bg-white shadow-lg w-2/3">
                                                            {searchResults.map((item, index) => (
                                                                <li
                                                                    key={item.id}
                                                                    onClick={() => {
                                                                        const itemWithComment = {
                                                                            ...item,
                                                                            comment: '',
                                                                        };
                                                                        const updatedTable = {
                                                                            ...selectedTable,
                                                                            items: [
                                                                                ...(selectedTable.items || []),
                                                                                itemWithComment,
                                                                            ],
                                                                            status: 'ocupado',
                                                                        };
                                                                        setSelectedTable(updatedTable);
                                                                        const updatedZones = zones.map((zone) => ({
                                                                            ...zone,
                                                                            tables: zone.tables.map((t) =>
                                                                                t.id === selectedTable.id
                                                                                    ? updatedTable
                                                                                    : t
                                                                            ),
                                                                        }));
                                                                        setZones(updatedZones);
                                                                        localStorage.setItem(
                                                                            'zones',
                                                                            JSON.stringify(updatedZones)
                                                                        );
                                                                        setItemSearch('');
                                                                        setSearchResults([]);
                                                                    }}
                                                                    className={`px-4 py-3 hover:bg-gray-200 cursor-pointer transition duration-200 ${index === highlightedIndex
                                                                        ? 'bg-gray-100'
                                                                        : ''
                                                                        }`}
                                                                >
                                                                    {item.name} - {item.price} Gs
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Cabecera de la tabla fija */}
                                    <div className="px-8 flex-none">
                                        <h4 className="text-2xl font-semibold mb-6">
                                            Artículos en la mesa:
                                        </h4>
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full table-fixed">
                                                <colgroup>
                                                    <col className="w-1/6" />
                                                    <col className="w-1/3" />
                                                    <col className="w-1/12" />
                                                    <col className="w-1/12" />
                                                    <col className="w-1/12" />
                                                    <col className="w-1/6" />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Nombre
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-2 text-left text-sm font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Comentario
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-2 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Cantidad
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-2 text-right text-sm font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Precio
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-2 text-right text-sm font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Subtotal
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="px-4 py-2 text-center text-sm font-medium text-gray-500 uppercase tracking-wider"
                                                        >
                                                            Acciones
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>

                                    {/* Cuerpo de la tabla con scroll */}
                                    <div className="px-8 flex-grow overflow-y-auto">
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full table-fixed">
                                                <colgroup>
                                                    <col className="w-1/6" />
                                                    <col className="w-1/3" />
                                                    <col className="w-1/12" />
                                                    <col className="w-1/12" />
                                                    <col className="w-1/12" />
                                                    <col className="w-1/6" />
                                                </colgroup>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {selectedTable?.items &&
                                                        selectedTable.items.length > 0 ? (
                                                        selectedTable.items.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className="px-4 py-2 text-left whitespace-nowrap text-lg">
                                                                    {item.name}
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    <input
                                                                        type="text"
                                                                        value={item.comment || ''}
                                                                        onChange={(e) => {
                                                                            const updatedItems = [
                                                                                ...selectedTable.items,
                                                                            ];
                                                                            updatedItems[index].comment = e.target.value;
                                                                            const updatedTable = {
                                                                                ...selectedTable,
                                                                                items: updatedItems,
                                                                            };
                                                                            setSelectedTable(updatedTable);
                                                                            const updatedZones = zones.map((zone) => ({
                                                                                ...zone,
                                                                                tables: zone.tables.map((t) =>
                                                                                    t.id === selectedTable.id
                                                                                        ? updatedTable
                                                                                        : t
                                                                                ),
                                                                            }));
                                                                            setZones(updatedZones);
                                                                            localStorage.setItem(
                                                                                'zones',
                                                                                JSON.stringify(updatedZones)
                                                                            );
                                                                        }}
                                                                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-lg border-gray-300 rounded-md py-2 px-3"
                                                                        placeholder="Agregar comentario..."
                                                                    />
                                                                </td>
                                                                <td className="px-4 py-2 whitespace-nowrap text-lg text-center">
                                                                    <input
                                                                        type="text"

                                                                        value={item.quantity}
                                                                        onChange={(e) =>
                                                                            handleQuantityChange(
                                                                                index,
                                                                                parseInt(e.target.value)
                                                                            )
                                                                        }
                                                                        className="shadow-sm text-center focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-lg border-gray-300 rounded-md py-2 px-3"
                                                                    />
                                                                </td>
                                                                <td className="px-4 py-2 whitespace-nowrap text-lg text-right">
                                                                    {item.price} Gs
                                                                </td>
                                                                <td className="px-4 py-2 whitespace-nowrap text-lg text-right">
                                                                    {item.subtotal} Gs
                                                                </td>
                                                                <td className="px-4 py-2 whitespace-nowrap text-center">
                                                                    <button
                                                                        onClick={() => removeItemFromTable(index)}
                                                                        className="bg-red-500 hover:bg-red-600 !text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-200"
                                                                    >
                                                                        Eliminar
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td
                                                                colSpan="6"
                                                                className="px-4 py-2 text-center text-gray-500 text-lg"
                                                            >
                                                                No hay artículos en la mesa.
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {/* Pie del modal */}
                                    <div className="px-8 py-6 flex-none">
                                        {/* Botones de Estado y Acciones */}
                                        <div className="flex justify-center space-x-4">
                                            {selectedTable?.status !== 'abierto' && (
                                                <>
                                                    {selectedTable?.status !== 'reservado' && (
                                                        <button
                                                            onClick={handleReserveTable}
                                                            className="bg-yellow-500 hover:bg-yellow-600 !text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-200"
                                                        >
                                                            Reservar
                                                        </button>
                                                    )}

                                                    {(selectedTable?.status === 'ocupado' ||
                                                        selectedTable?.status === 'reservado') && (
                                                            <button
                                                                onClick={handleFreeTable}
                                                                className="bg-green-500 hover:bg-green-600 !text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-200"
                                                            >
                                                                Liberar
                                                            </button>
                                                        )}

                                                    <button
                                                        onClick={handleJoinTables}
                                                        className="bg-purple-500 hover:bg-purple-600 !text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-200"
                                                    >
                                                        Unir Mesas
                                                    </button>

                                                    <button
                                                        onClick={handleChangeTable}
                                                        className="bg-orange-500 hover:bg-orange-600 !text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-200"
                                                    >
                                                        Cambiar Mesa
                                                    </button>

                                                    <button
                                                        onClick={handleBillTable}
                                                        className="bg-indigo-500 hover:bg-indigo-600 !text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-200"
                                                    >
                                                        Facturar
                                                    </button>
                                                </>
                                            )}

                                            {/* Botón Cerrar siempre visible */}
                                            <button
                                                onClick={() => setIsTableModalOpen(false)}
                                                className="bg-gray-500 hover:bg-gray-600 !text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-200"
                                            >
                                                Cerrar
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>


            {/* Modal para Unir Mesas */}
            <Transition.Root show={isJoinTablesModalOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setIsJoinTablesModalOpen(false)}
                >
                    {/* Fondo del modal */}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* Contenido del modal */}
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900"
                                        >
                                            Seleccionar Mesa para Unir
                                        </Dialog.Title>
                                        <div className="mt-4">
                                            {zones.map((zone) => (
                                                <div key={zone.id}>
                                                    <h4 className="font-semibold mt-2">{zone.name}</h4>
                                                    <div className="flex flex-wrap">
                                                        {zone.tables.map((table) => (
                                                            <button
                                                                key={table.id}
                                                                onClick={() => joinTables(table)}
                                                                className={`m-1 px-3 py-2 bg-gray-200 rounded ${table.status !== 'ocupado' ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-300'
                                                                    }`}
                                                                disabled={table.status !== 'ocupado'}
                                                            >
                                                                {table.label}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Modal para Cambiar Mesa */}
            <Transition.Root show={isChangeTableModalOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setIsChangeTableModalOpen(false)}
                >
                    {/* Fondo del modal */}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* Contenido del modal */}
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-gray-900"
                                        >
                                            Seleccionar Mesa para Cambiar
                                        </Dialog.Title>
                                        <div className="mt-4">
                                            {zones.map((zone) => (
                                                <div key={zone.id}>
                                                    <h4 className="font-semibold mt-2">{zone.name}</h4>
                                                    <div className="flex flex-wrap">
                                                        {zone.tables.map((table) => (
                                                            <button
                                                                key={table.id}
                                                                onClick={() => changeTable(table)}
                                                                className={`m-1 px-3 py-2 bg-gray-200 rounded ${table.status !== 'abierto' ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-300'
                                                                    }`}
                                                                disabled={table.status !== 'abierto'}
                                                            >
                                                                {table.label}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default TablesPage;
