import React, { useState, Fragment, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import accountimg from "../assets/img/account.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import { numberWithCommas } from "../utils/constants";

const Accounts = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);

  const [accounts, setAccounts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [banks, setBanks] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [accounTypes, setAccounTypes] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [accountId, setAccountId] = useState(0);
  const [bankId, setBankId] = useState(0);
  const [accounTypeId, setAccounTypeId] = useState(0);
  const [companyId, setCompanyId] = useState(user.companyId);
  const [currencyId, setCurrencyId] = useState(0);
  const [initialBalance, setInitialBalance] = useState(0);
  const [initialBalanceDate, setInitialBalanceDate] = useState("");
  const [owner, setOwner] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [active, setActive] = useState(1);
  const [deleted, setDeleted] = useState(0);

  async function fetchAccounts() {
    //hola williannnnnnnnn
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let accountsRequest = await axiosAuth.get("/accounts?page=" + pageQuery);

    setIsFetching(false);

    if (accountsRequest.data.error) {
      if (accountsRequest.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setAccounts(accountsRequest.data.accounts.rows);
    setTotal(accountsRequest.data.accounts.count);
    setPages(accountsRequest.data.accounts.pages);

    setBanks(accountsRequest.data.banks);
    setAccounTypes(accountsRequest.data.accountypes);
    setCurrencies(accountsRequest.data.currencies);
    setCompanies(accountsRequest.data.companies);
  }

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    fetchAccounts();
  }, [location]);

  const editAccount = (accountId) => {
    setAction("edit");
    setAccountId(accountId);

    let account = accounts.filter((account) => account.id === accountId)[0];

    setBankId(account.bankId);
    setAccounTypeId(account.accounTypeId);
    setCurrencyId(account.currencyId);
    setCompanyId(account.companyId);
    setInitialBalance(account.initialBalance);
    setInitialBalanceDate(account.initialBalanceDate);
    setOwner(account.owner);
    setName(account.name);
    setNumber(account.number);
    setActive(account.active);
    setDeleted(account.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setAccountId(0);
    setName("");
    setBankId(0);
    setAccounTypeId(0);
    setCurrencyId(0);
    setInitialBalance(0);
    setInitialBalanceDate("");
    setOwner("");
    setName("");
    setActive(1);
    setDeleted(0);
  };

  const handleSubmit = async () => {
    if (
      name === "" ||
      owner === "" ||
      bankId == 0 ||
      accounTypeId == 0 ||
      currencyId == 0 ||
      owner == "" ||
      name == 0
    ) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = accountId;
      let newAccountsObj = [];

      let updateAccountRequest = await axiosAuth.put("/accounts", {
        id,
        name,
        number,
        owner,
        bankId,
        accounTypeId,
        companyId,
        currencyId,
        initialBalance,
        initialBalanceDate,
        active,
        deleted,
      });

      if (updateAccountRequest.data.error) {
        if (updateAccountRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      accounts.map((account) => {
        if (account.id == id) {
          newAccountsObj.push(updateAccountRequest.data);
        } else {
          newAccountsObj.push(account);
        }
        return "";
      });

      setAccounts(newAccountsObj);
    } else {
      let createAccountRequest = await axiosAuth.post("/accounts", {
        name,
        number,
        owner,
        bankId,
        accounTypeId,
        companyId,
        currencyId,
        initialBalance,
        initialBalanceDate,
        active,
        deleted,
      });

      if (createAccountRequest.data.error) {
        if (createAccountRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newAccounts = [...accounts];
      newAccounts.push(createAccountRequest.data);
      setAccounts(newAccounts);
    }

    setOpen(false);
  };

  const deleteAccount = async (accountId) => {
    if (window.confirm("Desea eliminar esta cuenta ?")) {
      let accountDeleteRequest = await axiosAuth.delete("/accounts", {
        params: { id: accountId },
      });

      if (accountDeleteRequest.data.error) {
        if (accountDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newAccountsObj = [];

      accounts.map((account) => {
        if (account.id !== accountId) {
          newAccountsObj.push(account);
        } else {
          if (user.roleId == 1) {
            account.deleted = 1;
            newAccountsObj.push(account);
          }
        }
        return "";
      });

      setAccounts(newAccountsObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-[270px] pb-4 rounded-3xl"
                    style={{ marginTop: 150 }}
                  >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} cuenta
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded w-full">
                                {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="AccountId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="AccountId"
                                      type="text"
                                      value={accountId}
                                    />
                                  </div>
                                )}
                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="number"
                                  >
                                    Numero
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="number"
                                    type="text"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize"
                                    id="name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="owner"
                                  >
                                    Propietario
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="owner"
                                    type="text"
                                    value={owner}
                                    onChange={(e) => setOwner(e.target.value)}
                                  />
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="bankId"
                                    >
                                      Empresa
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="companyId"
                                      value={companyId}
                                      onChange={(e) => {
                                        setCompanyId(e.target.value);
                                      }}
                                    >
                                      <option>Elija una empresa</option>
                                      {companies.map((company) => {
                                        return (
                                          <option value={company.id}>
                                            {company.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                )}

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="bankId"
                                  >
                                    Banco
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="bankId"
                                    value={bankId}
                                    onChange={(e) => {
                                      setBankId(e.target.value);
                                    }}
                                  >
                                    <option>Elija un banco</option>
                                    {banks.map((bank) => {
                                      return (
                                        <option value={bank.id}>
                                          {bank.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="accounTypeId"
                                  >
                                    Tipo de cuenta
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="accounTypeId"
                                    value={accounTypeId}
                                    onChange={(e) => {
                                      setAccounTypeId(e.target.value);
                                    }}
                                  >
                                    <option>Elija un tipo de cuenta</option>
                                    {accounTypes.map((accounType) => {
                                      return (
                                        <option value={accounType.id}>
                                          {accounType.description}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="bankId"
                                  >
                                    Moneda
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="bankId"
                                    value={currencyId}
                                    onChange={(e) => {
                                      setCurrencyId(e.target.value);
                                    }}
                                  >
                                    <option>Elija una moneda</option>
                                    {currencies.map((currency) => {
                                      return (
                                        <option value={currency.id}>
                                          {currency.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="initialBalance"
                                  >
                                    Monto inicial
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="initialBalance"
                                    type="number"
                                    value={numberWithCommas(initialBalance)}
                                    onChange={(e) =>
                                      setInitialBalance(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="initialBalanceDate"
                                  >
                                    Monto inicial (Fecha)
                                  </label>
                                  <input
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="initialBalanceDate"
                                    type="date"
                                    value={initialBalanceDate.substring(0, 10)}
                                    onChange={(e) =>
                                      setInitialBalanceDate(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="block text-gray-700 text-sm font-bold"
                                    htmlFor="active"
                                  >
                                    Activa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="active"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="deleted"
                                    >
                                      Borrado
                                    </label>
                                    <select
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="deleted"
                                      value={deleted}
                                      onChange={(e) =>
                                        setDeleted(parseInt(e.target.value))
                                      }
                                    >
                                      <option value={0}>No</option>
                                      <option value={1}>Si</option>
                                    </select>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <h1 className="ml-5 mt-5 text-3xl text-white title secondary-color font-family-thin">
          Cuentas
        </h1>
        <DateComponent className="sm:block hidden" />

        <button
          type="button"
          className="relative right-[25px] sm:right-[50px] bottom-[10px] inline-flex w-[68px] float-right m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 !text-[10px] text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
          style={{ alignItems: "center" }}
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          <img src={accountimg} className="w-8" />
          CREAR CUENTA
        </button>

        <div className="w-[98%] p-5 mx-auto overflow-auto">
          <table className="w-[98%] accounts-table table-auto mx-auto w-full text-xs">
            <thead>
              <tr>
                <th className="uppercase">Id</th>
                <th className="uppercase">Numero</th>
                <th className="uppercase">Banco</th>
                <th className="uppercase">Tipo de Cuenta</th>
                <th className="uppercase">Moneda</th>
                <th className="uppercase">Propietario</th>
                <th className="uppercase">Nombre</th>
                <th className="uppercase">Monto Inicial</th>
                <th className="uppercase">Fecha Monto Inicial</th>
                <th className="uppercase">Activa</th>
                {user.roleId == 1 && <th className="uppercase">Borrado</th>}
                <th className="uppercase">Fecha Alta</th>
                <th className="uppercase">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {accounts.length > 0 &&
                !isFetching &&
                accounts?.map((account) => {
                  return (
                    <tr key={account.id}>
                      <td>{account.id}</td>
                      <td>{account.number}</td>
                      <td>{account.bank.name}</td>
                      <td>{account.accountype.description}</td>
                      <td>{account.currency.name}</td>
                      <td>{account.owner}</td>
                      <td>{account.name}</td>
                      <td>{numberWithCommas(account.initialBalance)}</td>
                      <td>
                        {account.initialBalanceDate
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td>{account.active ? "Si" : "No"}</td>
                      {user.roleId == 1 && (
                        <td>{account.deleted ? "Si" : "No"}</td>
                      )}
                      <td>
                        {account.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editAccount(account.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 secondary-color cursor-pointer"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteAccount(account.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 secondary-color cursor-pointer"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {accounts.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={12} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            className="accounts-pagination-container"
            path="cuentas"
            page={page}
            pages={pages}
            total={total}
          />
        </div>
      </Layout>
    </>
  );
};

export default Accounts;
