import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { axiosAuth } from "../utils/axios";
import logo from "../assets/img/imgtopfit.jpg";
import { numberWithCommas, numeroALetras } from "../utils/constants";
import { setToken, setUser } from "../store/slices/mainSlice";
import { usePDF } from "react-to-pdf";
import "../assets/css/pdftopfit.css";

const SalePDF = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { saleId } = useParams();
    const [sale, setSale] = useState([]);
    const [total, setTotal] = useState(0);
    const [saledate, setSaleDate] = useState("");
    const date = new Date().toLocaleDateString();

    const { toPDF, targetRef } = usePDF({
        filename: "FACTURA_" + date + ".pdf",
    });

    const fetchSale = async () => {
        let saleR = await axiosAuth("/sales/" + saleId);

        if (saleR.data.error) {
            if (saleR.data.message === "token") {
                await axiosAuth.post("/logout");
                dispatch(setUser(null));
                dispatch(setToken(null));
            }
        }

        let totalSum = 0;

        saleR.data.sale.salesdetails.map((detail) => {
            totalSum += detail.quantity * detail.price;
        });

        while (String(saleR.data.sale.number).length < 7) {
            saleR.data.sale.number = "0" + String(saleR.data.sale.number);
        }

        if (saleR.data.sale.salesdetails.length < 5) {
            let toAdd = 6 - saleR.data.sale.salesdetails.length;
            let toMap = [...Array(toAdd).keys()];

            toMap.map((itm) => {
                saleR.data.sale.salesdetails.push({
                    quantity: "",
                    item: { name: "" },
                    price: "",
                });
            });
        }

        setTotal(totalSum);
        setSale(saleR.data.sale);

        let dateFormat = new Date(saleR.data.sale.createdAt);
        //dateFormat.setDate(dateFormat.getDate() + 1);

        let months = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Setiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ];
        let day =
            String(dateFormat.getDate()).length == 1
                ? "0" + dateFormat.getDate()
                : dateFormat.getDate();
        setSaleDate(
            day + " " + months[dateFormat.getMonth()] + " " + dateFormat.getFullYear()
        );

        // to upload again

       setTimeout(function () {
               toPDF();
             setTimeout(function () {
                window.close();
               }, 1500);
               }, 1500); 
    };

    useEffect(function () {
        fetchSale();
    }, []);

    return (
        <>
            {/*   <h1
        className="absolute top-44 left-72"
        style={{
          zIndex: 9,
          color: "rgba(0,0,0,0.3)",
          transform: "rotate(-25deg)",
          fontSize: "62px",
          fontWeight: "bold",
        }}
      >
        ORIGINAL
      </h1> */}
            <div
                style={{
                    fontFamily: "Arial !important",
                    width: "860px",
                    height: "400px",
                }}
                ref={targetRef}
            >
                {/* <p
          className="!text-[5px]"
          style={{
            zIndex: 99,
            position: "relative",
            left: -415,
            top: -43,
            transform: "rotate(-90deg)",
          }}
        >
          Habilitacion Nro. 2425 - Grafica CANTERO S.R.L - R.U.C. 80103548-1
          Tel/Fax: (0294) 220 329 - E-mail: canterogabriel521@gmail.com
        </p> */}
                {/*  <p
          className="!text-[5px]"
          style={{
            zIndex: 99,
            top: -30,
            left: -408,
            position: "relative",
            transform: "rotate(-90deg)",
            fontSize: "5px !important",
          }}
        >
          Itaugua - Ruta Mariscal Estigarribia e/ Carlos Antonio Lopez y Gaspar
          Rodriguez de Francia Nro. 463 - 1000 FACTURAS 50X3 DEL [7201 - 8200]
          ENERO/24
        </p> */}
                <div
                    className="bg-white w-[20cm] h-[12cm] p-2 ml-5 relative mt-5"
                    style={{ transform: "scale(0.98)" }}
                >
                    <div className="flex">
                        {/*   <div
              className="w-[19%] text-[10px] text-center"
              style={{
                borderLeft: "1px solid rgba(0,0,0,0.5)",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
                borderRight: "2px solid rgba(0,0,0,0.5)",
                borderTop: "1px solid rgba(0,0,0,0.5)",
              }}
            >
             
               <h2 className="text-lg font-bold">OCUS S.A.</h2>
              <h3 className="mb-4">
                Análisis, diseño y programación <br />
                de sistemas informáticos
              </h3>
              <p style={{ fontSize: "10px" }}>Vicente Paul c/ Carmen Peña</p>
              <p style={{ fontSize: "10px" }}>Tel.: +595 984 317 179</p>
              <p style={{ fontSize: "10px" }}>Itauguá - Paraguay</p> 
            </div>  */}
                        <div
                            className="w-full flex flex-col text-[10px] text-center"

                        >
                            <div className="flex mb-2">
                                <div className="w-[69.8%] h-[100px] text-[9px] text-left pb-3 pt-3 pl-4 
                  flex flex-row justify-between mr-2"
                                    style={{
                                        border: "1px solid rgba(0,0,0,0.5)",
                                        borderRadius: "12px"
                                    }}
                                >

                                    <img
                                        src={logo}
                                        className="w-64"
                                        style={{ margin: "0px auto" }}
                                    />
                                    <div className="flex flex-col w-[100%] ">


                                        <div className="flex justify-end mr-8">

                                            <label className="titulo" >
                                                GIMNASIO TOPFIT
                                            </label>

                                        </div>
                                        <div className="flex justify-end mr-8">
                                            <label>
                                                de Jose Rodolfo Gómez Giménez
                                            </label>
                                        </div>
                                        <div className="flex justify-end mr-8">
                                            <label>
                                                Actividades Deportivas
                                            </label>
                                        </div>
                                        <div className="flex justify-end mr-8">
                                            <label>
                                                25 de Mayo entre Gral Aquino y Gral Bruguez N° 1970
                                            </label>
                                        </div>
                                        <div className="flex justify-end mr-8">
                                            <label>
                                                E-mail: administracion@topfitasuncion.com
                                            </label>
                                        </div>
                                        <div className="flex justify-end mr-8">
                                            <label>
                                                Cel: (0983) 483 500 - Asuncion - Paraguay
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="w-[30%] text-[10px] text-right pr-3 justify-center items-center flex"
                                    style={{
                                        border: "1px solid rgba(0,0,0,0.5)",
                                        borderRadius: "12px"
                                    }}
                                >
                                    <div className="text-left relative top-[-15px]">
                                        <div className="text-center"
                                        >
                                            <p style={{ lineHeight: "11px" }}>
                                                Timbrado N°. 17410035
                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ lineHeight: "11px" }}>

                                            </p>
                                        </div>
                                        <div>
                                            <p style={{ lineHeight: "11px" }}>
                                                Fecha Inicio Vigencia: 07/08/2024
                                            </p>
                                        </div>
                                        <div className="text-center">
                                            <p style={{ lineHeight: "11px" }}>
                                                Fecha Fin Vigencia: 31/08/2025
                                            </p>
                                        </div>
                                        <div className="text-center">
                                            <p style={{ lineHeight: "12px", marginTop: 2 }}>
                                                R.U.C N° 2907961-6
                                            </p>
                                        </div>
                                        <div className="text-center">
                                            <p className="factura" style={{ lineHeight: "12px", marginTop: 5 }}>
                                                F   A  C  T  U  R A
                                            </p>
                                        </div>
                                    </div>

                                    <p className="text-lg absolute top-[85px]  expedition">

                                        {sale.expeditionPoint}{" N°"}
                                        <span className="text-lg expedition">{sale.number}</span>

                                    </p>
                                </div>

                            </div>
                            <div className="w-[100%] h-[80px] text-[10px] text-right pr-2 justify-between items-center flex mb-2 flex-row"
                                style={{
                                    border: "1px solid rgba(0,0,0,0.5)",
                                    borderRadius: "12px"
                                }}>
                                <div className="flex flex-col w-[60%] mt-[-1px] ">
                                    <div className="flex justify-start ml-4 mb-2">
                                        <p>
                                            FECHA DE EMISION:{" "}
                                            <i>{saledate}</i>
                                        </p>
                                    </div>
                                    <div className="flex justify-start ml-4 mb-2">
                                        <p>
                                            NOMBRE O RAZÓN SOCIAL:{" "}
                                            <i>{sale?.client?.name}</i>
                                        </p>
                                    </div>
                                    <div className="flex justify-start ml-4 mb-2">
                                        <p>
                                            DIRECCION:{" "}
                                            <i>{sale?.client?.address}</i>
                                        </p>
                                    </div>
                                    <div className="flex justify-start ml-4 mb-2">
                                        <p>
                                            NOTA DE REMISIÓN N°:{" "}
                                            <i></i>
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-col w-[40%]">
                                    <div className="flex justify-start mb-2">
                                        <p>
                                            COND. DE VENTA: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i>CONTADO:&nbsp;&nbsp;{sale?.salecondition?.description === "CONTADO" ? "X" : ""}</i>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            CREDITO:&nbsp;&nbsp;{sale?.salecondition?.description === "CREDITO" ? "X" : ""}
                                        </p>
                                    </div>
                                    <div className="flex justify-start mb-2 ">
                                        <p>
                                            VENCIMIENTO:
                                        </p>
                                    </div>
                                    <div className="flex justify-start mb-2">
                                        <p>
                                            RUC/CI: {sale?.client?.ruc}
                                        </p>
                                    </div>
                                    <div className="flex justify-start mb-2">
                                        <p>
                                            TEL: {sale?.client?.phone}
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div className="flex">
                                <div
                                    className="w-[70%] text-[11px] text-left pb-1 pt-1 pl-1 flex flex-row"
                                    style={{
                                        borderTop: "1px solid rgba(0,0,0,0.5)",
                                        borderBottom: "1px solid rgba(0,0,0,0.5)",
                                        borderLeft: "1px solid rgba(0,0,0,0.5)",
                                        borderTopLeftRadius: "12px"

                                    }}
                                >
                                    <div className="w-full flex flex-row">

                                        <p className="w-[10%] text-[9px] text-center">
                                            CANT.
                                        </p>


                                        <p className="w-[75%] text-[9px] text-center">
                                            DESCRIPCION
                                        </p>
                                        <p className="w-[15%] text-[9px] text-center">
                                            PR. UNITARIO
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="w-[29.8%] text-[10px] text-right"
                                    style={{
                                        borderTop: "1px solid  rgba(0,0,0,0.5)",
                                        borderBottom: "1px solid  rgba(0,0,0,0.5)",
                                        borderRight: "1px solid  rgba(0,0,0,0.5)",
                                        borderTopRightRadius: "12px"
                                    }}
                                >
                                    <div className="w-full flex flex-row justify-around">
                                        <p className="w-[33%] text-[9px] text-center pt-1">
                                            EXENTAS
                                        </p>
                                        <p className="w-[33%] text-[9px] text-center pt-1">
                                            5%
                                        </p>
                                        <p className="w-[33%] text-[9px] text-center pt-1">
                                            10%
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {sale?.salesdetails?.map((saledetail) => (
                                <div className="flex">
                                    <div
                                        className="w-[70%] text-[10px] text-left flex flex-row"
                                        style={{
                                            borderRight: "1px solid rgba(0,0,0,0.5)",
                                            borderLeft: "1px solid rgba(0,0,0,0.5)"
                                        }}
                                    >
                                        <div className="w-full flex flex-row">
                                            <p className="w-[10%] text-center">
                                                {saledetail.quantity}
                                            </p>
                                            <p
                                                className="w-[75%] text-left pl-1"
                                                style={{
                                                    borderLeft: "1px solid rgba(0,0,0,0.5)",
                                                    borderRight: "1px solid rgba(0,0,0,0.5)",
                                                }}
                                            >
                                                {saledetail.item.name}
                                            </p>
                                            <p className="w-[15%] text-center">
                                                {numberWithCommas(saledetail.price)}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="w-[29.8%] text-[10px] text-right"
                                        style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                                    >
                                        <div className="w-full flex flex-row justify-around">
                                            <p className="w-[33%] text-center pt-1">
                                                <b></b>
                                            </p>
                                            <p
                                                className="w-[33%] text-center pt-1"
                                                style={{
                                                    borderLeft: "1px solid rgba(0,0,0,0.5)",
                                                    borderRight: "1px solid rgba(0,0,0,0.5)",
                                                }}
                                            >
                                                <b></b>
                                            </p>
                                            <p
                                                className="w-[33%] text-[10px] text-center"
                                                style={{
                                                    color:
                                                        saledetail.price * saledetail.quantity == 0
                                                            ? "transparent"
                                                            : "black",
                                                }}
                                            >
                                                {numberWithCommas(
                                                    saledetail.price * saledetail.quantity
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div
                                className="flex"
                                style={{
                                    borderTop: "1px solid rgba(0,0,0,0.5)",
                                    borderBottom: "1px solid rgba(0,0,0,0.5)",
                                    borderLeft: "1px solid rgba(0,0,0,0.5)"
                                }}
                            >
                                <div
                                    className="w-[70%] text-[11px] text-left flex flex-row"
                                    style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                                >
                                    <div
                                        className="w-full flex flex-row"
                                        style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                                    >
                                        <p className="w-[100%] text-[9px] text-left p-1">
                                            SUBTOTALES
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="w-[29.8%] text-[10px] text-right"
                                    style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                                >
                                    <div className="w-full flex flex-row justify-around h-[100%]">
                                        <p className="w-[33%] text-center pt-1">
                                            <b></b>
                                        </p>
                                        <p
                                            className="w-[33%] text-center pt-1"
                                            style={{
                                                borderLeft: "1px solid rgba(0,0,0,0.5)",
                                                borderRight: "1px solid rgba(0,0,0,0.5)",
                                            }}
                                        >
                                            <b></b>
                                        </p>
                                        <p className="w-[32.9%] text-[10px] text-center pt-1">
                                            {numberWithCommas(total)}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex" style={{ borderLeft: "1px solid rgba(0,0,0,0.5)" }}>
                                <div className="w-[70%] text-[11px] text-left flex flex-row">
                                    <div className="w-full flex flex-row">
                                        <p className="w-[100%] text-left text-[9px] p-1">
                                            TOTAL A PAGAR: &nbsp;&nbsp;
                                            {numeroALetras(total, {
                                                plural: "GUARANIES",
                                                singular: "GUARANI",
                                                centPlural: "CENTAVOS",
                                                centSingular: "CENTAVO",
                                            })}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="w-[29.8%] text-[10px] text-right"
                                    style={{ borderRight: "1px solid rgba(0,0,0,0.5)" }}
                                >
                                    <div className="w-full flex flex-row justify-end h-[100%]">
                                        <p className="w-[33%] text-center pt-1">
                                            {numberWithCommas(total)}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="flex h-[25px]"
                                style={{
                                    width: "99.8%",
                                    borderTop: "1px solid rgba(0,0,0,0.5)",
                                    borderRight: "1px solid rgba(0,0,0,0.5)",
                                    borderBottom: "1px solid rgba(0,0,0,0.5)",
                                    borderLeft: "1px solid rgba(0,0,0,0.5)",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px"

                                }}
                            >
                                <div className="w-full text-[11px] text-left flex flex-row "
                                >
                                    <div className="w-full flex flex-row">
                                        <p className="w-[33%] text-left p-1">
                                            LIQUIDACION DEL IVA
                                        </p>
                                        <p className="w-[20%] text-left p-1 text-[9px]">
                                            5%:
                                            {numberWithCommas(sale.vat5)}
                                        </p>
                                        <p className="w-[20%] text-left p-1 text-[9px]">
                                            10%: {numberWithCommas(sale.vat10)}
                                        </p>
                                        <p className="w-[20%] text-left p-1 text-[9px]">
                                            TOTAL IVA: {numberWithCommas(sale.vat10)}
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="w-full text-[11px] text-left flex flex-row ">
                                <div className="flex flex-col w-[65%]">
                                  <div>
                                    <p>
                                  <b>xpress</b> arte HAB. N°:1.1851 R.U.C: 50081413 -9
                                  </p>
                                  </div>
                                  <div>
                                   <p>
                                   
                                  de Elva Rosales EEUU N° 1521 C/ 3era - Cel.(0984) 556 714 - 300 Numeros del 0001 al 0300
                                  </p> 
                                  </div>
                                </div>
                               <div className="flex flex-row w-[35%]">
                                  <p><b>Original:</b>Cliente</p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <p><b>Duplicado:</b>Archivo Tributario</p> 
                                  
                               </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default SalePDF;
