import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    Fragment,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { v4 as uuidv4 } from "uuid";
import Table from "./Table";
import { db } from "../firebase"; // Import your Firebase configuration
import {
    collection,
    doc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    onSnapshot,
} from "firebase/firestore";

const BarConfig = () => {
    const [zones, setZones] = useState([]);
    const [isZoneModalOpen, setIsZoneModalOpen] = useState(false);
    const [isTableModalOpen, setIsTableModalOpen] = useState(false);
    const [currentZone, setCurrentZone] = useState(null);
    const [zoneName, setZoneName] = useState("");
    const [tableLabel, setTableLabel] = useState("");
    const [selectedZoneId, setSelectedZoneId] = useState(null);
    const [isGridActive, setIsGridActive] = useState(false);
    const containerRef = useRef(null);

    // Estado para mensajes de error
    const [zoneError, setZoneError] = useState("");
    const [tableError, setTableError] = useState("");

    // Dimensiones de la mesa
    const TABLE_WIDTH = 80;
    const TABLE_HEIGHT = 80;
    const CONTAINER_PADDING = 20;

    // Cargar zonas desde Firebase
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "zones"), (snapshot) => {
            const zonesData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setZones(zonesData);
            if (zonesData.length > 0) {
                setSelectedZoneId((prevSelectedZoneId) => {
                    // Keep the selected zone if it still exists
                    const zoneExists = zonesData.some(
                        (zone) => zone.id === prevSelectedZoneId
                    );
                    return zoneExists ? prevSelectedZoneId : zonesData[0].id;
                });
            } else {
                setSelectedZoneId(null);
            }
        });

        return () => unsubscribe();
    }, []);

    // Abrir modal de zona
    const openZoneModal = useCallback((zone = null) => {
        setZoneError("");
        if (zone) {
            setZoneName(zone.name);
            setCurrentZone(zone);
        } else {
            setZoneName("");
            setCurrentZone(null);
        }
        setIsZoneModalOpen(true);
    }, []);

    // Abrir modal de mesa
    const openTableModal = useCallback((zone) => {
        setTableError("");
        setTableLabel("");
        setCurrentZone(zone);
        setIsTableModalOpen(true);
    }, []);

    // Encontrar posición inicial para nueva mesa
    const findInitialPosition = () => {
        return { left: CONTAINER_PADDING, top: CONTAINER_PADDING };
    };

    // Manejar envío del formulario de zona
    const handleZoneSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            if (zoneName.trim() === "") {
                setZoneError("Por favor, ingresa un nombre para la zona.");
                return;
            }

            if (currentZone) {
                // Editar zona existente
                try {
                    await updateDoc(doc(db, "zones", currentZone.id), {
                        name: zoneName,
                    });
                } catch (error) {
                    console.error("Error updating zone: ", error);
                    setZoneError("Error al actualizar la zona.");
                }
            } else {
                // Agregar nueva zona con mesas automáticamente
                const rows = 2;
                const cols = 4;
                const spacing = 20;
                const initialTables = [];

                for (let i = 0; i < rows; i++) {
                    for (let j = 0; j < cols; j++) {
                        const tableNumber = i * cols + j + 1;
                        const table = {
                            id: uuidv4(),
                            label: `${tableNumber}`,
                            position: {
                                left: CONTAINER_PADDING + j * (TABLE_WIDTH + spacing),
                                top: CONTAINER_PADDING + i * (TABLE_HEIGHT + spacing),
                            },
                        };
                        initialTables.push(table);
                    }
                }

                try {
                    const zoneRef = await addDoc(collection(db, "zones"), {
                        name: zoneName,
                        tables: initialTables,
                    });
                    setSelectedZoneId(zoneRef.id);
                } catch (error) {
                    console.error("Error adding zone: ", error);
                    setZoneError("Error al agregar la zona.");
                }
            }

            setIsZoneModalOpen(false);
        },
        [zoneName, currentZone]
    );

    // Manejar envío del formulario de mesa
    const handleTableSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            if (tableLabel.trim() === "") {
                setTableError("Por favor, ingresa un identificador para la mesa.");
                return;
            }

            const duplicate = currentZone.tables.find(
                (table) =>
                    table.label.toLowerCase() === tableLabel.trim().toLowerCase()
            );
            if (duplicate) {
                setTableError("Este identificador ya existe en la zona seleccionada.");
                return;
            }

            const initialPosition = findInitialPosition();

            const newTable = {
                id: uuidv4(),
                label: tableLabel,
                position: initialPosition,
            };

            try {
                const updatedTables = [...currentZone.tables, newTable];
                await updateDoc(doc(db, "zones", currentZone.id), {
                    tables: updatedTables,
                });
            } catch (error) {
                console.error("Error adding table: ", error);
                setTableError("Error al agregar la mesa.");
            }

            setIsTableModalOpen(false);
        },
        [tableLabel, currentZone]
    );

    // Eliminar una zona
    const deleteZone = useCallback(
        async (zoneId) => {
            if (window.confirm("¿Estás seguro de que deseas eliminar esta zona?")) {
                try {
                    await deleteDoc(doc(db, "zones", zoneId));
                    if (selectedZoneId === zoneId) {
                        setSelectedZoneId(null);
                    }
                } catch (error) {
                    console.error("Error deleting zone: ", error);
                    alert("Error al eliminar la zona.");
                }
            }
        },
        [selectedZoneId]
    );

    // Eliminar una mesa
    const deleteTable = useCallback(
        async (zoneId, tableId) => {
            if (window.confirm("¿Estás seguro de que deseas eliminar esta mesa?")) {
                try {
                    const zone = zones.find((z) => z.id === zoneId);
                    if (zone) {
                        const updatedTables = zone.tables.filter(
                            (table) => table.id !== tableId
                        );
                        await updateDoc(doc(db, "zones", zoneId), {
                            tables: updatedTables,
                        });
                    }
                } catch (error) {
                    console.error("Error deleting table: ", error);
                    alert("Error al eliminar la mesa.");
                }
            }
        },
        [zones]
    );

    // Actualizar posición de una mesa
    const updateTablePosition = useCallback(
        async (zoneId, tableId, newPosition) => {
          try {
            const zone = zones.find((z) => z.id === zoneId);
            if (zone) {
              const updatedTables = zone.tables.map((table) =>
                table.id === tableId
                  ? { ...table, position: { left: newPosition.x, top: newPosition.y } }
                  : table
              );
              await updateDoc(doc(db, "zones", zoneId), {
                tables: updatedTables,
              });
            }
          } catch (error) {
            console.error("Error updating table position: ", error);
            alert("Error al actualizar la posición de la mesa.");
          }
        },
        [zones]
      );

    // Zona seleccionada
    const selectedZone = zones.find((zone) => zone.id === selectedZoneId);

    return (
        <Layout>
            <div className="flex h-screen">
                {/* Sidebar de Zonas */}
                <div className="w-1/4 bg-gray-200 p-4 overflow-y-auto">
                    <h2 className="text-2xl font-semibold mb-4">Zonas</h2>

                    {/* Botón para Agregar Zona */}
                    <button
                        onClick={() => openZoneModal()}
                        className="w-full flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 hover:bg-blue-700 transition-colors"
                    >
                        <svg
                            className="w-5 h-5 mr-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                        >
                            <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0V11H7a1 1 0 110-2h3V6a1 1 0 011-1z" />
                        </svg>
                        Agregar Zona
                    </button>

                    {/* Botón para Activar/Desactivar Cuadrícula */}
                    <button
                        onClick={() => setIsGridActive(!isGridActive)}
                        className={`w-full flex items-center justify-center text-white px-4 py-2 rounded-lg mb-6 transition-colors ${isGridActive
                                ? "bg-gray-500 hover:bg-gray-600"
                                : "bg-blue-600 hover:bg-blue-700"
                            }`}
                    >
                        <svg
                            className="w-5 h-5 mr-2"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                        >
                            {isGridActive ? (
                                <path d="M4 4h12v12H4V4zm2 2v2h2V6H6zm4 0v2h2V6h-2zm-4 4v2h2v-2H6zm4 0v2h2v-2h-2z" />
                            ) : (
                                <path d="M3 3h14v14H3V3zm2 2v2h2V5H5zm4 0v2h2V5H9zm-4 4v2h2V9H5zm4 0v2h2V9H9z" />
                            )}
                        </svg>
                        {isGridActive ? "Desactivar Cuadrícula" : "Activar Cuadrícula"}
                    </button>

                    <div className="flex flex-col space-y-2">
                        {zones.map((zone) => (
                            <div key={zone.id} className="flex justify-between items-center">
                                <button
                                    onClick={() => setSelectedZoneId(zone.id)}
                                    className={`w-full text-left px-3 py-2 rounded ${selectedZoneId === zone.id
                                            ? "bg-blue-600 text-white"
                                            : "bg-white hover:bg-blue-100"
                                        } transition-colors`}
                                >
                                    {zone.name}
                                </button>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={() => openZoneModal(zone)}
                                        className="text-gray-500 hover:text-blue-600 transition-colors"
                                        title="Editar Zona"
                                        aria-label="Editar Zona"
                                    >
                                        <svg
                                            className="w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                        >
                                            <path d="M17.414 2.586a2 2 0 010 2.828l-9 9a2 2 0 01-1.414.586H5a1 1 0 01-1-1v-2a2 2 0 01.586-1.414l9-9a2 2 0 012.828 0zM5 13h2v2H5v-2z" />
                                        </svg>
                                    </button>
                                    <button
                                        onClick={() => deleteZone(zone.id)}
                                        className="text-gray-500 hover:text-red-600 transition-colors"
                                        title="Eliminar Zona"
                                        aria-label="Eliminar Zona"
                                    >
                                        <svg
                                            className="w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M6 6L14 14M6 14L14 6"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                        {zones.length === 0 && (
                            <p className="text-gray-600">No hay zonas creadas.</p>
                        )}
                    </div>
                </div>

                {/* Contenedor Principal */}
                <div className="flex-grow p-6 overflow-y-auto relative">
                    {selectedZone ? (
                        <>
                            <div className="flex justify-between items-center mb-6">
                                <h1 className="text-3xl font-bold">{selectedZone.name}</h1>
                                <button
                                    onClick={() => openTableModal(selectedZone)}
                                    className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                                >
                                    <svg
                                        className="w-5 h-5 mr-2"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        aria-hidden="true"
                                    >
                                        <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0V11H7a1 1 0 110-2h3V6a1 1 0 011-1z" />
                                    </svg>
                                    Agregar Mesa
                                </button>
                            </div>
                            {selectedZone.tables.length > 0 ? (
                                <div
                                    ref={containerRef}
                                    className="table-container relative w-full h-full"
                                    style={{
                                        height: "80vh",
                                        border: "2px dashed #ccc",
                                        position: "relative",
                                        overflow: "hidden",
                                        backgroundImage: isGridActive
                                            ? `
                          repeating-linear-gradient(
                            to right,
                            #e2e8f0,
                            #e2e8f0 1px,
                            transparent 1px,
                            transparent 20px
                          ),
                          repeating-linear-gradient(
                            to bottom,
                            #e2e8f0,
                            #e2e8f0 1px,
                            transparent 1px,
                            transparent 20px
                          )
                        `
                                            : "none",
                                        backgroundSize: isGridActive ? "20px 20px" : "none",
                                    }}
                                >
                                    {selectedZone.tables.map((table) => (
                                        <Table
                                            key={table.id}
                                            table={table}
                                            zoneId={selectedZone.id}
                                            deleteTable={deleteTable}
                                            updateTablePosition={updateTablePosition}
                                            isGridActive={isGridActive}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <p className="text-gray-600">No hay mesas en esta zona.</p>
                            )}
                        </>
                    ) : (
                        <p className="text-gray-600">
                            Por favor, agrega una zona y selecciónala para ver sus mesas.
                        </p>
                    )}
                </div>

                {/* Modal de Zona */}
                <Transition.Root show={isZoneModalOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={setIsZoneModalOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-30" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-screen p-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="bg-white rounded-lg shadow-lg max-w-sm w-full p-6">
                                        <Dialog.Title className="text-2xl font-semibold mb-4">
                                            {currentZone ? "Editar Zona" : "Agregar Zona"}
                                        </Dialog.Title>
                                        <form onSubmit={handleZoneSubmit}>
                                            <label className="block mb-2">
                                                Nombre de la Zona:
                                                <input
                                                    type="text"
                                                    value={zoneName}
                                                    onChange={(e) => setZoneName(e.target.value)}
                                                    className="mt-1 block w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    placeholder="Ej: Terraza"
                                                    required
                                                />
                                            </label>
                                            {zoneError && (
                                                <p className="text-red-500 text-sm mb-2">{zoneError}</p>
                                            )}
                                            <div className="flex justify-end mt-4">
                                                <button
                                                    type="button"
                                                    onClick={() => setIsZoneModalOpen(false)}
                                                    className="mr-3 flex items-center bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 transition-colors"
                                                >
                                                    <svg
                                                        className="w-5 h-5 mr-2"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M6 6L14 14M6 14L14 6"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                        />
                                                    </svg>
                                                    Cancelar
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                                                >
                                                    <svg
                                                        className="w-5 h-5 mr-2"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        aria-hidden="true"
                                                    >
                                                        <path d="M10 5a1 1 0 011 1v8a1 1 0 11-2 0V6a1 1 0 011-1z" />
                                                    </svg>
                                                    {currentZone ? "Guardar Cambios" : "Agregar Zona"}
                                                </button>
                                            </div>
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Modal de Mesa */}
                <Transition.Root show={isTableModalOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-10"
                        onClose={setIsTableModalOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-30" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-screen p-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="bg-white rounded-lg shadow-lg max-w-sm w-full p-6">
                                        <Dialog.Title className="text-2xl font-semibold mb-4">
                                            Agregar Mesa a {currentZone?.name}
                                        </Dialog.Title>
                                        <form onSubmit={handleTableSubmit}>
                                            <label className="block mb-2">
                                                Identificador de la Mesa:
                                                <input
                                                    type="text"
                                                    value={tableLabel}
                                                    onChange={(e) => setTableLabel(e.target.value)}
                                                    className="mt-1 block w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    placeholder="Ej: t1, 1"
                                                    required
                                                />
                                            </label>
                                            {tableError && (
                                                <p className="text-red-500 text-sm mb-2">
                                                    {tableError}
                                                </p>
                                            )}
                                            <div className="flex justify-end mt-4">
                                                <button
                                                    type="button"
                                                    onClick={() => setIsTableModalOpen(false)}
                                                    className="mr-3 flex items-center bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 transition-colors"
                                                >
                                                    <svg
                                                        className="w-5 h-5 mr-2"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M6 6L14 14M6 14L14 6"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                        />
                                                    </svg>
                                                    Cancelar
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                                                >
                                                    <svg
                                                        className="w-5 h-5 mr-2"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        aria-hidden="true"
                                                    >
                                                        <path d="M10 5a1 1 0 011 1v8a1 1 0 11-2 0V6a1 1 0 011-1z" />
                                                    </svg>
                                                    Agregar Mesa
                                                </button>
                                            </div>
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        </Layout>
    );
};

export default BarConfig;
