// Table.js
import React from 'react';
import Draggable from 'react-draggable';

const Table = React.memo(
  ({ table, zoneId, deleteTable, updateTablePosition, isGridActive }) => {
    const nodeRef = React.useRef(null);

    return (
      <Draggable
        nodeRef={nodeRef}
        position={{ x: table.position.left, y: table.position.top }}
        onDrag={(e, data) => updateTablePosition(zoneId, table.id, data)}
        bounds="parent"
        grid={[20, 20]}
      >
        <div
          ref={nodeRef}
          className={`flex items-center justify-center border rounded bg-indigo-100 relative text-lg font-medium text-gray-800 cursor-pointer ${
            isGridActive ? 'bg-indigo-200' : ''
          }`}
          style={{
            position: 'absolute',
            width: '80px',
            height: '80px',
            userSelect: 'none',
            borderRadius: '12px',
            boxShadow: '0px 18px 48px -23px rgba(0, 0, 0, 0.1)',
          }}
        >
          {table.label}
          <button
            onClick={() => deleteTable(zoneId, table.id)}
            className="absolute top-1 right-1 text-gray-500 hover:text-red-600 transition-colors"
            title="Eliminar Mesa"
            aria-label="Eliminar Mesa"
          >
            <svg
              className="w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path
                d="M6 6L14 14M6 14L14 6"
                stroke="currentColor"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
      </Draggable>
    );
  }
);

export default Table;
