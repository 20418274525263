import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import box from "../assets/img/box.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import toast from "react-hot-toast";
import { formatDate } from "../utils/constants";
const Boxes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { page, search } = useParams();
  const user = useSelector((state) => state.main.user);

  const [boxes, setBoxes] = useState([]);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [boxId, setBoxId] = useState(0);
  const [name, setName] = useState("");
  const [stamped, setStamped] = useState("");
  const [companyId, setCompanyId] = useState(user.companyId);
  const [branchId, setBranchId] = useState(0);
  const [active, setActive] = useState(0);
  const [expeditionPoint, setExpeditionPoint] = useState("");
  const [receiptExpeditionPoint, setReceiptExpeditionPoint] = useState("");
  const [number, setNumber] = useState(0);
  const [receiptNumber, setReceiptNumber] = useState(0);
  const [openBox, setOpenBox] = useState(0);
  const [deleted, setDeleted] = useState(0);
  const [searchText, setSearchText] = useState(search || "");
  const [activity1, setActivity1] = useState("");
  const [activity2, setActivity2] = useState("");
  const [activity3, setActivity3] = useState("");
  const [activity4, setActivity4] = useState("");
  const [activity5, setActivity5] = useState("");
  const [activity6, setActivity6] = useState("");
  const [expirationFrom, setExpirationFrom] = useState(function () {
    ///let newDate = new Date().toISOString;
    return Date()
      .split("/")
      .reverse()
      .map((d1) => {
        if (d1.length == 1) {
          return "0" + d1;
        } else {
          return d1;
        }
      })
      .join("-");
  } );
  const [expirationTo, setExpirationTo] = useState(function () {
    //let newDate = new Date().toISOString;
    return Date()
      .split("/")
      .reverse()
      .map((d2) => {
        if (d2.length == 1) {
          return "0" + d2;
        } else {
          return d2;
        }
      })
      .join("-");
  }
  
  );

  async function fetchBoxes() {
    let pageQuery = page === undefined ? 1 : page;

    setIsFetching(true);

    let boxes = await axiosAuth.get("/boxes?page=" + pageQuery);

    setIsFetching(false);
    let items = await axiosAuth.get(
      "/items?page=" + pageQuery + "&search=" + search
    );
    if (boxes.data.error) {
      if (boxes.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setBoxes(boxes.data.boxes.rows);
    setTotal(boxes.data.boxes.count);
    setPages(boxes.data.boxes.pages);
    setBranches(boxes.data.branches);
    setCompanies(boxes.data.companies);

    let newFilteredBranchesObj = [];
    boxes.data.branches.map((branch) => {
      if (branch.companyId == boxes.data.companies[0].id) {
        newFilteredBranchesObj.push(branch);
      }
    });

    setFilteredBranches(newFilteredBranchesObj);
    console.log(boxes);
  }

  useEffect(() => {
    fetchBoxes();
  }, []);

  useEffect(() => {
    fetchBoxes();
  }, [location]);

  useEffect(() => {
    let newFilteredBranchesObj = [];

    branches.map((branch) => {
      if (branch.companyId == companyId) {
        newFilteredBranchesObj.push(branch);
      }
    });

    setFilteredBranches(newFilteredBranchesObj);
  }, [companyId]);

  const editBox = (boxId) => {
    setAction("edit");
    setBoxId(boxId);

    let box = boxes.filter((box) => box.id === boxId)[0];

    setName(box.name);
    setStamped(box.stamped);
    setExpeditionPoint(box.expeditionPoint);
    setReceiptExpeditionPoint(box.receiptExpeditionPoint);
    setNumber(box.number);
    setReceiptNumber(box.receiptNumber);
    setBranchId(box.branchId);
    setActivity1(box.activity1);
    setActivity2(box.activity2);
    setActivity3(box.activity3);
    setActivity4(box.activity4);
    setActivity5(box.activity5);
    setActivity6(box.activity6);
    setExpirationFrom(box.expirationFrom);
    setExpirationTo(box.expirationTo);
    branches.map((branch) => {
      if (branch.id == box.branchId) {
        setCompanyId(branch.companyId);
      }
    });

    setActive(box.active);
    setOpenBox(box.open);
    setDeleted(box.deleted);

    setOpen(true);
  };

  const clearFields = () => {
    setBoxId(0);
    setName("");
    setStamped("");
    setExpeditionPoint("");
    setReceiptExpeditionPoint("");
    setNumber(0);
    setReceiptNumber(0);
    setBranchId(0);
    setActive(0);
    setOpenBox(0);
    setDeleted(0);
    setActivity1("");
    setActivity2("");
    setActivity3("");
    setActivity4("");
    setActivity5("");
    setActivity6("");
  };

  const handleSubmit = async () => {
    if (name === "" || branchId === 0) {
      alert("Debes completar los campos para continuar.");
      return false;
    }

    if (action === "edit") {
      let id = boxId;
      let newBoxesObj = [];
      
      let updateBoxRequest = await axiosAuth.put("/boxes", {
        id,
        name,
        stamped,
        number,
        receiptNumber,
        expeditionPoint,
        receiptExpeditionPoint,
        branchId,
        activity1,
        activity2,
        activity3,
        activity4,
        activity5,
        activity6,
        active,
        open: openBox,
        deleted,
        expirationFrom,
        expirationTo,
      });

      if (updateBoxRequest.data.error) {
        if (updateBoxRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }
      boxes.map((box) => {
        if (box.id == id) {
          // Busca la compañía correspondiente al companyId del branch
          const company = companies.find((c) => c.id === box.branch.companyId);
          // Agrega el objeto branch al updateBoxRequest.data con la compañía correspondiente
          updateBoxRequest.data.branch = {
            ...box.branch, // Incluye los datos actuales de branch
            company: company || null, // Agrega la compañía o null si no se encuentra
          };

          newBoxesObj.push(updateBoxRequest.data);
        } else {
          newBoxesObj.push(box);
        }
        return "";
      });
      setBoxes(newBoxesObj);
     
    } else {
      let createBoxRequest = await axiosAuth.post("/boxes", {
        name,
        stamped,
        number,
        receiptNumber,
        expeditionPoint,
        receiptExpeditionPoint,
        branchId: parseInt(branchId),
        activity1,
        activity2,
        activity3,
        activity4,
        activity5,
        activity6,
        active,
        open: openBox,
        deleted,
        expirationFrom,
        expirationTo,
      });

      if (createBoxRequest.data.error) {
        if (createBoxRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      /* let newBoxes = [...boxes];
      newBoxes.push(createBoxRequest.data);
      setBoxes(newBoxes); */
      fetchBoxes();
     
    }
    {action === "edit" ?  toast.success("Caja modificada con éxito!") :  toast.success("Caja registrada con éxito!");}
    setOpen(false);
    clearFields();
 
  };
  const searchBoxes = async (searchTxt) => {
    setSearchText((prev) => searchTxt);
    const searchBoxesRequest = await axiosAuth.get(
      "/searchBoxes?search=" + searchTxt
    );
    setBoxes(searchBoxesRequest.data.boxes.rows);
    setPages(searchBoxesRequest.data.boxes.pages);
    setTotal(searchBoxesRequest.data.boxes.count);
  };

  const deleteBox = async (boxId) => {
    if (window.confirm("Desea eliminar esta caja ?")) {
      let boxDeleteRequest = await axiosAuth.delete("/boxes", {
        params: { id: boxId },
      });

      if (boxDeleteRequest.data.error) {
        if (boxDeleteRequest.data.message === "token") {
          await axiosAuth.post("/logout");
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newBoxesObj = [];

      boxes.map((box) => {
        if (box.id !== boxId) {
          newBoxesObj.push(box);
        } else {
          if (user.roleId == 1) {
            box.deleted = 1;
            newBoxesObj.push(box);
          }
        }
        return "";
      });

      setBoxes(newBoxesObj);
    }
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center
            
            justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative sm:mt-[90px] mt-[500px] 
                  mb-[55px] transform sm:overflow rounded-3xl bg-white text-left shadow-xl transition-all">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} caja
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <form className="bg-white rounded grid grid-cols-1 md:grid-cols-3 gap-3 p-2">
                           {/*      {action === "edit" && (
                                  <div className="mb-4">
                                    <label
                                      className="block text-gray-700 text-sm font-bold"
                                      htmlFor="boxId"
                                    >
                                      Id
                                    </label>
                                    <input
                                      readOnly
                                      className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      id="boxId"
                                      type="text"
                                      value={boxId}
                                    />
                                  </div>
                                )} */}

                                <div className="mb-[0px] sm:flex flex  sm:flex-col">
                                  <label 
                                    className="inline-block text-700 text-[10px] w-[30%] font-bold uppercase"
                                    htmlFor="name"
                                  >
                                    Nombre
                                  </label>
                                  <input
                                     type="text" className="inline-block  appearance-none border sm:w-[100%] w-[70%]
                                     py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                       h-[23px] uppercase"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <div className="mb-[0px] sm:flex flex sm:flex-col" >
                                  <label
                                    className="inline-block text-700 w-[30%] text-[10px] font-bold uppercase"
                                    htmlFor="stamped"
                                  >
                                    Timbrado
                                  </label>
                                  <input
                                    type="text" className="inline-block  appearance-none border sm:w-[100%] w-[70%]
                                    py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                      h-[23px] uppercase"
                                    value={stamped}
                                    onChange={(e) => setStamped(e.target.value)}
                                  />
                                </div>
                                <div className="mb-[0px] sm:flex flex  sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] w-[30%] font-bold uppercase"
                                    htmlFor="name"
                                  >
                                    Inicio Vigencia
                                  </label>
                                  <input
                          className="inline-block input-bg-color 
                                    appearance-none border sm:w-full w-[70%] py-2 px-3 text-gray-700 leading-tight 
                                    focus:outline-none focus:shadow-outline h-[23px] uppercase"
                          id="date"
                          value={expirationFrom}
                          type="date"
                          placeholder="Fecha"
                          onChange={(e) => {
                            setExpirationFrom(e.target.value);
                          }}
                        />
                                </div>
                                <div className="mb-[0px] sm:flex  flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] font-bold uppercase w-[30%]"
                                    htmlFor="name"
                                  > Fin Vigencia
                                 </label>
                                 <input
                          className="inline-block input-bg-color 
                                    appearance-none border sm:w-full w-[70%] py-2 px-3 text-gray-700 leading-tight 
                                    focus:outline-none focus:shadow-outline h-[23px] uppercase"
                          id="date"
                          value={expirationTo}
                          type="date"
                          placeholder="Fecha"
                          onChange={(e) => {
                            setExpirationTo(e.target.value);
                          }}
                        />
                                </div>
                                

                                <div className="mb-[1px] sm:flex flex sm:flex-col">
                                  <label
                                    className="inline-block w-[30%] sm:w-[50%]
                                     text-700 text-[10px] font-bold uppercase"
                                    htmlFor="number" 
                                  >
                                    Numero (Factura)
                                  </label>
                                  <input
                                    className="inline-block input-bg-color appearance-none border
                                     sm:w-[100%] w-[70%] py-2 px-3 text-gray-700 leading-tight focus:outline-none
                                      focus:shadow-outline capitalize h-[23px] uppercase"
                                    
                                    type="number"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                  />
                                </div>

                                <div className="mb-[0px] sm:flex flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 w-[30%] text-[10px] font-bold uppercase"
                                    htmlFor="receiptNumber"
                                  >
                                    Numero (Recibo)
                                  </label>
                                  <input
                                    className="inline-block input-bg-color 
                                    appearance-none border sm:w-[100%] w-[70%] py-2 px-3
                                     text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                              
                                    type="receiptNumber"
                                    value={receiptNumber}
                                    onChange={(e) =>
                                      setReceiptNumber(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-[0px] sm:flex  flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] w-[30%] sm:w-[60%] font-bold uppercase"
                                    htmlFor="expeditionPoint"
                                  >
                                    Punto de expedicion (Factura )
                                  </label>
                                  <input
                                    className="inline-block
                                     input-bg-color appearance-none
                                      border sm:w-[100%] w-[70%] py-2 px-3 text-gray-700 
                                      leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                    
                                    type="text"
                                    value={expeditionPoint}
                                    onChange={(e) =>
                                      setExpeditionPoint(e.target.value)
                                    }
                                  />
                                </div>

                                <div className="mb-[0px] sm:flex flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] font-bold uppercase w-[30%] sm:w-[50%]"
                                    htmlFor="receiptExpeditionPoint"
                                  >
                                    Punto de expedicion (Recibo)
                                  </label>
                                  <input
                                    className="inline-block input-bg-color appearance-none border
                                     sm:w-[100%] w-[70%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                      capitalize h-[23px] uppercase"
                                
                                    type="text"
                                    value={receiptExpeditionPoint}
                                    onChange={(e) =>
                                      setReceiptExpeditionPoint(e.target.value)
                                    }
                                  />
                                </div>

                                {user.roleId == 1 && (
                                  <div className="mb-[0px] sm:flex flex sm:flex-col">
                                    <label
                                      className="inline-block text-700 text-[10px] w-[30%] font-bold uppercase"
                                      htmlFor="companyId"
                                    >
                                      Empresa
                                    </label>
                                    <select
                                      className="inline-block input-bg-color appearance-none border
                                       sm:w-[100%] w-[70%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[23px] uppercase"
                                      
                                      value={companyId}
                                      onChange={(e) =>
                                        setCompanyId(e.target.value)
                                      }
                                    >
                                      <option key={0} value={0}>
                                        Elija una empresa
                                      </option>
                                      {companies?.map((company) => (
                                        <option
                                          key={company.id}
                                          value={company.id}
                                        >
                                          {company.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}

                                <div className="mb-[0px] sm:flex flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] w-[30%] font-bold uppercase"
                                    htmlFor="branchId"
                                  >
                                    Sucursal
                                  </label>
                                  <select
                                    className="inline-block input-bg-color
                                     appearance-none border sm:w-[100%] w-[70%] py-2 px-3
                                      text-gray-700 leading-tight focus:outline-none
                                       focus:shadow-outline capitalize h-[23px] uppercase"
                                    
                                    value={branchId}
                                    onChange={(e) =>
                                      setBranchId(e.target.value)
                                    }
                                  >
                                    <option key={0} value={0}>
                                      Elija una sucursal
                                    </option>
                                    {filteredBranches?.map((branch) => (
                                      <option key={branch.id} value={branch.id}>
                                        {branch.description}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="mb-[0px] sm:flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] font-bold uppercase"

                                  >
                                    Actividad 1
                                  </label>
                                  <textarea type="text" className="inline-block  appearance-none border w-[100%] 
                                    py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                      h-[85%] uppercase"
                                    value={activity1}
                                    onChange={(e) => setActivity1(e.target.value)}
                                  />
                                </div>
                                <div className="mb-[1px] sm:flex  sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] font-bold uppercase"

                                  >
                                    Actividad 2
                                  </label>
                                  <textarea type="text" className="inline-block  appearance-none border
                                     w-[100%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                       h-[85%] uppercase"
                                    value={activity2}
                                    onChange={(e) => setActivity2(e.target.value)}
                                  />
                                </div>
                                <div className="mb-[1px] sm:flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] font-bold uppercase"

                                  >
                                    Actividad 3
                                  </label>
                                  <textarea type="text" className="inline-block  appearance-none border w-[100%] 
                                    py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                     h-[85%] uppercase"
                                    value={activity3}
                                    onChange={(e) => setActivity3(e.target.value)} />
                                </div>
                                <div className="mb-[1px] sm:flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] font-bold uppercase"

                                  >
                                    Actividad 4
                                  </label>
                                  <textarea type="text" className="inline-block  appearance-none border w-[100%] 
                                    py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                      h-[85%] uppercase"
                                    value={activity4}
                                    onChange={(e) => setActivity4(e.target.value)}

                                  />
                                </div>
                                <div className="mb-[1px] sm:flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] font-bold uppercase"

                                  >
                                    Actividad 5
                                  </label>
                                  <textarea type="text" className="inline-block  appearance-none border w-[100%] 
                                    py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                      h-[85%] uppercase"
                                    value={activity5}
                                    onChange={(e) => setActivity5(e.target.value)}

                                  />
                                </div>
                                <div className="mb-[1px] sm:flex sm:flex-col">
                                  <label
                                    className="inline-block text-700 text-[10px] font-bold uppercase"

                                  >
                                    Actividad 6
                                  </label>
                                  <textarea type="text" className="inline-block  appearance-none border w-[100%] 
                                    py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
                                      h-[85%] uppercase"
                                    value={activity6}
                                    onChange={(e) => setActivity6(e.target.value)}

                                  />
                                </div>

                                <div className="mb-4">
                                  <label
                                    className="inline-block text-700 text-[10px] font-bold mx-3 uppercase"
                                    htmlFor="active"
                                  >
                                    Activa
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border w-[35] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="boxactive"
                                    value={active}
                                    onChange={(e) =>
                                      setActive(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>

                                  <label
                                    className="inline-block text-700 text-[10px] font-bold mx-3 uppercase"
                                    htmlFor="open"
                                  >
                                    Abierta
                                  </label>
                                  <select
                                    className="input-bg-color appearance-none border w-[35] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="boxopen"
                                    value={openBox}
                                    onChange={(e) =>
                                      setOpenBox(parseInt(e.target.value))
                                    }
                                  >
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                  </select>


                                  {user.roleId == 1 && (
                                    <div className="float-left">
                                      <label
                                        className=" text-700 text-[10px] font-bold mx-2 uppercase"
                                        htmlFor="deleted"
                                      >
                                        Borrado
                                      </label>
                                      <select
                                        className="input-bg-color appearance-none border w-[35] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="boxdeleted"
                                        value={deleted}
                                        onChange={(e) =>
                                          setDeleted(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>
                                  )}
                                </div>

                              </form>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>

                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div class="w-full top-[-50px] flex flex-col sm:flex-row pb-2 relative bg-white "
          style={{ borderBottom: "1px solid lightgray" }}>
          <div className="sm:w-[30%] w-[60%]">
            <h1 className="ml-5 mt-7 text-3xl title font-family-thin secondary-color">
              Cajas
            </h1>
            <DateComponent className="sm:block hidden" />
          </div>
          <div class="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-6 flex flex-row items-center w-200 sm:mt-2 mt-12">
            <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px] sm:mt-5">
              Buscar:
            </label>
            <input
              value={searchText}
              className="input-bg-color appearance-none border w-[350px]  rounded  
            py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[35px] sm:mt-5"
              placeholder="Nombre de la caja"
              onChange={(e) => searchBoxes(e.target.value)}
            />
            <button
              type="button"
              className="inline-flex flex-row float-right sm:w-[170px] w-[30%] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 relative left-[10px] sm:mt-5 h-[35px]"
              style={{ alignItems: "center" }}
              onClick={() => {
                setAction("create");
                setOpen(true);
              }}
            >
              <img src={box} className="w-8" />
              CREAR CAJA
            </button>
          </div>
        </div>
        {/* <button
          className="rounded-md secondary-bg-color px-2 py-1 float-right relative right-10 bottom-10 cursor-pointer title-button mt-8"
          onClick={() => {
            setAction("create");
            setOpen(true);
          }}
        >
          Crear caja
        </button> */}



        <div className="w-[95%] p-5 mx-auto overflow-auto sm:max-h-[70vh] max-h-[40vh] mt-[-50px]">
          <table className="w-[95%] table-auto mx-auto w-full text-[10px] support-table">
            <thead>
              <tr className="bg-gray-500 h-8">
                <th className="uppercase !text-white text-left">Id</th>
                <th className="uppercase !text-white text-left">Nombre</th>
                <th className="uppercase !text-white text-right">Timbrado</th>
                <th className="uppercase !text-white">Punto de Exp. (Fact)</th>
                <th className="uppercase !text-white">Punto de Exp. (Recibo)</th>
                <th className="uppercase !text-white">Num. (Fact)</th>
                <th className="uppercase !text-white">Num. (Recibo)</th>
                {(user.roleId == 1) && <th className="uppercase !text-white text-left">Empresa</th>}
                <th className="uppercase !text-white text-right">Sucursal</th>
                <th className="uppercase !text-white">Activa</th>
                <th className="uppercase !text-white">Abierta</th>
                {user.roleId == 1 && <th className="uppercase !text-white">Borrado</th>}
                <th className="uppercase !text-white">Fecha Alta</th>
                <th className="uppercase !text-white">Accion</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {boxes.length > 0 &&
                !isFetching &&
                boxes?.map((box) => {
                  return (
                    <tr key={box.id}>
                      <td>{box.id}</td>
                      <td className="text-left">{box.name}</td>
                      <td className="text-right">{box.stamped}</td>
                      <td>{box.expeditionPoint}</td>
                      <td>{box.receiptExpeditionPoint}</td>
                      <td>{box.number}</td>
                      <td>{box.receiptNumber}</td>
                      {user.roleId == 1 && (
                        <td className="whitespace-nowrap text-left">
                          {box.branch.company.name}
                        </td>
                      )}
                      <td className="text-right">{box.branch.description}</td>
                      <td>{box.active ? "Si" : "No"}</td>
                      <td>{box.open ? "Si" : "No"}</td>
                      {user.roleId == 1 && <td>{box.deleted ? "Si" : "No"}</td>}
                      <td>
                        {box.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="text-center">
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            editBox(box.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="bg-transparent"
                          onClick={() => {
                            deleteBox(box.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="m-1 cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {boxes.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={8} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination path="cajas" page={page} pages={pages} total={total} params={{ search }} />
        </div>
      </Layout>
    </>
  );
};

export default Boxes;
