import React, { useState, useRef, useEffect } from "react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import ComboBox from "../components/combobox";

import { DownloadTableExcel } from "react-export-table-to-excel";
import xls from "../assets/img/xls.png";
import search from "../assets/img/search.webp";
import door from "../assets/img/door.png";
import pdf from "../assets/img/pdf.png";
import printer from "../assets/img/printer.png";
import { numberWithCommas } from "../utils/constants";

const SalesReport = (props) => {
  const tableRef = useRef(null);

  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [reporType, setReporType] = useState(0);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [salesReport, setSalesReport] = useState(true);
  const [salesReportByItem, setSalesReportByItem] = useState(false);
  const [bookOfSalesReport, setBookOfSalesReport] = useState(false);
  const [type, seType] = useState(state != undefined ? state.type : 0);

  const user = useSelector((state) => state.main.user);

  const [companyId, setCompanyId] = useState(
    user != undefined ? user.companyId : 0
  );
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState(0);

  const [reports, setReports] = useState([]);

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .getFullYear()
        .toLocaleString();

    return state != undefined
      ? state.dateFromFilter
      : MyDateString.replaceAll(".", "")
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(() => {
    return state != undefined
      ? state.dateToFilter
      : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
          .toLocaleDateString()
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .map((d) => (d.length <= 1 ? "0" + String(d) : d))
          .join("-");
  });

  async function fetchReport() {
    setIsFetching(true);

    try {
      let reportRequest = await axiosAuth.get(
        "/salesreport?type=" +
          type +
          "&clientId=" +
          clientId +
          "&companyId=" +
          companyId +
          "&dateFrom=" +
          dateFromFilter +
          "&dateTo=" +
          dateToFilter +
          "&salesReport=" +
          salesReport +
          "&salesReportByItem=" +
          salesReportByItem +
          "&bookOfSalesReport=" +
          bookOfSalesReport
      );
  
      if (reportRequest.data.error) {
        if (reportRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      
        setIsFetching(false);
        return;
      }
  
  
      let totalPriceUn = 0,
        totalPriceTot = 0,
        totalPriceUnGral = 0,
        totalPriceTotGral = 0,
        totalTaxed10 = 0,
        totalTaxed10Ind = 0,
        totalTaxed5 = 0,
        totalTaxed5Ind = 0,
        totalVat10Ind = 0,
        totalVat5Ind = 0,
        totalExempt = 0,
        totalTotal = 0;
  
      reportRequest.data.report.map((report, r) => {
        totalPriceUn += parseInt(report.price);
        totalPriceUnGral += parseInt(report.price);
        totalPriceTot += parseInt(report.total);
        totalPriceTotGral += parseInt(report.total);
        totalVat10Ind += parseInt(report.vat10);
        totalVat5Ind += parseInt(report.vat5);
        totalTaxed10Ind += parseInt(report.taxed10);
        totalTaxed5Ind += parseInt(report.taxed5);
        totalTaxed10 += parseInt(report.taxed10 + report.vat10);
        totalTaxed5 += parseInt(report.taxed5 + report.vat5);
        totalExempt += parseInt(report.exempt);
        totalTotal += parseInt(
          report.taxed10 +
            report.vat10 +
            report.taxed5 +
            report.vat5 +
            report.exempt
        );
  
        report.totalPriceUn = numberWithCommas(totalPriceUn);
        report.totalPriceTot = numberWithCommas(totalPriceTot);
  
        report.totalPriceUnGral = numberWithCommas(totalPriceUnGral);
        report.totalPriceTotGral = numberWithCommas(totalPriceTotGral);
  
        report.totalTaxed10 = numberWithCommas(totalTaxed10);
        report.totalVat5Ind = numberWithCommas(totalVat5Ind);
        report.totalVat10Ind = numberWithCommas(totalVat10Ind);
        report.totalTaxed10Ind = numberWithCommas(totalTaxed10Ind);
        report.totalTaxed5 = numberWithCommas(totalTaxed5);
        report.totalTaxed5Ind = numberWithCommas(totalTaxed5Ind);
        report.totalExempt = numberWithCommas(totalExempt);
  
        report.totalTotal = numberWithCommas(totalTotal);
  
        if (
          r > 0 &&
          reportRequest.data.report.length > 0 &&
          reportRequest.data.report.length > 1 &&
          r != reportRequest.data.report.length - 1 &&
          reportRequest.data.report[r].id != reportRequest.data.report[r + 1].id
        ) {
          totalPriceUn = 0;
          totalPriceTot = 0;
        }
      });
  
      setReports(reportRequest.data.report);
      
    } catch (error) {
      console.error("Error fetching report:", error);
    }finally{
      setIsFetching(false);
    }
  }

  useEffect(() => {
    async function fetchClients() {
      let clientsRequest = await axiosAuth.get("/clients?page=1");

      if (clientsRequest.data.error) {
        if (clientsRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      setClients(clientsRequest.data.clients.rows);
    }

    fetchClients();
  }, []);

  useEffect(() => {
    dispatch(setTitle("Reporte de Ventas"));
  }, []);

  const searchClients = async (search) => {
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
  };

  return (
    <>
      <Layout>
        <div className="flex flex-col md:flex-row border border-gray-200 border-r-0 border-b-1">
          <div className="hidden sm:block w-[70%] md:w-[40%] min-w-[212px] border border-b-0 border-r-2 h-[98%]">
            <h1 className="ml-5 mt-5 text-[20px] secondary-color title font-family-thin">
              Reporte de Ventas
            </h1>
            <DateComponent className="!text-[15px] sm:block hidden" />
          </div>

          <div className="flex flex-row md:flex-col lg:flex-row justify-center items-center w-full md:w-[45%] m-[auto] mr-4 sm:ml-2 ml-6">
            <div className="mr-6 flex flex-col items-center justify-start">
              <div className="flex flex-row justify-start items-center">
                <ComboBox
                  data={clients}
                  setData={setClientId}
                  value={clientId}
                  placeholder="Cliente"
                  className="h-[27px] w-[100%] mb-2"
                  search={searchClients}
                />
              </div>

              <div className="w-[100%] flex flex-row justify-start items-center">
                <input
                  id="salesReportFilter"
                  name="salesReportFilter"
                  checked={salesReport}
                  type="checkbox"
                  className="mr-1"
                  onChange={(e) => {
                    setReports([]);
                    setSalesReportByItem(false);
                    setBookOfSalesReport(false);
                    setSalesReport(!salesReport);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm uppercase !text-[11px] whitespace-nowrap"
                  htmlFor="salesReportFilter"
                >
                  Reporte de ventas
                </label>
              </div>

              <div className="w-[100%] flex flex-row justify-start items-center">
                <input
                  id="byItemFilter"
                  name="byItemFilter"
                  checked={salesReportByItem}
                  type="checkbox"
                  className="mr-1"
                  onChange={(e) => {
                    setReports([]);
                    setSalesReport(false);
                    setBookOfSalesReport(false);
                    setSalesReportByItem(!salesReportByItem);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm uppercase !text-[11px]"
                  htmlFor="byItemFilter"
                >
                  Por articulo
                </label>
              </div>

              <div className="w-[100%] flex flex-row justify-start items-center">
                <input
                  id="salesBookFilter"
                  name="salesBookFilter"
                  checked={bookOfSalesReport}
                  type="checkbox"
                  className="mr-1"
                  onChange={(e) => {
                    setReports([]);
                    setSalesReport(false);
                    setSalesReportByItem(false);
                    setBookOfSalesReport(!bookOfSalesReport);
                  }}
                />
                <label
                  className="block text-gray-700 text-sm uppercase !text-[11px]"
                  htmlFor="salesBookFilter"
                >
                  Libro de Ventas
                </label>
              </div>
            </div>

            <div className="mr-5 w-[25%] min-w-[120px] mb-2">
              <select
                id="typeFilter"
                name="typeFilter"
                value={type}
                className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[22px] uppercase !text-[11px] mt-2"
                style={{ lineHeight: "13px" }}
                onChange={(e) => {
                  setReports([]);
                  seType(e.target.value);
                }}
              >
                <option value="0">Todos</option>
                <option value="1">Contado</option>
                <option value="2">Credito</option>
              </select>

              <input
                type="date"
                id="dateFromFilter"
                name="dateFromFilter"
                value={dateFromFilter}
                className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                onChange={(e) => {
                  setReports([]);
                  setDateFromFilter(e.target.value);
                }}
              />

              <input
                type="date"
                id="dateToFilter"
                name="dateToFilter"
                value={dateToFilter}
                className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                onChange={(e) => {
                  setReports([]);
                  setDateToFilter(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="flex flex-row w-full justify-center md:w-[55%] mt-1 h-[75px] mr-[6%] relative sm:right-0 right-[-7%] sales-buttons-container">
            <div className="flex flex-col items-center h-[70px]">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  setReports([]);
                  fetchReport();
                }}
              >
                <img src={search} className="w-8" />
                CONSULTAR
              </button>
            </div>

            <div className="flex flex-col items-center h-[70px]">
              <DownloadTableExcel
                filename={
                  salesReport
                    ? "REPORTE_VENTAS_"
                    : "REPORTE_VENTAS_ARTICULO_" +
                      new Date().toLocaleDateString()
                }
                sheet="REPORTE-VENTAS"
                currentTableRef={tableRef.current}
              >
                <button
                  type="button"
                  className="inline-flex flex-col w-[75px] h-[55px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px] mr-[2px]"
                >
                  <img src={xls} className="w-8" />
                  EXPORTAR
                </button>
              </DownloadTableExcel>
            </div>

            <div className="flex flex-col items-center hidden sm:block">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {}}
              >
                <img src={printer} className="w-8" />
                IMPRIMIR
              </button>
            </div>

            <div className="flex flex-col items-center">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {}}
              >
                <img src={pdf} className="w-8" />
                PDF
              </button>
            </div>

            <div className="flex flex-col items-center">
              <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={door} className="w-4" />
                SALIR
              </button>
            </div>
          </div>
        </div>

        <div
          className={`w-[95%] p-5 pb-0 mx-auto overflow-auto max-h-[300px] sm:max-h-[400px] relative ${
            salesReportByItem ? "" : "pt-4 sm:mt-12 mt-0"
          }`}
        >
          <table
            className="w-[95%] table-auto mx-auto w-full text-xs sales-report-table min-w-[628px] relative"
            ref={tableRef}
          >
            {salesReport && reports.length > 0 && !isFetching && (
              <thead className="bg-gray-600 !text-white relative md:fixed mt-[-30px] w-[87.8%]">
                <tr className="w-full">
                  <th className="!text-white uppercase w-[4%]">NRO.</th>
                  <th className="!text-white uppercase w-[2%] text-right">FECHA</th>
                  <th className="!text-white uppercase w-[15%]">CLIENTE</th>
                  <th className="!text-white uppercase w-[2%] text-right">RUC</th>

                  <th className="!text-white uppercase w-[2%] whitespace-nowrap text-right">
                    GRAV 10
                  </th>
                  <th className="!text-white uppercase w-[2%] text-right whitespace-nowrap">
                    GRAV 5
                  </th>
                  <th className="!text-white uppercase w-[2%] ">EXENTAS</th>
                  <th className="!text-white uppercase w-[2%] text-left">TOTAL</th>
                  <th className="!text-white uppercase w-[10%] text-center ">COND.</th>
                </tr>
              </thead>
            )}

            {salesReportByItem && reports.length > 0 && !isFetching && (
              <thead className="bg-gray-600 !text-white">
                <tr className="w-full">
                  <th className="!text-white uppercase">CLIENTE</th>
                  <th className="!text-white uppercase">FECHA</th>
                  <th className="!text-white uppercase">NRO. FACT.</th>
                  <th className="!text-white uppercase">CANT.</th>
                  <th className="!text-white uppercase">PRECIO UN.</th>
                  <th className="!text-white uppercase">PRECIO TOT.</th>
                  {/* <th className="!text-white uppercase w-[5%]">COSTO UN.</th>
                  <th className="!text-white uppercase w-[5%]">COSTO TOT.</th> */}
                </tr>
              </thead>
            )}

            {bookOfSalesReport && reports.length > 0 && !isFetching && (
              <thead className="bg-gray-600 !text-white">
                <tr className="w-full">
                  <th className="!text-white uppercase">NRO.</th>
                  <th className="!text-white uppercase">FECHA</th>
                  <th className="!text-white uppercase">COND</th>
                  <th className="!text-white uppercase">RAZON SOCIAL</th>
                  <th className="!text-white uppercase">RUC</th>
                  <th className="!text-white uppercase whitespace-nowrap">
                    GRAV. 5
                  </th>
                  <th className="!text-white uppercase whitespace-nowrap">
                    GRAV. 10
                  </th>
                  <th className="!text-white uppercase whitespace-nowrap">
                    IVA. 5
                  </th>
                  <th className="!text-white uppercase whitespace-nowrap">
                    IVA. 10
                  </th>
                  <th className="!text-white uppercase">Exentas</th>
                  <th className="!text-white uppercase">Total</th>
                  {/* <th className="!text-white uppercase w-[5%]">COSTO UN.</th>
                  <th className="!text-white uppercase w-[5%]">COSTO TOT.</th> */}
                </tr>
              </thead>
            )}
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={20}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {salesReport &&
                reports.length > 0 &&
                !isFetching &&
                reports.map((report, r) => {
                  let total = 0;
                  let number = String(report?.number) || 0;

                  Array(7 - number?.length)
                    .fill(0)
                    .map((_, i) => {
                      number = "0" + number;
                    });
                  total =
                    report.vat5 +
                    report.vat10 +
                    report.exempt +
                    report.taxed5 +
                    report.taxed10;

                  return (
                    <>
                      <tr key={report.id} className="text-[10px]">
                        <td className="w-[9%] whitespace-nowrap">
                          {report.expeditionPoint}
                          {"-"}
                          {number}
                        </td>
                        <td className="w-[7%]">
                          {report.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td className="whitespace-nowrap text-left ml-1 w-[33%]">
                          {report?.client?.name || report?.client}
                        </td>
                        <td className="text-right w-[5%]">
                          {report?.client?.ruc}
                        </td>

                        <td className="text-right w-[5%]">
                          {numberWithCommas(report.taxed10 + report.vat10)}
                        </td>
                        <td className="text-right w-[5%]">
                          {numberWithCommas(report.taxed5 + report.vat5)}
                        </td>
                        <td className="text-right w-[5%]">
                          {numberWithCommas(report.exempt)}
                        </td>
                        <td className="text-right w-[5%]">
                          {numberWithCommas(total)}
                        </td>
                        <td className="text-center">
                          {report.saleconditionId == 1 ? "CONTADO" : "CREDITO"}
                        </td>
                      </tr>
                    </>
                  );
                })}

              {salesReportByItem &&
                reports.length > 0 &&
                !isFetching &&
                reports.map((report, r) => {
                  let number = String(report?.number) || 0;

                  Array(7 - number?.length)
                    .fill(0)
                    .map((_, i) => {
                      number = "0" + number;
                    });

                  return (
                    <>
                      {r == 0 && reports.length > 0 && (
                        <tr key={report.id} className="text-[10px]">
                          <td
                            className="text-left pl-2 text-[12px] font-bold"
                            colSpan={20}
                          >
                            {report.name}
                          </td>
                        </tr>
                      )}

                      {r > 0 &&
                        reports.length > 0 &&
                        reports.length > 1 &&
                        r != reports.length - 1 &&
                        reports[r].id != reports[r - 1].id && (
                          <tr key={report.id} className="text-[10px]">
                            <td
                              className="text-left pl- text-[12px] font-bold"
                              colSpan={20}
                            >
                              {report.name}
                            </td>
                          </tr>
                        )}

                      {reports.length > 0 &&
                        r == reports.length - 1 &&
                        r > 1 && ( // comment the left r condition if breaks
                          <tr key={report.id} className="text-[10px]">
                            <td
                              className="text-left pl- text-[12px] font-bold"
                              colSpan={20}
                            >
                              {report.name}
                            </td>
                          </tr>
                        )}

                      <tr key={report.id} className="text-[10px]">
                        <td className="whitespace-nowrap text-left ml-1">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {report?.client?.name || report?.client}
                        </td>
                        <td>
                          {report.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td>
                          {report.expeditionPoint}
                          {"-"}
                          {number}
                        </td>
                        <td className="text-right">{report.quantity}</td>
                        <td className="text-right">
                          {numberWithCommas(report.price)}
                        </td>
                        <td className="text-right">
                          {numberWithCommas(report.total)}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        {/* <td className="text-right">0</td>
                      <td className="text-right">0</td> */}
                      </tr>

                      {r > 0 &&
                        reports.length > 0 &&
                        reports.length > 1 &&
                        r != reports.length - 1 &&
                        reports[r].id != reports[r + 1].id && (
                          <tr key={report.id} className="text-[10px]">
                            <td className="text-left pl- text-[12px] font-bold"></td>
                            <td className="text-left pl- text-[12px] font-bold"></td>
                            <td className="text-left pl- text-[12px] font-bold"></td>
                            <td className="text-left pl- text-[12px] font-bold text-right"></td>
                            <td className="text-left pl- text-[12px] text-right font-bold">
                              TOTALES:
                            </td>
                            {/* <td className="text-left pl- text-[12px] text-right font-bold">{report.totalPriceUn}</td> */}
                            <td className="text-left pl- text-[12px] text-right font-bold">
                              {report.totalPriceTot}
                            </td>
                            <td className="text-left pl- text-[12px] font-bold"></td>
                            <td className="text-left pl- text-[12px] font-bold"></td>
                          </tr>
                        )}

                      {reports.length > 0 && r == reports.length - 1 && (
                        <tr key={report.id} className="text-[10px]">
                          <td className="text-left pl- text-[12px] font-bold"></td>
                          <td className="text-left pl- text-[12px] font-bold"></td>
                          <td className="text-left pl- text-[12px] font-bold"></td>
                          <td className="text-left pl- text-[12px] font-bold text-right"></td>
                          <td className="text-left pl- text-[12px] text-right font-bold">
                            TOTALES:
                          </td>
                          {/* <td className="text-left pl- text-[12px] text-right font-bold">{report.totalPriceUn}</td> */}
                          <td className="text-left pl- text-[12px] text-right font-bold">
                            {report.totalPriceTot}
                          </td>
                          <td className="text-left pl- text-[12px] font-bold"></td>
                          <td className="text-left pl- text-[12px] font-bold"></td>
                        </tr>
                      )}
                    </>
                  );
                })}

              {bookOfSalesReport &&
                reports.length > 0 &&
                !isFetching &&
                reports.map((report, r) => {
                  let total = 0;
                  let number = String(report?.number) || 0;

                  Array(7 - number?.length)
                    .fill(0)
                    .map((_, i) => {
                      number = "0" + number;
                    });

                  total =
                    report.vat5 +
                    report.vat10 +
                    report.exempt +
                    report.taxed5 +
                    report.taxed10;

                  return (
                    <>
                      <tr key={report.id} className="text-[10px]">
                        <td className="w-[12%] whitespace-nowrap">
                          {report.expeditionPoint}
                          {"-"}
                          {number}
                        </td>
                        <td className="w-[10%]">
                          {report.createdAt
                            .substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("/")}
                        </td>
                        <td className="uppercase">
                          {report.salecondition.description}
                        </td>
                        <td className="whitespace-nowrap text-left ml-1 w-[38%]">
                          {report?.client?.name}
                        </td>
                        <td className="text-right w-[7%]">
                          {report.client.ruc}
                        </td>

                        <td className="text-right w-[6%]">
                          {numberWithCommas(report.taxed5)}
                        </td>
                        <td className="text-right w-[5%]">
                          {numberWithCommas(report.taxed10)}
                        </td>
                        <td className="text-right w-[6%]">
                          {numberWithCommas(report.vat5)}
                        </td>
                        <td className="text-right w-[5%]">
                          {numberWithCommas(report.vat10)}
                        </td>
                        <td className="text-right w-[6.5%]">
                          {numberWithCommas(report.exempt)}
                        </td>
                        <td className="text-right w-[8%]">
                          {numberWithCommas(total)}
                        </td>
                      </tr>
                    </>
                  );
                })}

              {/* {reports.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={20} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )} */}
            </tbody>
          </table>
        </div>
        {salesReport && reports.length > 0 && !isFetching && (
          <>
            <table className="!w-[92%] mx-auto sales-report-table">
              <tr className="text-[12px]" style={{ lineHeight: 2 }}>
                <td className="w-[12%]"></td>
                <td className="w-[10%]"></td>
                <td className="whitespace-nowrap text-left ml-1 w-[37.6%]"></td>
                <td className="text-right font-bold w-[7%]">TOTALES: </td>

                <td className="text-right font-bold w-[6%] border-gray-600 border-t-[1px] border-b-[1px]">
                  {numberWithCommas(reports[reports.length - 1].totalTaxed10)}
                </td>
                <td className="text-right font-bold w-[5%] border-gray-600 border-t-[1px] border-b-[1px]">
                  {numberWithCommas(reports[reports.length - 1].totalTaxed5)}
                </td>
                <td className="text-right font-bold w-[6.5%] border-gray-600 border-t-[1px] border-b-[1px]">
                  {numberWithCommas(reports[reports.length - 1].totalExempt)}
                </td>
                <td className="text-right font-bold w-[8%] border-gray-600 border-t-[1px] border-b-[1px]">
                  {numberWithCommas(reports[reports.length - 1].totalTotal)}
                </td>
                <td className="text-right font-bold w-[10%]"></td>
              </tr>
              <tr className="text-[12px]">
                <td className="w-[12%]"></td>
                <td className="w-[10%]"></td>
                <td className="whitespace-nowrap text-left ml-1 w-[37.6%]"></td>
                <td className="text-right font-bold w-[7%]"></td>

                <td className="text-right font-bold w-[6%] border-gray-600 border-t-[1px] border-b-[1px]"></td>
                <td className="text-right font-bold w-[5%] border-gray-600 border-t-[1px] border-b-[1px]"></td>
                <td className="text-right font-bold w-[6.5%] border-gray-600 border-t-[1px] border-b-[1px]"></td>
                <td className="text-right font-bold w-[8%] border-gray-600 border-t-[1px] border-b-[1px]"></td>
                <td className="text-right font-bold w-[10%]"></td>
              </tr>
            </table>
          </>
        )}

        {salesReportByItem && reports.length > 0 && !isFetching && (
          <>
            <table className="!w-[93%] mx-auto sales-report-table">
              <tr className="text-[10px]">
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                {/* <td className="text-left pl- text-[12px] text-right font-bold w-[15%]"></td> */}
                <td className="text-left pl- text-[12px] font-bold text-right w-[79%]">
                  TOTALES:{" "}
                </td>
                {/* <td className="text-left pl- text-[12px] text-right font-bold w-[15%]">{reports[reports.length-1].totalPriceUnGral}</td> */}
                <td className="text-left pl- text-[12px] text-right font-bold">
                  {reports[reports.length - 1].totalPriceTotGral}
                </td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
                <td className="text-left pl- text-[12px] font-bold"></td>
              </tr>
            </table>
          </>
        )}

        {bookOfSalesReport && reports.length > 0 && !isFetching && (
          <>
            <table className="!w-[93%] mx-auto sales-report-table">
              <tr className="text-[10px] leading-5">
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold text-right w-[77%]">
                  TOTALES:{" "}
                </td>
                <td className="text-left text-[12px] font-bold w-[1%] border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalTaxed5Ind}
                </td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] text-center w-[8%] font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalTaxed10Ind}
                </td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[3%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalVat5Ind}
                </td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[4%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalVat10Ind}
                </td>
                <td className="w-[6%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalExempt}
                </td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600">
                  {reports[reports.length - 1].totalTotal}
                </td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
              </tr>
              <tr className="text-[10px] leading-5">
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold text-right w-[77%]"></td>
                <td className="text-left text-[12px] font-bold w-[1%] border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] text-center w-[8%] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[3%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[4%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="w-[6%] text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] text-right font-bold border-t-[1px] border-b-[1px] border-gray-600"></td>
                <td className="text-left text-[12px] font-bold"></td>
                <td className="text-left text-[12px] font-bold"></td>
              </tr>
            </table>
          </>
        )}
      </Layout>
    </>
  );
};

export default SalesReport;
