import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPlus,
  faL,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition, Tab } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import useViewport from "../utils/useViewport";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import toast from "react-hot-toast";
import { numberWithCommas } from "../utils/constants";
import discard from "../assets/img/discard.png";
import door from "../assets/img/door.png";
import pdf from "../assets/img/pdf.png";
import printer from "../assets/img/printer.png";
import search from "../assets/img/search.webp";

const ProviderPaymentsViewer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [action, setAction] = useState("edit");

  const { width } = useViewport();

  const { page } = useParams();
  const user = useSelector((state) => state.main.user);
  

  const [payment, setPayment] = useState([]);

  const [payments, setPayments] = useState([]);

  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [paymentId, setPaymentId] = useState(0);
  const [state, setState] = useState(0);

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    let MyDateString =
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2);

    return MyDateString;
  });

  const [dateToFilter, setDateToFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .map((d) => (String(d).length == 1 ? "0" + String(d) : d))
      .reverse()
      .join("-")
  );

  async function fetchPayments() {
    let pageQuery = page === undefined ? 1 : page;
    setIsFetching(true);

    let paymentsRequest = await axiosAuth.get(
      "/providerpaymentsviewer?page=" +
        pageQuery +
        "&companyId=" +
        user.companyId +
        "&dateFrom=" +
        dateFromFilter +
        "&dateTo=" +
        dateToFilter
    );

    setIsFetching(false);

    if (paymentsRequest.data.error) {
      if (paymentsRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setPayments(paymentsRequest.data.providerpayments);
    // setTotal(salesRequest.data.sales.count);
    // setPages(salesRequest.data.sales.pages);
  }

  useEffect(() => {
    fetchPayments();
  }, []);

  useEffect(() => {
    fetchPayments();
  }, [location]);

  const editPayment = (paymentId) => {
    let payment = payments.filter((payment) => payment.id === paymentId)[0];
    setPayment(payment);
  }

  return (
    <>
      <Layout>

      <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setPayment([]);
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex">
              <div className="flex justify-center p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform sm:mt-[100px] mt-[0px] overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 w-[270px] pb-4 rounded-3xl">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-6 primary-color text-3xl"
                          >
                            Id Pago {payment?.id}
                          </Dialog.Title>
                          <div className="mt-5">
                            <div className="w-full">
                              <table>
                                <thead>
                                  {/* <th>Cuota Nro</th> */}
                                  <th>Monto</th>
                                  {/*<th>Pagado</th> */}
                                  <th>Estado</th>
                                </thead>
                                <tbody>
                                  {payment?.purchase?.paymentdetails.map(paymentdetail =>
                                  <tr>
                                    {/* <td>{paymentdetail?.due?.number}</td> */}
                                    <td>{paymentdetail?.amount}</td>
                                    {/* <td>{numberWithCommas(paymentdetail?.due?.amount)}</td>
                                    <td>{numberWithCommas(paymentdetail?.due?.paid)}</td> */}
                                    <td>{paymentdetail.state == 0 ? "ACTIVO" : "ANULADO"}</td>
                                  </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 justify-center flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          setPayment([]);
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="flex flex-row">

        <div className="flex flex-col sm:w-[45%]" style={{ borderRight: "1px solid lightgray" }}>
        <h1
          id="titlebig"
          className="ml-5 mt-5 text-3xl title font-family-thin secondary-color"
          
          >
          Visor de Pagos (Proveedores)
        </h1>
        <DateComponent className="sm:block hidden" />
          </div>
          <div className="flex flex-row w-full justify-end">
          <div className="w-[80%] sm:w-[50%] float-right flex flex-col">
            <div>
            <label className="uppercase">Fecha desde: </label>
            <input
              className="input-bg-color appearance-none border w-[50%] text-gray-700 leading-tight capitalize h-[25px] p-[6px] mr-[1px]"
              id="date"
              value={dateFromFilter}
              type="date"
              onChange={(e) => {
                setDateFromFilter(e.target.value);
              }}
              />
              </div>
              <div>
              <label className="uppercase">Fecha hasta:&nbsp; </label>
            <input
              className="input-bg-color appearance-none border w-[50%] text-gray-700 leading-tight capitalize h-[25px] p-[6px]"
              id="date"
              value={dateToFilter}
              type="date"
              onChange={(e) => {
                setDateToFilter(e.target.value);
              }}
            />
              </div>
          </div>
          <div className="relative left-[-50px]">
          <button
                type="button"
                className="inline-flex flex-col w-[75px] h-[55px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                onClick={() => {
                  fetchPayments();
                }}
              >
                <img src={search} className="w-8" />
                CONSULTAR
              </button>
              <button
                  type="button"
                  className="inline-flex w-[65px] h-[54px] ml-[-0px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
                  style={{ alignItems: "center" }}
                  onClick={() => navigate("/")}
                >
                  <img src={door} className="w-5" />
                  SALIR
                </button>
          </div>
          
        </div>
          </div>
        

        <div className="w-[95%] min-w-[780px] max-h-[70vh] p-5 mx-auto overflow-auto">
          <table className="w-[95%] table-auto mx-auto w-full text-xs mt-5 relative ">
            <thead className="bg-gray-600 h-[25px]">
              <tr>
                <th className="!text-white">NUMERO</th>
                <th className="!text-white">Proveedor</th>
                <th className="!text-white">Monto</th>
                <th className="!text-white">Intereses</th>
                <th className="!text-white">Fecha</th>
                <th className="!text-white">Estado</th>
                <th className="!text-white">Accion</th>
              </tr>
            </thead>
            <tbody className="relative">
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {payments.length > 0 &&
                !isFetching &&
                payments?.map((payment, i) => {
                  return (
                    <tr
                      key={payment.id}
                      style={{ borderBottom: "1px solid gray" }}
                      className={`text-[10px] ${
                        payment?.state == 1 ? "bg-red-200 h-[23px]" : ""
                      }`}
                    >
                      <td className="text-center uppercase">
                        {payment.state != 1
                          ? payment.purchase.expeditionPoint +
                            " - " +
                            payment.purchase.number
                          : "ANULADO"}
                      </td>
                      <td className="text-left uppercase whitespace-nowrap overflow-hidden">
                        {payment.state != 1
                          ? payment.purchase.provider.name
                          : "ANULADO"}
                      </td>
                      <td className="text-center uppercase">
                        {payment.state != 1
                          ? numberWithCommas(payment.amount)
                          : 0}
                      </td>
                      
                      <td>
                        {payment.state != 1
                          ? payment.createdAt
                              .substr(0, 10)
                              .split("-")
                              .reverse()
                              .join("/")
                          : "ANULADO"}
                      </td>
                      <td>{payment.state == 1 ? "ANULADO" : "ACTIVO"}</td>
                      <td className="text-center">
                      {payment.state == 1 ? "ANULADO" : <>
                          <button
                            onClick={() => {
                              setOpen(true);
                              editPayment(payment.id);
                            }}
                            className="bg-transparent"
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faEye}
                            />
                          </button>

                          <button
                            onClick={async () => {

                              if (window.confirm("Desea anular este pago?")) {
                                await axiosAuth.put("/cancelproviderpayment", {
                                  id: payment?.id,
                                });

                                let newPayments = [...payments];
                                newPayments.map((paymnt) => {
                                  if (paymnt.id == payment.id) {
                                    paymnt.state = 1;
                                  }
                                });
                                setPayments(newPayments);
                              }
                            }}
                            className=""
                          >
                            ANULAR
                          </button></>
                        }
                        {/* {payment.state != 1 ? (
                          <button
                            onClick={async () => {
                              //editPayment(payment.id);

                              if (window.confirm("Desea anular este pago?")) {
                                await axiosAuth.put("/cancelproviderpayment", {
                                  id: payment.id,
                                });

                                let newPayments = [...payments];
                                newPayments.map((paymnt) => {
                                  if (paymnt.id == payment.id) {
                                    paymnt.state = 1;
                                  }
                                });
                                setPayments(newPayments);
                              }
                            }}
                            className=""
                          >
                            ANULAR
                          </button>
                        ) : (
                          ""
                        )} */}
                      </td>
                    </tr>
                  );
                })}
              {payments.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={18} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Layout>
    </>
  );
};

export default ProviderPaymentsViewer;
