import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser, setTitle } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import DateComponent from "../components/date";
import ComboBox from "../components/combobox";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import customer from "../assets/img/customer.png";
import door from "../assets/img/door.png";
import floppy from "../assets/img/floppy.png";
import useViewport from "../utils/useViewport";
import plus from "../assets/img/plus.png";
import { motion } from "framer-motion";

const Clients = () => {
  const user = useSelector((state) => state.main.user);

  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const { page } = useParams();
  const { width } = useViewport();

  const [isFetching, setIsFetching] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("edit");
  const [forMesagge, setFormessage] = useState("");
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [clients, setClients] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [cities, setCities] = useState([]);
  const [zones, setZones] = useState([]);
  const [dataOrigins, setDataOrigins] = useState([]);

  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  const [clientId, setClientId] = useState(0);
  const [name, setName] = useState("");
  const [ruc, setRuc] = useState("");
  const [cumple, setCum] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [hood, setHood] = useState("");
  const [companyId, setCompanyId] = useState(user.companyId);
  const [cityId, setCityId] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [dataOriginId, setDataOriginId] = useState(0);
  const [observations, setObservations] = useState("");
  const [fantasyname, setFantasyname] = useState("");
  const [search, setSearch] = useState("");
  const [potential, setPotential] = useState(0);
  const [active, setActive] = useState(0);
  const [deleted, setDeleted] = useState(0);
  const [errors, setErrors] = useState({});

  async function fetchClientsData() {
    let pageQuery = page == undefined ? 1 : page;

    setIsFetching(true);

    let searchString = "";

    if (state?.search != "") {
      searchString = "&search=" + state?.search;
      setSearch(state?.search);
    } else {
      searchString = "&search=";
    }

    let data = await axiosAuth.get("/clients?page=" + pageQuery + searchString);

    if (data.data.error) {
      if (data.data.message === "token") {
        await axiosAuth.post("/logout");
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }

    setIsFetching(false);

    setClients(data.data.clients.rows);
    setPages(data.data.clients.pages);
    setTotal(data.data.clients.count);

    data.data.companies.unshift({ id: 0, name: "Elija una empresa" });
    setCompanies(data.data.companies);

    setCities(data.data.cities);
    setZones(data.data.zones);
    setDataOrigins(data.data.dataorigins);
  }

  useEffect(() => {
    fetchClientsData();
    dispatch(setTitle("Clientes"));
  }, []);

  useEffect(() => {
    fetchClientsData();
  }, [location]);

  const editClient = (clientId) => {
    setAction("edit");
    setClientId(clientId);

    let client = clients.filter((client) => client.id === clientId)[0];

    setContacts(client.contacts);
    setName(client.name);
    setRuc(client.ruc);
    setAddress(client.address);
    setPhone(client.phone);
    setEmail(client.email);
    setHood(client.hood);
    setCompanyId(client.companyId);
    setCityId(client.cityId);
    setZoneId(client.zoneId);
    setDataOriginId(client.dataoriginId);
    setObservations(client.observations);
    setPotential(client.potential);
    setActive(client.active);
    setDeleted(client.deleted);
    setCum(client.cumple);
    setFantasyname(client.fantasyname);

    setOpen(true);
  };

  const clearFields = () => {
    setContacts([]);
    setClientId(0);
    setName("");
    setRuc("");
    setAddress("");
    setCum("");
    setEmail("");
    setPhone("");
    setHood("");
    setCompanyId(user.companyId);
    setCityId(0);
    setZoneId(0);
    setObservations("");
    setPotential(0);
    setActive(0);
    setDeleted(0);
    setFantasyname("");
  };

  const handleSubmit = async () => {
    if (action === "edit") {
      let objToSend = {
        clientId,
        name,
        ruc,
        address,
        phone,
        email,
        hood,
        companyId,
        cityId,
        zoneId,
        dataOriginId,
        observations,
        potential,
        active,
        deleted,
        cumple,
        fantasyname,
      };

      let updatedClientRequest = await axiosAuth.put("/clients", objToSend);

      if (updatedClientRequest.data.error) {
        if (updatedClientRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }

        setFormessage(updatedClientRequest.message);
      } else {
        let updatedClientsNewObj = [];

        clients.map((client) => {
          if (client.id == clientId) {
            updatedClientsNewObj.push(updatedClientRequest.data);
          } else {
            updatedClientsNewObj.push(client);
          }
          return "";
        });

        setClients(updatedClientsNewObj);
        setOpen(false);
      }
    } else {
      const newErrors = {};

      if (name === "") newErrors.name = "*";
      if (ruc === "") newErrors.ruc = "*";
      if (companyId === 0) newErrors.companyId = "*";
      if (cityId === 0) newErrors.cityId = "*";
      if (zoneId === 0) newErrors.zoneId = "*";
      if (dataOriginId === 0) newErrors.dataOriginId = "*";

      if (Object.keys(newErrors).length > 0) {
        window.alert("Debe completar los campos obligatorios!");
        setErrors(newErrors); // Actualiza el estado de errores
        return false;
      }

      let createdClient = await axiosAuth.post("/clients", {
        name,
        ruc,
        address,
        phone,
        email,
        hood,
        companyId,
        cityId,
        zoneId,
        dataOriginId,
        observations,
        potential,
        active,
        deleted,
        cumple,
        fantasyname,
      });

      if (createdClient.data.error) {
        if (createdClient.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }

        if (createdClient.data.message === "ruc" ) {
          window.alert("Ya existe un cliente con el RUC ingresado!");
        }

        setFormessage(createdClient.data.message);
      } else {
        let newClients = [...clients];
        newClients.push(createdClient.data);
        setClients(newClients);
        setOpen(false);
        clearFields();
      }
    }
  };

  const deleteClient = async (clientId) => {
    if (window.confirm("Desea eliminar este cliente ?")) {
      let clientDeleteRequest = await axiosAuth.delete("/clients", {
        params: { id: clientId },
      });

      if (clientDeleteRequest.data.error) {
        if (clientDeleteRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
      }

      let newClientsObj = [];
      let loggedUser = user;

      clients.map((client) => {
        if (client.id !== clientId) {
          newClientsObj.push(client);
        } else {
          if (loggedUser.roleId == 1) {
            client.deleted = 1;
            newClientsObj.push(client);
          }
        }
        return "";
      });

      setClients(newClientsObj);
    }
  };

  const searchClients = async (search) => {
    setSearch((prev) => search);
    const searchClientsRequest = await axiosAuth.get(
      "/searchClients?search=" + search
    );

    setClients(searchClientsRequest.data.clients.rows);
    setPages(searchClientsRequest.data.clients.pages);
    setTotal(searchClientsRequest.data.clients.count);
  };

  return (
    <>
      <Layout>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              clearFields();
              setOpen(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto align-items-center justify-center items-center flex pb-5">
              <div className="flex justify-center p-4 text-center sm:p-0 w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel
                    className={`relative transform overflow-hidden rounded-3xl bg-white text-left shadow-xl transition-all sm:my-4 ${
                      action == "edit" ? "w-[90%]" : "w-[45%]"
                    } ${width > 1024 ? "w-[45%]" : "w-[90%]"}`}
                    style={{
                      marginTop: action == "edit" ? 400 : 0,
                      marginTop: width > 1024 ? 0 : 100,
                    }}
                  >
                    <div className="bg-white px-4 pb-4 pt-1 sm:p-6 sm:pb-0">
                      <div className="sm:flex sm:items-start w-full">
                        <div className="text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h1"
                            className="text-center font-regular leading-8 primary-color text-2xl"
                          >
                            {action === "edit" ? "Editar" : "Crear"} cliente
                          </Dialog.Title>
                          <div className="mt-1">
                            <div className="w-full">
                              <form
                                className="bg-white rounded w-full"
                                action=""
                                onSubmit={(e) => e.preventDefault()}
                              >
                                <div className="flex flex-col md:flex-row w-full">
                                  <div
                                    className={`${
                                      action == "edit"
                                        ? "w-[100%] md:[50%]"
                                        : "w-[100%]"
                                    } sm:border-none border-b-2 border-gray-600 pb-3`}
                                  >
                                    {action === "edit" && (
                                      <div className="mb-[1px] flex flex-row items-center">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label
                                          className="block text-gray-700 text-[10px] font-bold uppercase"
                                          htmlFor="id"
                                        >
                                          Id:
                                        </label>
                                        &nbsp;
                                        <input
                                          readOnly
                                          className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                          id="id"
                                          type="text"
                                          value={clientId}
                                        />
                                      </div>
                                    )}

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="name"
                                      >
                                        Nombre:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[27px]"
                                        id="name"
                                        type="text"
                                        value={name}
                                        onChange={(e) =>
                                          setName(e.target.value)
                                        }
                                        style={{
                                          borderColor: errors.name
                                            ? "red"
                                            : "#d3d3d3",
                                        }}
                                      />
                                     {/*  {errors.name && (
                                        <p style={{ color: "red" }}>
                                          {errors.name}
                                        </p>
                                      )} */}
                                    </div>
                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="name"
                                      >
                                        Nombre Fantasia:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline capitalize h-[27px]"
                                        id="name"
                                        type="text"
                                        value={fantasyname}
                                        onChange={(e) =>
                                          setFantasyname(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[0.5px]"
                                        htmlFor="ruc"
                                      >
                                        RUC:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="ruc"
                                        type="text"
                                        value={ruc}
                                        onChange={(e) => setRuc(e.target.value)}
                                      
                                      style={{
                                        borderColor: errors.ruc
                                          ? "red"
                                          : "#d3d3d3",
                                      }}
                                      />
                                    </div>
                                    <div className="mb-[1px] flex flex-row items-center">
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[0.5px]"
                                        htmlFor="Cumpleaños"
                                      >
                                        Cumpleaños:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="cumple"
                                        type="date"
                                        value={cumple}
                                        onChange={(e) => setCum(e.target.value)}
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase ml-[2px]"
                                        htmlFor="address"
                                      >
                                        Direccion:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="address"
                                        type="text"
                                        value={address}
                                        onChange={(e) =>
                                          setAddress(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase ml-[2px]"
                                        htmlFor="phone"
                                      >
                                        Telefono:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-6 relative w-full h-[27px]"
                                        id="phone"
                                        type="text"
                                        value={phone}
                                        onChange={(e) =>
                                          setPhone(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px]"
                                        htmlFor="email"
                                      >
                                        Email:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="email"
                                        type="text"
                                        value={email}
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase"
                                        htmlFor="hood"
                                      >
                                        Barrio:
                                      </label>
                                      &nbsp;
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="hood"
                                        type="text"
                                        value={hood}
                                        onChange={(e) =>
                                          setHood(e.target.value)
                                        }
                                      />
                                    </div>

                                    {user.roleId == 1 && (
                                      <>
                                        <div className="mb-[1px] flex flex-row items-center">
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <label
                                            className="block text-gray-700 text-[10px] font-bold uppercase"
                                            htmlFor="companyId"
                                          >
                                            Empresa:
                                          </label>
                                          &nbsp;
                                          <ComboBox
                                            data={companies}
                                            setData={setCompanyId}
                                            value={companyId}
                                            className="border h-[27px] w-[100.5%]"
                                            style={{
                                              borderColor: errors.companyId
                                                ? "red"
                                                : "#d3d3d3",
                                            }}
                                          />
                                        </div>
                                      </>
                                    )}
                                    {/* <select
                                    className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="companyId"
                                    value={companyId}
                                    onChange={(e) => {
                                      setCompanyId(e.target.value);
                                    }}
                                  >
                                    <option value={0}>Elija una empresa</option>
                                    {companies.map((company) => (
                                      <option
                                        key={company.id}
                                        value={company.id}
                                      >
                                        {company.name}
                                      </option>
                                    ))}
                                  </select> */}

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px]"
                                        htmlFor="cityId"
                                      >
                                        Ciudad:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[27px]"
                                        id="cityId"
                                        value={cityId}
                                        onChange={(e) => {
                                          setCityId(parseInt(e.target.value));
                                        }}
                                        style={{
                                          borderColor: errors.cityId
                                            ? "red"
                                            : "#d3d3d3",
                                        }}
                                      >
                                        <option value={0}>
                                          Elija una ciudad
                                        </option>
                                        {cities.map((city) => (
                                          <option key={city.id} value={city.id}>
                                            {city.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px]"
                                        htmlFor="zoneId"
                                      >
                                        Zona:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[27px]"
                                        id="zoneId"
                                        value={zoneId}
                                        onChange={(e) => {
                                          setZoneId(parseInt(e.target.value));
                                        }}
                                        style={{
                                          borderColor: errors.zoneId
                                            ? "red"
                                            : "#d3d3d3",
                                        }}
                                      >
                                        <option value={0}>
                                          Elija una zona
                                        </option>
                                        {zones
                                        .filter((zone)=> zone.companyId === user.companyId)
                                        .map((zone) => (
                                          <option key={zone.id} value={zone.id}>
                                            {zone.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[3px]"
                                        htmlFor="zoneId"
                                      >
                                        Origen:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[10px] h-[27px]"
                                        id="zoneId"
                                        value={dataOriginId}
                                        onChange={(e) => {
                                          setDataOriginId(
                                            parseInt(e.target.value)
                                          );
                                        }}
                                        style={{
                                          borderColor: errors.dataOriginId
                                            ? "red"
                                            : "#d3d3d3",
                                        }}
                                      >
                                        <option value={0}>
                                          Elija un origen
                                        </option>
                                        {dataOrigins
                                        .filter((dataOrigin)=> dataOrigin.companyId === user.companyId)
                                        .map((dataOrigin) => (
                                          <option
                                            key={dataOrigin.id}
                                            value={dataOrigin.id}
                                          >
                                            {dataOrigin.description}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="mb-[1px] flex flex-row items-center">
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                        htmlFor="observations"
                                      >
                                        Observacion:
                                      </label>
                                      <input
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[27px]"
                                        id="observations"
                                        type="text"
                                        value={observations}
                                        onChange={(e) =>
                                          setObservations(e.target.value)
                                        }
                                      />
                                    </div>

                                    {/* <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                        htmlFor="potential"
                                      >
                                        Potencial:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[11px] h-[28px]"
                                        id="potential"
                                        value={potential}
                                        onChange={(e) =>
                                          setPotential(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div> */}

                                    <div className="mb-[1px] flex flex-row items-center">
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <label
                                        className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                        htmlFor="active"
                                      >
                                        Activo:
                                      </label>
                                      &nbsp;
                                      <select
                                        className="input-bg-color appearance-none border w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[11px] h-[27px]"
                                        id="active"
                                        value={active}
                                        onChange={(e) =>
                                          setActive(parseInt(e.target.value))
                                        }
                                      >
                                        <option value={0}>No</option>
                                        <option value={1}>Si</option>
                                      </select>
                                    </div>

                                    {user.roleId == 1 && (
                                      <div className="mb-[1px] flex flex-row items-center">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label
                                          className="block text-gray-700 text-[10px] font-bold uppercase mr-[2px]"
                                          htmlFor="deleted"
                                        >
                                          Borrado:
                                        </label>
                                        &nbsp;
                                        <select
                                          className="input-bg-color appearance-none border w-[101%] py-2 px-3 text-gray-700 leading-[10px] focus:outline-none focus:shadow-outline h-[27px]"
                                          id="deleted"
                                          value={deleted}
                                          onChange={(e) =>
                                            setDeleted(parseInt(e.target.value))
                                          }
                                        >
                                          <option value={0}>No</option>
                                          <option value={1}>Si</option>
                                        </select>
                                      </div>
                                    )}
                                  </div>

                                  {action == "edit" && (
                                    <div className="w-[100%] md:[50%] mt-3 md:mt-0 flex flex-col">
                                      <div className="w-full max-h-[30vh] overflow-auto ml-1">
                                        <h2 className="text-center primary-color-bg !text-white uppercase text-[12px]">
                                          Contactos
                                        </h2>
                                        <table className="text-[12px] relative !top-0 !w-full">
                                          <thead className="primary-color-bg">
                                            <tr>
                                              <th className="!text-white text-[11px] font-thin">
                                                Nombre
                                              </th>
                                              <th className="!text-white text-[11px] w-[23%] font-thin">
                                                Telefono
                                              </th>
                                              <th className="!text-white text-[11px] font-thin">
                                                Email
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody className="text-[10px]">
                                            {width > 550 &&
                                              contacts.length > 0 &&
                                              contacts.map((contact) => (
                                                <tr>
                                                  <td className="text-left">
                                                    {contact.name}
                                                  </td>
                                                  <td>{contact.phone}</td>
                                                  <td className="text-left">
                                                    {contact.email}
                                                  </td>
                                                </tr>
                                              ))}

                                            {width < 550 &&
                                              contacts.length > 0 &&
                                              contacts.map((contact) => (
                                                <tr>
                                                  <td
                                                    className="text-left border-b-[1px] border-gray-600"
                                                    colSpan={3}
                                                  >
                                                    <b className="uppercase">
                                                      Nombre:{" "}
                                                    </b>
                                                    {contact.name}
                                                    <br />
                                                    <b className="uppercase">
                                                      Telefono:{" "}
                                                    </b>
                                                    {contact.phone}
                                                    <br />
                                                    <b className="uppercase">
                                                      Email:{" "}
                                                    </b>
                                                    {contact.email}
                                                  </td>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="flex flex-col justify-center sm:flex-row m-1 border-none sm:border-t-[1px] sm:border-b-2 border-gray-600">
                                        <input
                                          className="w-[100%] sm:w-[33.2%] h-6 border rounded m-1 px-2 text-[11px]"
                                          name="contact-name"
                                          placeholder="Nombre"
                                          onChange={(e) =>
                                            setContactName(e.target.value)
                                          }
                                          value={contactName}
                                          type="text"
                                        />
                                        <input
                                          className="w-[100%] sm:w-[33.2%] h-6 border rounded m-1 px-2 text-[11px]"
                                          name="contact-phone"
                                          placeholder="Telefono"
                                          onChange={(e) =>
                                            setContactPhone(e.target.value)
                                          }
                                          value={contactPhone}
                                          type="text"
                                        />
                                        <input
                                          className="w-[100%] sm:w-[33.2%] h-6 border rounded m-1 px-2 text-[11px]"
                                          name="contact-email"
                                          placeholder="Email"
                                          onChange={(e) =>
                                            setContactEmail(e.target.value)
                                          }
                                          value={contactEmail}
                                          type="text"
                                        />
                                        <button
                                          onClick={async () => {
                                            if (
                                              contactName == "" ||
                                              contactEmail == "" ||
                                              contactPhone == ""
                                            ) {
                                              alert(
                                                "Debe completar los campos para guardar el contacto"
                                              );
                                              return false;
                                            }

                                            let createContactRequest =
                                              await axiosAuth.post(
                                                "/contacts",
                                                {
                                                  clientId,
                                                  name: contactName,
                                                  phone: contactPhone,
                                                  email: contactEmail,
                                                }
                                              );

                                            let newContacts = [...contacts];
                                            newContacts.push(
                                              createContactRequest.data
                                            );
                                            setContacts(newContacts);

                                            let newClients = [...clients];
                                            newClients.map((client) => {
                                              if (client.id == clientId) {
                                                client.contacts = newContacts;
                                              }
                                            });
                                            setClients(newClients);

                                            setTimeout(function () {
                                              setContactName("");
                                              setContactPhone("");
                                              setContactEmail("");
                                            }, 1000);
                                          }}
                                          className="p-1 mx-auto h-8"
                                        >
                                          <img
                                            src={floppy}
                                            className="w-8 relative top-[-6px]"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 pt-0 justify-start flex sm:px-6">
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] h-[68px] float-left m-1 flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 text-[11px] top-[-4px] sm:top-0"
                        style={{ alignItems: "center" }}
                        onClick={() => handleSubmit()}
                      >
                        <img src={floppy} className="w-8" />
                        GUARDAR
                      </button>
                      <button
                        type="button"
                        className="relative inline-flex w-[68px] h-[68px] float-left flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 text-[11px]"
                        style={{ alignItems: "center" }}
                        onClick={() => {
                          clearFields();
                          setOpen(false);
                        }}
                      >
                        <img src={door} className="w-5" />
                        CERRAR
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/*  <div style={{ borderBottom: "1px solid lightgray" }} className="flex flex-row w-full relative top-[-35px] pb-3"> */}

        <div
          class="w-full top-[-50px] flex flex-col sm:flex-row pb-2 relative bg-white "
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <div className="sm:w-[30%] w-[60%]">
            <h1 className="ml-5 mt-5 text-3xl secondary-color title font-family-thin">
              Clientes
            </h1>
            <DateComponent className="sm:block hidden" />
          </div>
          <div className="sm:w-[50%] w-[85%] mb-[1px] sm:ml-0 ml-6 flex flex-row items-center w-200 sm:mt-2 mt-12">
            {/*  <div className="flex flex-col justify-start w-100 float-right relative right-[60px] space-y-2"> */}
            <label className="block text-gray-700 text-[10px] font-bold uppercase mr-[1px] sm:mt-5">
              Buscar
            </label>
            <input
              className="input-bg-color appearance-none border w-[350px]  rounded  
                py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline leading-[5px] h-[35px] sm:mt-5"
              placeholder="Nombre, RUC, etc."
              onChange={(e) => searchClients(e.target.value)}
            />
            {/*   </div> */}
            <motion.button
              initial={false}
              whileTap="press"
              onClick={() => {
                setAction("create");
                setOpen(true);
              }}
              className="inline-flex flex-row float-right sm:w-[170px] w-[30%] mr-1 justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 relative left-[10px] sm:mt-5 h-[35px] "
            >
              {/*<Suspense fallback={null}>
                  <BoxesIcon isHover={true} />
              </Suspense>*/}
              <img alt="" src={plus} className="w-8" />
              AGREGAR
            </motion.button>
          </div>
        </div>

        <div className="w-[95%] p-5 mx-auto overflow-auto sm:max-h-[70vh] max-h-[70vh] mt-[-50px]">
          <table className="w-[95%] table-auto mx-auto w-full text-[10px] clients-table support-table">
            <thead className="bg-gray-500 h-8">
              {width > 1024 ? (
                <tr>
                  <th className="uppercase !text-white p-2">Id</th>
                  <th className="uppercase !text-white p-2 text-left">
                    Nombre
                  </th>
                  <th className="uppercase !text-white p-2 text-left">
                    Nombre Fantasia
                  </th>
                  <th className="uppercase !text-white p-2 text-left">RUC</th>
                  <th className="uppercase !text-white p-2 text-left">
                    Direccion
                  </th>
                  <th className="uppercase !text-white p-2 text-left">
                    Telefono
                  </th>
                  {/* <th className="uppercase">Email</th> */}
                  <th className="uppercase !text-white p-2 text-left">
                    Barrio
                  </th>

                  <th className="uppercase !text-white p-2 text-left">
                    Ciudad
                  </th>
                  <th className="uppercase !text-white p-2 text-left">Zona</th>
                  {/* <th className="uppercase">Observacion</th> */}

                  <th className="uppercase !text-white p-2">Fecha Alta</th>
                  <th className="uppercase !text-white p-2">Accion</th>
                </tr>
              ) : (
                <></>
              )}
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={15}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {clients.length > 0 &&
                !isFetching &&
                clients?.map((client) => {
                  if (width > 1024) {
                    return (
                      <tr key={client.id}>
                        <td>{client.id}</td>
                        <td className="text-left whitespace-nowrap">
                          {client.name}
                        </td>
                        <td className="text-left whitespace-nowrap">
                          {client.fantasyname}
                        </td>
                        <td className="text-left whitespace-nowrap text-left">
                          {client.ruc}
                        </td>
                        <td className="text-left whitespace-nowrap text-left">
                          {client.address}
                        </td>
                        <td className="text-left whitespace-nowrap text-left">
                          {client.phone}
                        </td>
                        {/* <td>{client.email}</td> */}
                        <td className="text-left whitespace-nowrap text-left">
                          {client.hood}
                        </td>

                        <td className="text-left whitespace-nowrap text-left">
                          {client?.city?.name}
                        </td>
                        <td className="text-left whitespace-nowrap text-left">
                          {client?.zone?.name}
                        </td>
                        {/* <td>{client.observations}</td> */}

                        <td className="text-left whitespace-nowrap text-left">
                          {client?.createdAt
                            ?.substr(0, 10)
                            ?.split("-")
                            ?.reverse()
                            ?.join("/")}
                        </td>
                        <td className="text-center">
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              editClient(client.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faPen}
                            />
                          </button>
                          <button
                            className="bg-transparent"
                            onClick={() => {
                              deleteClient(client.id);
                            }}
                          >
                            <FontAwesomeIcon
                              className="m-1 cursor-pointer secondary-color"
                              icon={faTrash}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <label className="font-bold">ID: </label>
                        {client.id}
                        <br />
                        <label className="font-bold">NOMBRE: </label>
                        {client.name}
                        <br />
                        <label className="font-bold">NOMBRE FANTASIA: </label>
                        {client.fantasyname}
                        <br />
                        <label className="font-bold">RUC: </label>
                        {client.ruc}
                        <br />
                        <label className="font-bold">TELEFONO: </label>
                        {client.phone}
                        <br />
                        <label className="font-bold">ACCION:</label>
                        <button
                          className="m-[1px] bg-transparent"
                          onClick={() => {
                            editClient(client.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer secondary-color"
                            icon={faPen}
                          />
                        </button>
                        <button
                          className="m-[1px] bg-transparent"
                          onClick={() => {
                            deleteClient(client.id);
                          }}
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer secondary-color"
                            icon={faTrash}
                          />
                        </button>
                      </tr>
                    );
                  }
                })}
              {clients.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={15} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            className="clients-pagination-container"
            path="clientes"
            page={page}
            pages={pages}
            total={total}
            params={{ search }}
          />
        </div>
      </Layout>
    </>
  );
};

export default Clients;
