import React, { useState, useRef, useEffect } from "react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";

import { DownloadTableExcel } from "react-export-table-to-excel";
import xls from "../assets/img/xls.png";
import search from "../assets/img/search.webp";
import { numberWithCommas } from "../utils/constants";

const RG90 = () => {
  const tableRef = useRef(null);

  const location = useLocation();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [type, seType] = useState("sales");

  const user = useSelector((state) => state.main.user);

  const [report, setReport] = useState([]);

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .getFullYear()
        .toLocaleString();

    return MyDateString.replaceAll(".", "")
      .replaceAll("/", "-")
      .split("-")
      .reverse()
      .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .reverse()
      .map((d) => (d.length <= 1 ? "0" + String(d) : d))
      .join("-")
  );

  async function fetchReport() {
    setIsFetching(true);

    let reportRequest = await axiosAuth.get(
      "/report?type=" +
        type +
        "&dateFrom=" +
        dateFromFilter +
        "&dateTo=" +
        dateToFilter
    );

    if (reportRequest.data.error) {
      if (reportRequest.data.message === "token") {
        dispatch(setUser(null));
        dispatch(setToken(null));
      }
    }
    setIsFetching(false);
    setReport(reportRequest.data.report);
    console.log(reportRequest.data.report);
  };

  useEffect(() => {
    fetchReport();
  }, [location, type]);

  return (
    <>
      <Layout>
        
        <div className="w-full flex flex-col sm:flex-row pb-2 relative bg-white "
         style={{ borderBottom: "1px solid lightgray" }}
        >
        
        <div className="mr-2 sm:w-[30%] w-[60%]">
        <h1 className="ml-5 mt-[-10] text-3xl secondary-color title font-family-thin">
          Reportes
        </h1>
        <DateComponent className="sm:block hidden" />
        </div>
       
          <div className="mr-2 flex flex-row">
            <label
              className="block text-gray-700 text-sm font-bold"
              htmlFor="typeFilter"
            >
              TIPO:
            </label>
            <select
              id="typeFilter"
              name="typeFilter"
              value={type}
              className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[30px]"
              style={{ lineHeight: "13px" }}
              onChange={(e) => { seType(e.target.value); }}
            >
              <option value="sales">VENTAS RG90</option>
              <option value="purchases">COMPRAS RG90</option>
            </select>
          </div>

          <div className="mr-2 flex flex-row">
            <label
              className="block text-gray-700 text-sm font-bold"
              htmlFor="dateFromFilter"
            >
              FECHA DESDE:
            </label>
            <input
              type="date"
              id="dateFromFilter"
              name="dateFromFilter"
              value={dateFromFilter}
              className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[30px]"
              onChange={(e) => setDateFromFilter(e.target.value)}
            />
          </div>

          <div className="mr-2 flex flex-row">
            <label
              className="block text-gray-700 text-sm font-bold"
              htmlFor="dateToFilter"
            >
              FECHA HASTA:
            </label>
            <input
              type="date"
              id="dateToFilter"
              name="dateToFilter"
              value={dateToFilter}
              className="input-bg-color appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[30px]"
              onChange={(e) => {
                setDateToFilter(e.target.value);
              }}
              onBlur={() => fetchReport()}
            />
          </div>
          <div className="mr-2 flex flex-row" >
          
            <DownloadTableExcel
              filename={"RG90-" + new Date().toLocaleDateString()}
              sheet="RG90"
              currentTableRef={tableRef.current}
            >
              <button
                type="button"
                className="inline-flex flex-col w-[80px] mr-1 justify-center items-center 
                rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
              >
                <img src={xls} className="w-6" />
                Exportar 
              </button>
            </DownloadTableExcel>
          </div>
          <div className="mr-2   flex flex-row">
          <button
                type="button"
                className="inline-flex flex-col w-[80px] mr-1 justify-center items-center 
                rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0"
              >
                <img src={search} className="w-6" />
                Consultar 
              </button>
          </div>
      
        </div>
        <div className="w-[95%] p-5 pb-0 mx-auto overflow-auto mt-5 max-h-[400px]">
          <table
            className="w-[95%] table-auto mx-auto w-full text-xs rg90-table"
            ref={tableRef}
          >
            <thead>
              <tr>
                <th className="uppercase">CODREG</th>
                <th className="uppercase">CODTIP</th>
                <th className="uppercase w-[100px]">RUC</th>
                <th className="uppercase w-[180px]">NOMBRES</th>
                <th className="uppercase">CODCOM</th>
                <th className="uppercase">FECHA</th>
                <th className="uppercase">TIMBRADO</th>
                <th className="uppercase w-[150px] ">NUMERO</th>
                <th className="uppercase w-[120px] text-right">GRAV 10</th>
                <th className="uppercase w-[120px]  text-right">GRAV 5</th>
                <th className="uppercase text-right">EXCENTAS</th>
                <th className="uppercase">TOTAL</th>
                <th className="uppercase">CONDICION</th>
                <th className="uppercase">MONEXTRA</th>
                <th className="uppercase">IVA</th>
                <th className="uppercase">IRE</th>
                <th className="uppercase">IRP</th>
                <th className="uppercase">COMPASO</th>
                <th className="uppercase">TIMBASO</th>
              </tr>
            </thead>
            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={20}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}
              {report.length > 0 &&
                !isFetching && 
                report.filter((report) => report.saleconditionId === 1 || report.saleconditionId === 2).map((report) => {
                  let total = 0;
                  let number = String(report.number);

                  Array(7 - number.length)
                    .fill(0)
                    .map((_, i) => {
                      number = "0" + number;
                    });
                  total =
                    report.vat5 +
                    report.vat10 +
                    report.exempt +
                    report.taxed5 +
                    report.taxed10;
                   
                      return (
                    
                    <tr key={report.id} className="text-[10px]">
                      <td>1</td>
                      <td>
                      {report?.client?.name?.trim() =='NO DEFINIDO' || report?.provider?.name?.trim() =='NO DEFINIDO'? "15" : report?.client?.ruc?.split('-').length>1 || report?.provider?.ruc?.split('-').length>1 ? "11" : "12"}
                      

                      </td>
                      <td className="text-right pr-3">
                      {
                        //report.client.ruc.substr(
                        //  0,
                        //report.client.ruc.indexOf("-")
                          
                       // )
                        report?.client?.ruc?.trim().split('-')[0] || report?.provider?.ruc?.trim().split('-')[0]
                        }
                      </td>
                      <td className="whitespace-nowrap text-left ml-1">
                        {report?.client?.name || report?.provider?.name}
                      </td>
                      <td>109</td>
                      <td>
                        {report.createdAt
                          .substr(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                      </td>
                      <td>{report.stamped}</td>
                      <td>
                        {report.expeditionPoint}
                        {"-"}
                        {number}
                      </td>
                      <td className="text-right">
                        {(report.taxed10 + report.vat10)}
                      </td>
                      <td className="text-right">
                        {(report.taxed5 + report.vat5)}
                      </td>
                      <td className="text-right">
                        {(report.exempt)}
                      </td>
                      <td className="text-right">{(total)}</td>
                      <td>{report.saleconditionId}</td>
                      <td>{report.currencyId == 1 ? "N" : "S"}</td>
                      <td>S</td>
                      <td>N</td>
                      <td>N</td>
                      <td>N</td>
                      <td>N</td>
                    </tr>
                  );
              
                })
                    
              }
                
              {report.length <= 0 && !isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={20} className="text-center">
                    Sin registros (0)
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Layout>
    </>
  );
};

export default RG90;
