import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import floppy from "../assets/img/floppy.png";
import door from "../assets/img/door.png";

const StockTransfers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deposits, setDeposits] = useState([]);
    const [selectedOriginDeposit, setSelectedOriginDeposit] = useState("");
    const [selectedDestinationDeposit, setSelectedDestinationDeposit] = useState("");
    const [date, setDate] = useState(new Date());
    const [itemSearch, setItemSearch] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [transferItems, setTransferItems] = useState([]);
    const containerRef = useRef(null);
    const inputRefs = useRef([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [debouncedItemSearch, setDebouncedItemSearch] = useState(itemSearch);

    // Efecto para obtener los depósitos al montar el componente
    useEffect(() => {
        fetchDeposits();
    }, []);

    // Efecto para manejar el debounce en la búsqueda de ítems
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedItemSearch(itemSearch);
        }, 500); 

        return () => {
            clearTimeout(handler);
        };
    }, [itemSearch]);

    // Función para obtener los depósitos desde la API
    async function fetchDeposits() {
        let pageQuery = 1;
        try {
            const response = await axiosAuth.get(`/deposits?page=${pageQuery}`);
            if (!response.data.error) {
                setDeposits(response.data.deposits.rows);
            } else {
                if (response.data.message === "token") {
                    await axiosAuth.post("/logout");
                    dispatch(setUser(null));
                    dispatch(setToken(null));
                }
            }
        } catch (error) {
            console.error("Error fetching deposits:", error);
        }
    }

    // Manejo de errores de token
    const handleTokenError = async (response) => {
        if (response.data.message === "token") {
            await axiosAuth.post("/logout");
            dispatch(setUser(null));
            dispatch(setToken(null));
        }
    };

    // Función para buscar ítems
    const searchItems = async (query) => {
        if (!selectedOriginDeposit) {
            alert("Por favor, selecciona un depósito de Origen antes de buscar ítems.");
            return;
        }

        try {
            const response = await axiosAuth.get(`/searchItems?search=${query}&page=1&stock=true`);
            if (!response.data.error) {
                setSearchResults(response.data.items.rows);
            } else {
                handleTokenError(response);
            }
            setHighlightedIndex(-1);
        } catch (error) {
            console.error("Error searching items:", error);
        }
    };

    // Función para obtener la existencia en el depósito de destino
    const getStockForDestination = (item) => {
        const destinationStock = item.stocks.find(stock => stock.depositId === parseInt(selectedDestinationDeposit));
        return destinationStock ? destinationStock.existence : 0;
    };

    // Función para agregar ítems a la transferencia
    const addItemToTransfer = (item) => {
        const exists = transferItems.find((transferItem) => transferItem.id === item.id);
        if (exists) {
            alert("Este ítem ya ha sido agregado.");
            return;
        }

        // Obtener la existencia en el depósito de origen
        const originStock = item.stocks.find(stock => stock.depositId === parseInt(selectedOriginDeposit));
        const stockExistence = originStock ? originStock.existence : 0;

        if (stockExistence < 1) {
            alert("La existencia en el depósito de origen es menor a 1 para este ítem. No se puede agregar.");
            return;
        }

        setTransferItems((prevItems) => [
            ...prevItems,
            {
                id: item.id,
                name: item.name,
                current_stock: stockExistence,
                transfer_quantity: "",
                stock_destination: getStockForDestination(item)
            },
        ]);

        setSearchResults([]);
        setItemSearch("");
        setHighlightedIndex(-1);
    };

    // Función para manejar las teclas en la búsqueda
    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
                Math.min(prevIndex + 1, searchResults.length - 1)
            );
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (e.key === "Enter" && highlightedIndex >= 0) {
            e.preventDefault();
            addItemToTransfer(searchResults[highlightedIndex]);
        }
    };

    // Función para actualizar un ítem en la transferencia
    const updateTransferItem = (id, field, value) => {
        setTransferItems(
            transferItems.map((item) => {
                if (item.id === id) {
                    let updatedItem = { ...item, [field]: value };

                    if (field === "transfer_quantity") {
                        const parsedValue = parseInt(value, 10);
                        if (!isNaN(parsedValue) && parsedValue <= item.current_stock && parsedValue >= 1) {
                            updatedItem[field] = parsedValue;
                        } else if (value === "") {
                            updatedItem[field] = "";
                        } else {
                            alert(`La cantidad a transferir debe ser un número entre 1 y ${item.current_stock}.`);
                            updatedItem[field] = item.transfer_quantity;
                        }
                    }

                    return updatedItem;
                }
                return item;
            })
        );
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    // Función para eliminar un ítem de la transferencia
    const removeTransferItem = (id) => {
        setTransferItems(transferItems.filter((item) => item.id !== id));
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    // Función para manejar la creación de la transferencia
    const handleCreateTransfer = async () => {
        // Eliminada la validación de descripción
        if (selectedOriginDeposit === "" || selectedDestinationDeposit === "") {
            alert("Por favor, selecciona los depósitos de origen y destino.");
            return;
        }

        if (selectedOriginDeposit === selectedDestinationDeposit) {
            alert("El depósito de origen y destino deben ser diferentes.");
            return;
        }

        if (transferItems.length === 0) {
            alert("Agrega al menos un ítem a transferir.");
            return;
        }

        // Validar que todas las cantidades de transferencia sean mayores que 0 y no excedan el stock
        for (const item of transferItems) {
            if (item.transfer_quantity === "" || item.transfer_quantity <= 0) {
                alert(`La cantidad a transferir para el ítem "${item.name}" debe ser mayor que 0.`);
                return;
            }
            if (item.transfer_quantity > item.current_stock) {
                alert(`La cantidad a transferir para el ítem "${item.name}" excede el stock disponible.`);
                return;
            }
        }

        const payload = {
            // Eliminado la descripción del payload
            origin_deposit_id: selectedOriginDeposit,
            destination_deposit_id: selectedDestinationDeposit,
            transfer_date: date.toISOString().split('T')[0],
            transfer_details: transferItems.map((item) => ({
                item_id: item.id,
                transfer_quantity: item.transfer_quantity,
            })),
        };
        console.log( payload);

        try {
            const response = await axiosAuth.post("/stock-transfers", payload);
            if (!response.data.error) {
                alert("Transferencia creada exitosamente.");
                clearCreateFields();
            } else {
                handleTokenError(response);
                alert(response.data.message || "Error al crear la transferencia.");
            }
        } catch (error) {
            console.error("Error creating transfer:", error);
            alert("Error al crear la transferencia. Por favor, intenta de nuevo.");
        }
    };

    // Función para limpiar los campos después de crear una transferencia
    const clearCreateFields = () => {
        // Eliminada la limpieza de descripción
        // setDescription("");
        setSelectedOriginDeposit("");
        setSelectedDestinationDeposit("");
        setTransferItems([]);
        setDate(new Date());
    };

    // Efecto para buscar ítems cuando el término de búsqueda cambia
    useEffect(() => {
        if (debouncedItemSearch.trim() !== "") {
            searchItems(debouncedItemSearch);
        } else {
            setSearchResults([]);
        }
    }, [debouncedItemSearch, selectedOriginDeposit]);

    // Efecto para actualizar las existencias en los ítems cuando se cambia el depósito de origen o destino
    useEffect(() => {
        setTransferItems((prevItems) =>
            prevItems.map((item) => {
                // Obtener la existencia en origen
                const originStock = item.stocks ? item.stocks.find(stock => stock.depositId === parseInt(selectedOriginDeposit)) : null;
                const updatedOriginStock = originStock ? originStock.existence : 0;

                // Obtener la existencia en destino
                const destinationStock = item.stocks ? item.stocks.find(stock => stock.depositId === parseInt(selectedDestinationDeposit)) : null;
                const updatedDestinationStock = destinationStock ? destinationStock.existence : 0;

                return {
                    ...item,
                    current_stock: updatedOriginStock,
                    stock_destination: updatedDestinationStock
                };
            })
        );
    }, [selectedOriginDeposit, selectedDestinationDeposit]);

    // Función para manejar cambios en el depósito de origen
    const handleOriginDepositChange = (e) => {
        const selectedDepositId = e.target.value;
        setSelectedOriginDeposit(selectedDepositId);

        // Si el depósito seleccionado en origen es el mismo que el de destino, restablecer destino
        if (selectedDepositId && selectedDepositId === selectedDestinationDeposit) {
            alert("El depósito de origen seleccionado ya está seleccionado como destino. Se restablecerá el depósito de destino.");
            setSelectedDestinationDeposit("");
        }
    };

    // Función para manejar cambios en el depósito de destino
    const handleDestinationDepositChange = (e) => {
        const selectedDepositId = e.target.value;
        setSelectedDestinationDeposit(selectedDepositId);

        // Si el depósito seleccionado en destino es el mismo que el de origen, restablecer origen
        if (selectedDepositId && selectedDepositId === selectedOriginDeposit) {
            alert("El depósito de destino seleccionado ya está seleccionado como origen. Se restablecerá el depósito de origen.");
            setSelectedOriginDeposit("");
        }
    };

    return (
        <Layout>
            <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
                <h1 className="text-2xl font-semibold mb-4">Transferencias de Stock</h1>

                <div className="space-y-4">
                    {/* Eliminado el campo de descripción */}

                    {/* Selección de Depósito de Origen */}
                    <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-3 border-t border-gray-300" style={{ margin: "4px 0" }}>
                        <label className="text-gray-700 text-xl font-bold w-full sm:w-[5%] text-left !text-xl" htmlFor="originDeposit">
                            Origen
                        </label>
                        <span className="text-gray-700 text-lg font-bold text-left !text-xl hidden sm:block">:</span>

                        <select
                            style={{ width: "100%" }}
                            id="originDeposit"
                            value={selectedOriginDeposit}
                            onChange={handleOriginDepositChange}
                            className="shadow appearance-none border rounded w-full sm:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                        >
                            <option value="">Selecciona un depósito de Origen</option>
                            {deposits
                                .filter(deposit => deposit.id !== selectedDestinationDeposit)
                                .map((deposit) => (
                                    <option key={deposit.id} value={deposit.id}>
                                        {deposit.description}
                                    </option>
                            ))}
                        </select>
                    </div>

                    {/* Selección de Depósito de Destino */}
                    <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-3 border-t border-gray-300" style={{ margin: "4px 0" }}>
                        <label className="text-gray-700 text-xl font-bold w-full sm:w-[5%] text-left !text-xl" htmlFor="destinationDeposit">
                            Destino
                        </label>
                        <span className="text-gray-700 text-lg font-bold text-left !text-xl hidden sm:block">:</span>

                        <select
                            style={{ width: "100%" }}
                            id="destinationDeposit"
                            value={selectedDestinationDeposit}
                            onChange={handleDestinationDepositChange}
                            className="shadow appearance-none border rounded w-full sm:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                        >
                            <option value="">Selecciona un depósito de Destino</option>
                            {deposits
                                .filter(deposit => deposit.id !== selectedOriginDeposit)
                                .map((deposit) => (
                                    <option key={deposit.id} value={deposit.id}>
                                        {deposit.description}
                                    </option>
                            ))}
                        </select>
                    </div>

                    {/* Separador */}
                    <div className="border border-gray-600" style={{ width: "100%", height: "1.5px", background: "#4b556370", margin: "8px 0px" }}></div>

                    {/* Buscador de Ítems */}
                    <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-3 relative" style={{ margin: "0" }}>
                        <label className="text-gray-700 text-xl font-bold w-full sm:w-[5%] text-left !text-xl" htmlFor="itemSearch">
                            Buscar
                        </label>
                        <span className="text-gray-700 text-lg font-bold text-left !text-xl hidden sm:block">:</span>
                        <input
                            style={{ width: "100%" }}
                            type="text"
                            id="itemSearch"
                            value={itemSearch}
                            onChange={(e) => setItemSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="shadow appearance-none border rounded w-full sm:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                            placeholder="Escribe para buscar ítems"
                        />
                        {searchResults.length > 0 && (
                            <ul className="absolute z-10 border border-gray-300 rounded mt-1 max-h-48 overflow-y-auto bg-white shadow-lg top-16 left-1/2 transform -translate-x-1/2 w-full sm:w-3/4">
                                {searchResults.map((item, index) => (
                                    <li
                                        key={item.id}
                                        onClick={() => addItemToTransfer(item)}
                                        className={`px-4 py-2 hover:bg-gray-200 cursor-pointer transition duration-200 ${index === highlightedIndex ? 'bg-gray-100' : ''}`}
                                    >
                                        {item.name} - Stock Origen: {selectedOriginDeposit ? (item.stocks.find(stock => stock.depositId === parseInt(selectedOriginDeposit))?.existence || 0) : 0}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                {/* Tabla de Ítems a Transferir */}
                <div className="mt-4">
                    <div className="overflow-x-auto">
                        <div className="overflow-y-auto" ref={containerRef} style={{
                            height: "308px",
                            borderLeft: "1px solid #4b5563",
                            borderRight: "1px solid #4b5563",
                            borderBottom: "1px solid #4b5563",
                        }}>
                            <table className="min-w-full table-auto table-layout-fixed text-lg">
                                <thead className="bg-gray-600 text-white">
                                    <tr>
                                        <th className="px-4 py-2 !text-white text-left w-[30%]">Artículos</th>
                                        <th className="px-4 py-2 !text-white text-center w-[15%]">Stock Origen</th>
                                        <th className="px-4 py-2 !text-white text-center w-[15%]">Cantidad a Transferir</th>
                                        <th className="px-4 py-2 !text-white text-center w-[15%]">Stock Destino</th>
                                        <th className="px-4 py-2 !text-white text-center w-[20%]">Stock Resultante</th>
                                        <th className="px-4 py-2 !text-white text-left w-[5%]">Borrar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transferItems.map((item, index) => {
                                        const transferQuantity = parseInt(item.transfer_quantity, 10) || 0;
                                        const newStockDestination = (item.stock_destination || 0) + transferQuantity;
                                        return (
                                            <tr key={item.id} className="bg-white hover:bg-gray-50 transition duration-200">
                                                <td className="border-t border-gray-300 px-4 w-[30%] text-left">
                                                    {item.name}
                                                </td>
                                                <td className="border-t border-gray-300 px-4 w-[15%] text-right text-xl">
                                                    {item.current_stock}
                                                </td>
                                                <td className="border-t border-gray-300 px-4 w-[15%] text-right !text-xl">
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        max={item.current_stock}
                                                        value={item.transfer_quantity}
                                                        onChange={(e) =>
                                                            updateTransferItem(
                                                                item.id,
                                                                "transfer_quantity",
                                                                e.target.value
                                                            )
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                e.preventDefault();
                                                                document.getElementById("itemSearch").focus();
                                                            }
                                                        }}
                                                        className="shadow appearance-none px-2 border-2 border-blue-400 w-[90%] text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-400 text-right text-lg"
                                                        placeholder="Cantidad"
                                                    />
                                                </td>
                                                <td className="border-t border-gray-300 px-4 w-[15%] text-right text-xl">
                                                    {item.stock_destination}
                                                </td>
                                                <td className="border-t border-gray-300 px-4 w-[20%] text-right text-xl">
                                                    {newStockDestination}
                                                </td>
                                                <td className="border-t border-gray-300 px-4 w-[5%]">
                                                    <button
                                                        onClick={() => removeTransferItem(item.id)}
                                                        className="text-red-500"
                                                        title="Eliminar Ítem"
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {/* Añadir filas vacías si hay menos de 11 elementos */}
                                    {transferItems.length < 11 &&
                                        Array.from({ length: 11 - transferItems.length }).map(
                                            (_, index) => (
                                                <tr key={`empty-${index}`} className="bg-white">
                                                    <td className="border-t border-gray-300 px-4 w-[30%] text-left">
                                                        &nbsp;
                                                    </td>
                                                    <td className="border-t border-gray-300 px-4 w-[15%] text-center">
                                                        &nbsp;
                                                    </td>
                                                    <td className="border-t border-gray-300 px-4 w-[15%] text-center">
                                                        &nbsp;
                                                    </td>
                                                    <td className="border-t border-gray-300 px-4 w-[15%] text-center">
                                                        &nbsp;
                                                    </td>
                                                    <td className="border-t border-gray-300 px-4 w-[20%] text-center">
                                                        &nbsp;
                                                    </td>
                                                    <td className="border-t border-gray-300 px-4 w-[5%] text-center">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* Botones de Acción */}
                <div className="flex justify-end space-x-2 mt-2">
                    <button
                        type="button"
                        className="relative inline-flex w-[68px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400"
                        onClick={handleCreateTransfer}
                    >
                        <img src={floppy} className="w-8" alt="Guardar" />
                        GUARDAR
                    </button>
                    <button
                        className="relative inline-flex w-[68px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400"
                        onClick={() => { clearCreateFields(); navigate('/'); }}
                    >
                        <img className="w-6" src={door} alt="Salir" />
                        SALIR
                    </button>
                </div>
            </div>
        </Layout>
    );
};

export default StockTransfers;
