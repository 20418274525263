import React, { useState, Fragment, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye, faPlus } from "@fortawesome/free-solid-svg-icons"; // Import faPlus to add items
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useParams } from "react-router-dom";
import Pagination from "../components/pagination";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";
import door from "../assets/img/door.png"; // Icon to close
import floppy from "../assets/img/floppy.png"; // Icon to save
import stockimg from "../assets/img/stock.webp";

const Adjustments = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [action, setAction] = useState("view"); // Change to 'view' for details

    const { page, search } = useParams();
    const user = useSelector((state) => state.main.user);

    const [adjustments, setAdjustments] = useState([]);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState(0);

    const [adjustmentId, setAdjustmentId] = useState(0);
    const [name, setName] = useState("");
    const [date, setDate] = useState(new Date());
    const [searchText, setSearchText] = useState(search || "");

    const [details, setDetails] = useState(null); // State for details

    const [isCreating, setIsCreating] = useState(false); // State to open creation modal
    const [description, setDescription] = useState(""); // Group description
    const [deposits, setDeposits] = useState([]); // List of deposits for combobox
    const [selectedDeposit, setSelectedDeposit] = useState(""); // Selected deposit
    const [itemSearch, setItemSearch] = useState(""); // Item search text
    const [searchResults, setSearchResults] = useState([]); // Item search results
    const [adjustmentItems, setAdjustmentItems] = useState([]); // List of items added to adjustment
    const containerRef = useRef(null); // Referencia para el contenedor
    const inputRefs = useRef([]); // Referencia para el contenedor
    const [highlightedIndex, setHighlightedIndex] = useState(-1)
    // Función que actualiza la lista de elementos (debes ajustarla según tu lógica)

    const clearFields = () => {
        setAdjustmentId(0);
        setName("");
        setDate(new Date());
        setDetails(null);
    };

    // Fetch deposits
    async function fetchDeposits() {
       
        let pageQuery = page === undefined ? 1 : page;
        try {
            const response = await axiosAuth.get(`/deposits?page=${pageQuery}`);
            if (!response.data.error) {
                console.log(response);
                setDeposits(response.data.deposits.rows);
            } else {
                if (response.data.message === "token") {
                    await axiosAuth.post("/logout");
                    dispatch(setUser(null));
                    dispatch(setToken(null));
                }
            }
        } catch (error) {
            console.error("Error fetching deposits:", error);
        }
    }



    // Fetch adjustments
    async function fetchAdjustments() {
        let pageQuery = page === undefined ? 1 : page;

        setIsFetching(true);

        try {
            const response = await axiosAuth.get(`/adjustment-groups?page=${pageQuery}&search=${searchText}`);
            setIsFetching(false);

            if (response.data.error) {
                if (response.data.message === "token") {
                    await axiosAuth.post("/logout");
                    dispatch(setUser(null));
                    dispatch(setToken(null));
                }
                return;
            }

            const groups = response.data.groups;

            const adjustments = groups.map(group => ({
                id: group.id,
                name: group.name.trim(),
                date: group.adjustment_date,
                deposit: group.deposit, // Keep deposit for details and table
                adjustmentdetails: group.adjustmentdetails, // Keep details to display
            }));

            setAdjustments(adjustments);

            if (response.data.count !== undefined && response.data.pages !== undefined) {
                setTotal(response.data.count);
                setPages(response.data.pages);
            } else {
                setTotal(groups.length);
                setPages(1);
            }

        } catch (error) {
            console.error("Error fetching adjustments:", error);
            setIsFetching(false);
            // Optional: Set an error state here
        }
    }

    // Search items
    const searchItems = async (query) => {
        try {
            let currentPage = 1;
            const response = await axiosAuth.get(`/searchItems?search=${query}&page=${currentPage}&stock=${true}`);
            if (!response.data.error) {
                console.log(response);
                setSearchResults(response.data.items.rows);
            } else {
                if (response.data.message === "token") {
                    await axiosAuth.post("/logout");
                    dispatch(setUser(null));
                    dispatch(setToken(null));
                }
            }
            setHighlightedIndex(-1);
        } catch (error) {
            console.error("Error searching items:", error);
        }
    };

    // Add items to adjustment
    const addItemToAdjustment = (item) => {
        const exists = adjustmentItems.find((adjustItem) => adjustItem.id === item.id);
        if (exists) {
            alert("Este ítem ya ha sido agregado.");
            return;
        }

        // Obtiene la existencia del stock, si está disponible
        const stockExistence = item.stocks && item.stocks.length > 0 ? item.stocks[0].existence : 0;

        // Agrega el ítem con los campos necesarios
        setAdjustmentItems((prevItems) => [
            ...prevItems,
            {
                id: item.id,
                name: item.name,
                current_stock: stockExistence,
                adjustment_quantity: 0,
                target_quantity: 0,
            },
        ]);


        // Clear search results
        setSearchResults([]);
        setItemSearch("");
        setTimeout(() => {
            const newIndex = adjustmentItems.length;
            if (inputRefs.current[newIndex]) {
                inputRefs.current[newIndex].focus();
            }
        }, 0);
        setHighlightedIndex(-1);
    };
    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
                Math.min(prevIndex + 1, searchResults.length - 1)
            );
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        } else if (e.key === "Enter" && highlightedIndex   
 >= 0) {
            e.preventDefault();
            addItemToAdjustment(searchResults[highlightedIndex]);
        }
    };

    // Update adjustment item fields
    const updateAdjustmentItem = (id, field, value) => {
        setAdjustmentItems(
            adjustmentItems.map((item) => {
                if (item.id === id) {
                    let updatedItem = { ...item, [field]: value };

                    // If target_quantity is updated, recalculate adjustment_quantity
                    if (field === "target_quantity") {
                        // Reemplazar comas por puntos
                        value = value.replace(",", ".");

                        // Si el valor termina con un punto, no intentar convertirlo aún
                        if (value.endsWith(".")) {
                            updatedItem[field] = value; // Asignar el valor con el punto
                        } else {
                            const parsedValue = parseFloat(value);
                            if (!isNaN(parsedValue)) {
                                updatedItem[field] = parsedValue;
                                updatedItem.adjustment_quantity = parsedValue - item.current_stock;
                            } else {
                                updatedItem[field] = 0;
                                updatedItem.adjustment_quantity = 0 - item.current_stock;
                            }
                        }
                    }

                    return updatedItem;
                }
                return item;
            })
        );
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    // Remove items from adjustment
    const removeAdjustmentItem = (id) => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
        setAdjustmentItems(adjustmentItems.filter((item) => item.id !== id));
    };

    // Create adjustments
    const handleCreateAdjustment = async () => {
        if (description.trim() === "" || selectedDeposit === "") {
            alert("Por favor, completa la descripción y selecciona un depósito.");
            return;
        }

        if (adjustmentItems.length === 0) {
            alert("Agrega al menos un ítem al ajuste.");
            return;
        }

        // Prepare data to send
        const payload = {
            name: description,
            deposit_id: selectedDeposit,
            adjustment_date: date.toISOString().split('T')[0], // Format YYYY-MM-DD
            adjustmentdetails: adjustmentItems.map((item) => ({
                item_id: item.id,
                current_stock: item.current_stock, // Add current_stock to payload
                adjustment_quantity: item.adjustment_quantity,
                target_quantity: item.target_quantity,
            })),
        };

        try {
            const response = await axiosAuth.post("/adjustment-groups", payload);
            if (!response.data.error) {
                alert("Ajuste creado exitosamente.");
                // Update adjustments list
                fetchAdjustments();
                // Close modal and clear fields
                setOpen(false);
                clearCreateFields();
            } else {
                if (response.data.message === "token") {
                    await axiosAuth.post("/logout");
                    dispatch(setUser(null));
                    dispatch(setToken(null));
                } else {
                    alert(response.data.message || "Error al crear el ajuste.");
                }
            }
        } catch (error) {
            console.error("Error creating adjustment:", error);
            alert("Error al crear el ajuste. Por favor, intenta de nuevo.");
        }
    };

    // Clear creation form fields
    const clearCreateFields = () => {
        setDescription("");
        setSelectedDeposit("");
        setAdjustmentItems([]);
        setDate(new Date());
    };

    // Delete adjustments
    const handleDelete = async (id) => {
        if (window.confirm("¿Desea eliminar este ajuste?")) {
            const deleteAdjustmentRequest = await axiosAuth.delete("/adjustments", { params: { id } });

            if (deleteAdjustmentRequest.data.error) {
                if (deleteAdjustmentRequest.data.message === "token") {
                    await axiosAuth.post("/logout");
                    dispatch(setUser(null));
                    dispatch(setToken(null));
                }
            }

            const updatedAdjustments = adjustments.filter((adj) => adj.id !== id);
            setAdjustments(updatedAdjustments);
        }
    };

    // Search adjustments
    const searchAdjustments = (searchTxt) => {
        setSearchText(searchTxt);
    };

    // Debounce implementation for item search
    const [debouncedItemSearch, setDebouncedItemSearch] = useState(itemSearch);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedItemSearch(itemSearch);
        }, 500); // 500ms delay

        return () => {
            clearTimeout(handler);
        };
    }, [itemSearch]);
    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }

    }, [adjustmentItems]);
    useEffect(() => {
        if (debouncedItemSearch.trim() !== "") {
            searchItems(debouncedItemSearch);
        } else {
            setSearchResults([]);
        }
    }, [debouncedItemSearch]);

    useEffect(() => {
        fetchDeposits();
    }, []);

    useEffect(() => {
        fetchAdjustments();
    }, [location, page, searchText]);

    // View details
    const viewDetails = (adjustment) => {
        setAction("view");
        setAdjustmentId(adjustment.id);
        setName(adjustment.name);
        setDate(new Date(adjustment.date));
        setDetails(adjustment);
        setOpen(true);
    };

    // Open creation modal
    const openCreateModal = () => {
        setAction("create");
        setIsCreating(true);
        setOpen(true);
    };

    return (
        <>
            <Layout>
                {/* Modal for viewing details and creating adjustments */}
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => { clearFields(); setOpen(false); }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 modal-bg-color bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto flex justify-center items-center p-4">
                            <div className="flex justify-center p-6 text-center sm:p-0 w-full" style={{ position: "absolute", top: "0" }}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-lg transition-all sm:my-8 w-full sm:w-[900px] max-w-full pb-6 rounded-2xl">
                                        <div className="bg-white px-6 pb-6 pt-5 sm:p-8 sm:pb-0">
                                            <div className="sm:flex sm:items-start w-full">
                                                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                                    <Dialog.Title as="h1" className="text-center font-semibold leading-6 text-primary text-2xl">
                                                        {action === "view" ? "Detalles del Ajuste" : "AJUSTES DE STOCK"}
                                                    </Dialog.Title>
                                                    <div className="border border-gray-600" style={{
                                                        width: "100%",
                                                        height: "1px",
                                                        background: "#4b55635c",
                                                        margin: "6px 0px"
                                                    }}></div>
                                                    <div>
                                                        {action === "view" && details ? (
                                                            <div>
                                                                <div className="space-y-2">
                                                                    <p className="text-lg"><strong>ID:</strong> {details.id}</p>
                                                                    <p className="text-lg"><strong>Nombre:</strong> {details.name}</p>
                                                                    <p className="text-lg"><strong>Fecha:</strong> {new Date(details.date).toLocaleDateString()}</p>
                                                                    <p className="text-lg"><strong>Depósito:</strong> {details.deposit.description}</p>
                                                                </div>
                                                                <h2 className="mt-6 text-xl font-semibold">Detalles del Ajuste:</h2>
                                                                <div className="overflow-x-auto mt-4">
                                                                    <table className="min-w-full border-collapse border border-gray-300">
                                                                        <thead>
                                                                            <tr className="bg-gray-100">
                                                                                <th className="border border-gray-300 px-4 py-2 text-left">Item</th>
                                                                                <th className="border border-gray-300 px-4 py-2 text-left">Stock Actual</th>
                                                                                <th className="border border-gray-300 px-4 py-2 text-left">Cantidad Ajustada</th>
                                                                                <th className="border border-gray-300 px-4 py-2 text-left">Cantidad Objetivo</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {details.adjustmentdetails.map(detail => (
                                                                                <tr key={detail.id} className="bg-white hover:bg-gray-50">
                                                                                    <td className="border border-gray-300 px-4 py-2">{detail.item.name.trim()}</td>
                                                                                    <td className="border border-gray-300 px-4 py-2">{detail.current_stock}</td>
                                                                                    <td className="border border-gray-300 px-4 py-2">{detail.adjustment_quantity}</td>
                                                                                    <td className="border border-gray-300 px-4 py-2">{detail.target_quantity}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        ) : action === "create" ? (
                                                            <div>
                                                                <div className="space-y-4">
                                                                    <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-3">
                                                                        <label className="text-gray-700 text-lg font-bold w-full sm:w-1/6 text-left !text-xl" style={{ width: "10%" }} htmlFor="description">
                                                                            Nombre
                                                                        </label>
                                                                        <span className="text-gray-700 text-lg font-bold text-left !text-xl">:</span>
                                                                        <input
                                                                            style={{ width: "100%" }}
                                                                            type="text"
                                                                            id="description"
                                                                            value={description}
                                                                            onChange={(e) => setDescription(e.target.value)}
                                                                            className="shadow appearance-none border rounded w-full sm:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                                                                            placeholder="Descripción del ajuste"
                                                                        />
                                                                    </div>
                                                                    <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-3 border-t border-gray-300" style={{
                                                                        margin: "4px 0",
                                                                    }}>
                                                                        <label style={{ width: "10%" }} className="text-gray-700 text-xl font-bold w-full sm:w-1/6 text-left !text-xl" htmlFor="deposit">
                                                                            Depósito
                                                                        </label>
                                                                        <span className="text-gray-700 text-lg font-bold text-left !text-xl">:</span>
                                                                        <select
                                                                            style={{ width: "100%" }}
                                                                            id="deposit"
                                                                            value={selectedDeposit}
                                                                            onChange={(e) => setSelectedDeposit(e.target.value)}
                                                                            className="shadow appearance-none border rounded w-full sm:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                                                                        >
                                                                            <option value="">Selecciona un depósito</option>
                                                                            {deposits.map((deposit) => (
                                                                                <option key={deposit.id} value={deposit.id}>
                                                                                    {deposit.description}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="border border-gray-600" style={{
                                                                        width: "100%",
                                                                        height: "1.5px",
                                                                        background: "#4b556370",
                                                                        margin: "8px 0px"
                                                                    }}></div>
                                                                    <div className="flex flex-col sm:flex-row items-center sm:items-start space-y-2 sm:space-y-0 sm:space-x-3 relative " style={{ margin: "0" }} >
                                                                        <label className="text-gray-700 text-xl font-bold w-full sm:w-1/6 text-left !text-xl" style={{ width: "10%" }} htmlFor="itemSearch">
                                                                            Buscar
                                                                        </label>
                                                                        <span className="text-gray-700 text-lg font-bold text-left !text-xl">:</span>
                                                                        <input
                                                                            style={{ width: "100%" }}
                                                                            type="text"
                                                                            id="itemSearch"
                                                                            value={itemSearch}
                                                                            onChange={(e) => setItemSearch(e.target.value)}
                                                                            onKeyDown={handleKeyDown} 
                                                                            className="shadow appearance-none border rounded w-full sm:w-3/4 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                                                                            placeholder="Escribe para buscar ítems"
                                                                        />
                                                                        {searchResults.length > 0 && (
                                                                            <ul className="absolute z-10 border border-gray-300 rounded mt-1 max-h-48 overflow-y-auto bg-white shadow-lg top-16 left-1/2 transform -translate-x-1/2 w-full sm:w-3/4">
                                                                                {searchResults.map((item) => (
                                                                                    <li
                                                                                        key={item.id}
                                                                                        onClick={() => addItemToAdjustment(item)}
                                                                                        className="px-4 py-2 hover:bg-gray-200 cursor-pointer transition duration-200"
                                                                                    >
                                                                                        {item.name} - Stock: {item.stocks.length > 0 ? item.stocks[0].existence : 0}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2">
                                                                    <div className="overflow-x-auto">
                                                                        {/* Tabla principal con cabecera */}
                                                                        <div className="overflow-y-auto" ref={containerRef} style={{
                                                                            maxHeight: "300px",
                                                                            borderLeft: "1px solid #4b5563",
                                                                            borderRight: "1px solid #4b5563",
                                                                            borderBottom: "1px solid #4b5563",
                                                                        }}>
                                                                            <table
                                                                                className="min-w-full table-auto table-layout-fixed text-lg"

                                                                            >
                                                                                <thead className="bg-gray-600 text-white">
                                                                                    <tr>
                                                                                        {/* Definimos el mismo porcentaje de ancho para las columnas */}
                                                                                        <th className="px-4 py-2 !text-white text-left w-[50%]">Artículos</th>
                                                                                        <th className="px-4 py-2 !text-white text-center w-[12%]">Stock</th>
                                                                                        <th className="px-4 py-2 !text-white text-center w-[12%]">Ajuste</th>
                                                                                        <th className="px-4 py-2 !text-white text-center w-[18%]">Stock final</th>
                                                                                        <th className="px-4 py-2 !text-white text-left w-[8%]">Borrar</th>
                                                                                    </tr>
                                                                                </thead>
                                                                            </table>
                                                                        </div>

                                                                        {/* Contenedor con alto fijo y scroll vertical */}
                                                                        <div className="overflow-y-auto mb-4" ref={containerRef} style={{
                                                                            maxHeight: "300px",
                                                                            borderLeft: "1px solid #8080808a",
                                                                            borderRight: "1px solid #8080808a",
                                                                            borderBottom: "1px solid #8080808a",
                                                                        }}>
                                                                            <table
                                                                                className="min-w-full table-auto table-layout-fixed text-lg "

                                                                            >
                                                                                <tbody>
                                                                                    {adjustmentItems.length > 0 ? (
                                                                                        <>
                                                                                            {adjustmentItems.map((item, index) => (
                                                                                                <tr
                                                                                                    key={item.id}
                                                                                                    className="bg-white hover:bg-gray-50 transition duration-200"
                                                                                                >
                                                                                                    <td className="border-t border-gray-300 px-4 w-[50%] text-left">
                                                                                                        {item.name}
                                                                                                    </td>
                                                                                                    <td className="border-t border-gray-300 px-4 w-[12%] text-right text-xl">
                                                                                                        {item.current_stock}
                                                                                                    </td>
                                                                                                    <td className="border-t border-gray-300 px-4 w-[12%] text-right !text-xl">
                                                                                                        <span
                                                                                                            className={item.adjustment_quantity > 0
                                                                                                                ? "text-green-500 !text-xl"
                                                                                                                : item.adjustment_quantity < 0
                                                                                                                    ? "text-red-500 !text-xl"
                                                                                                                    : "text-black !text-xl"
                                                                                                            }
                                                                                                        >
                                                                                                            {item.adjustment_quantity}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td className="border-t border-gray-300 px-4 w-[18%] text-right">
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            ref={(el) => (inputRefs.current[index] = el)}
                                                                                                            value={item.target_quantity}
                                                                                                            onChange={(e) =>
                                                                                                                updateAdjustmentItem(
                                                                                                                    item.id,
                                                                                                                    "target_quantity",
                                                                                                                    e.target.value
                                                                                                                )
                                                                                                            }
                                                                                                            onKeyDown={(e) => {
                                                                                                                if (e.key === "Enter") {
                                                                                                                    e.preventDefault();
                                                                                                                    document.getElementById("itemSearch").focus();
                                                                                                                }
                                                                                                            }}
                                                                                                            className="shadow appearance-none px-2 border-2 border-blue-400  w-[60%] text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-400 text-right text-lg"
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td className="border-t border-gray-300 px-4 w-[8%]">
                                                                                                        <button
                                                                                                            onClick={() => removeAdjustmentItem(item.id)}
                                                                                                            className="text-red-500"
                                                                                                            title="Eliminar Ítem"
                                                                                                        >
                                                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                            {/* Añadir filas vacías si hay menos de 8 elementos */}
                                                                                            {adjustmentItems.length < 13 &&
                                                                                                Array.from({ length: 13 - adjustmentItems.length }).map(
                                                                                                    (_, index) => (
                                                                                                        <tr key={`empty-${index}`} className="bg-white">
                                                                                                            <td className="border-t border-gray-300 px-4 w-[50%] text-left">
                                                                                                                &nbsp;
                                                                                                            </td>
                                                                                                            <td className="border-t border-gray-300 px-4 w-[12%] text-center">
                                                                                                                &nbsp;
                                                                                                            </td>
                                                                                                            <td className="border-t border-gray-300 px-4 w-[12%] text-center">
                                                                                                                &nbsp;
                                                                                                            </td>
                                                                                                            <td className="border-t border-gray-300 px-4 w-[18%]">
                                                                                                                &nbsp;
                                                                                                            </td>
                                                                                                            <td className="border-t border-gray-300 px-4 w-[8%]">
                                                                                                                &nbsp;
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <tr>
                                                                                                <td
                                                                                                    colSpan="5"
                                                                                                    className="border-t border-gray-300 px-4  text-center"
                                                                                                >
                                                                                                    No hay ítems agregados.
                                                                                                </td>
                                                                                            </tr>

                                                                                            {/* Añadir filas vacías si no hay ítems */}
                                                                                            {Array.from({ length: 7 }).map((_, index) => (
                                                                                                <tr key={`empty-${index}`} className="bg-white">
                                                                                                    <td className="border-t border-gray-300 px-4 w-[50%] text-left">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="border-t border-gray-300 px-4 w-[12%] text-center">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="border-t border-gray-300 px-4 w-[12%] text-center">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="border-t border-gray-300 px-4 w-[18%]">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                    <td className="border-t border-gray-300 px-4 w-[8%]">
                                                                                                        &nbsp;
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </>
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        ) : (
                                                            <p className="text-lg text-gray-500">No hay detalles para mostrar.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col sm:flex-row justify-center sm:justify-end space-y-4 sm:space-y-0 sm:space-x-4 ">
                                                {action === "view" ? (
                                                    <button
                                                        className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-medium py-2 px-5 rounded-lg text-lg"
                                                        onClick={() => { clearFields(); setOpen(false); }}
                                                    >
                                                        Cerrar
                                                    </button>
                                                ) : (
                                                    <>
                                                        <div className="flex justify-center space-x-2">


                                                            <button
                                                                type="button"
                                                                className="relative inline-flex w-[68px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400"
                                                                onClick={handleCreateAdjustment}
                                                            >
                                                                <img src={floppy} className="w-8" />
                                                                GUARDAR
                                                            </button>
                                                            <button
                                                                className="relative inline-flex w-[68px] flex-col justify-center items-center rounded ternary-bg-color px-3 py-2 text-xs text-gray-900 shadow-sm ring-1 rng-inset ring-gray-300 hover:bg-gray-400"
                                                                onClick={() => { clearCreateFields(); setOpen(false); }}
                                                            >
                                                                <img className="w-6" src={door} alt="Cerrar" />
                                                                CANCELAR
                                                            </button>
                                                        </div>

                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md">
                    <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-4 sm:space-y-0">
                        <h1 className="text-2xl font-semibold">Ajustes de Stock</h1>

                       {/*  <button
                            type="button"
                            className="flex flex-col items-center justify-center bg-ternary-bg-color hover:bg-gray-400 text-gray-900 shadow-sm ring-1 ring-gray-300 rounded-lg px-3 py-2 text-sm w-full sm:w-auto"
                            onClick={openCreateModal}
                        >
                            <img src={stockimg} className="w-16 mb-2 sm:mb-0" alt="Ajustar Stock" />
                            <span className="text-center">AJUSTAR STOCK</span>
                        </button> */}
                    </div>

                    <div className="mb-4">
                        <input
                            type="text"
                            value={searchText}
                            onChange={(e) => searchAdjustments(e.target.value)}
                            className="w-full sm:w-1/2 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-lg"
                            placeholder="Buscar ajustes..."
                        />
                    </div>

                    <div className="overflow-x-auto">
                        <table className="min-w-full table-auto text-sm sm:text-base">
                            <thead className="bg-gray-600 text-white">
                                <tr>
                                    <th className="w-1/12 !text-white px-4 py-2 uppercase">Id</th>
                                    <th className="w-5/12 !text-white px-4 py-2 uppercase text-left">Nombre</th>
                                    <th className="w-3/12 !text-white px-4 py-2 uppercase text-left">Depósito</th>
                                    <th className="w-2/12 !text-white px-4 py-2 uppercase">Fecha</th>
                                    <th className="w-2/12 !text-white px-4 py-2 uppercase">Ver</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isFetching && (
                                    <tr>
                                        <td colSpan="5" className="py-8">
                                            <Lottie
                                                animationData={loadingAnimation}
                                                style={{ width: 100, height: 100, margin: "0 auto" }}
                                                loop={true}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {!isFetching && adjustments.length === 0 && (
                                    <tr>
                                        <td colSpan="5" className="text-center text-gray-500 py-4">No se encontraron ajustes.</td>
                                    </tr>
                                )}
                                {adjustments.map((adjustment) => (
                                    <tr key={adjustment.id} className="border-b border-gray-200 hover:bg-gray-50">
                                        <td className="px-4 py-2">{adjustment.id}</td>
                                        <td className="px-4 py-2 text-left">{adjustment.name}</td>
                                        <td className="px-4 py-2 text-left">{adjustment.deposit ? adjustment.deposit.description : "Sin Depósito"}</td> {/* Show Deposit */}
                                        <td className="px-4 py-2">{new Date(adjustment.date).toLocaleDateString()}</td>
                                        <td className="px-4 py-2 flex justify-center space-x-2">
                                            <button
                                                onClick={() => viewDetails(adjustment)}
                                                className="text-green-500 hover:text-green-700"
                                                title="Ver Detalles"
                                            >
                                                <FontAwesomeIcon icon={faEye} size="lg" />
                                            </button>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <Pagination pages={pages} total={total} />
                </div>
            </Layout>
        </>
    );
};

export default Adjustments;
