import React, { useState, useRef, useEffect } from "react";
import Layout from "../layout";
import { axiosAuth } from "../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../store/slices/mainSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateComponent from "../components/date";
import Lottie from "lottie-react";
import loadingAnimation from "../assets/animations/loading.json";

import { DownloadTableExcel } from "react-export-table-to-excel";
import xls from "../assets/img/xls.png";
import search from "../assets/img/search.webp";
import door from "../assets/img/door.png";
import pdf from "../assets/img/pdf.png";
import printer from "../assets/img/printer.png";
import { numberWithCommas } from "../utils/constants";

const CollectionsReport = () => {
  const tableRef = useRef(null);

  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [reporType, setReporType] = useState(0);
  const [salesTotal, setSalesTotal] = useState(0);
  const [collectionsTotal, setCollectionsTotal] = useState(0);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [salesReport, setSalesReport] = useState(true);
  const [salesReportByItem, setSalesReportByItem] = useState(false);
  const [bookOfSalesReport, setBookOfSalesReport] = useState(false);
  const [type, seType] = useState(state != undefined ? state.type : 0);

  const user = useSelector((state) => state.main.user);

  const [sales, setSales] = useState([]);
  const [collections, setCollections] = useState([]);

  const [dateFromFilter, setDateFromFilter] = useState(() => {
    let MyDateString =
      (
        "0" +
        new Date(new Date().getFullYear(), new Date().getMonth(), 1).getDate()
      ).slice(-2) +
      "/" +
      (
        "0" +
        (new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1
        ).getMonth() +
          1)
      ).slice(-2) +
      "/" +
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .getFullYear()
        .toLocaleString();

    return state != undefined
      ? state.dateFromFilter
      : MyDateString.replaceAll(".", "")
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .join("-");
  });
  const [dateToFilter, setDateToFilter] = useState(() => {
    return state != undefined
      ? state.dateToFilter
      : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
          .toLocaleDateString()
          .replaceAll("/", "-")
          .split("-")
          .reverse()
          .map((d) => (d.length <= 1 ? "0" + String(d) : d))
          .join("-");
  });

  async function fetchReport() {
    setIsFetching(true);
    
    try {
      let collectionsReportRequest = await axiosAuth.get(
        "/collectionsreport?dateFrom=" +
          dateFromFilter +
          "&dateTo=" +
          dateToFilter
      );
  
      if (collectionsReportRequest.data.error) {
        if (collectionsReportRequest.data.message === "token") {
          dispatch(setUser(null));
          dispatch(setToken(null));
        }
        setIsFetching(false);
        return;
      }
  
     
  
      setSales(collectionsReportRequest.data.sales);
      setCollections(collectionsReportRequest.data.collections.rows);
  
      let salesTotalSum = 0;
      let collectionsTotalSum = 0;
  
      collectionsReportRequest.data.sales.map((sale) => {
        salesTotalSum +=
          sale.vat5 + sale.taxed5 + sale.vat10 + sale.taxed10 + sale.exempt;
      });
  
      collectionsReportRequest.data.collections.rows.map((collection) => {
        collectionsTotalSum += collection.amount;
      });
  
      setSalesTotal(salesTotalSum);
      setCollectionsTotal(collectionsTotalSum);
    } catch (error) {
      console.error("Error fetching report cobros:", error);
    // Opcional: puedes mostrar un mensaje de error al usuario aquí
    }finally{
      setIsFetching(false);
    }

    
  }

  return (
    <>
      <Layout>
        <div className="flex flex-col lg:flex-row border border-gray-200 border-r-0 border-b-1">
          <div className="w-[100%] sm:w-[50%] lg:w-[25%] border-none sm:border border-b-0 border-r-2 h-[98%]">
            <h1 className="ml-5 mt-2 text-[20px] secondary-color title font-family-thin">
              Reporte de Cobros
            </h1>
            <DateComponent className="!text-[15px] sm:block hidden" />
          </div>

          <div className="flex relative right-[-30px] sm:right-[0px] flex-row justify-start items-start ml-0 sm:ml-[8%] w-[100%] sm:w-[100%] lg:w-[75%] mt-1">
            <div className="flex flex-row justify-start items-center w-[50%] lg:w-[25%]">
              <div className="mr-5 w-[100%]">
                <select
                  id="typeFilter"
                  name="typeFilter"
                  value={type}
                  className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm h-[22px] uppercase !text-[11px]"
                  style={{ lineHeight: "13px" }}
                  onChange={(e) => {
                    setSales([]);
                    setCollections([]);
                    seType(e.target.value);
                  }}
                >
                  <option value="0">Todos</option>
                  <option value="1">Contado</option>
                  <option value="2">Credito</option>
                </select>

                <input
                  type="date"
                  id="dateFromFilter"
                  name="dateFromFilter"
                  value={dateFromFilter}
                  className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                  onChange={(e) => {
                    setSales([]);
                    setCollections([]);
                    setDateFromFilter(e.target.value);
                  }}
                />

                <input
                  type="date"
                  id="dateToFilter"
                  name="dateToFilter"
                  value={dateToFilter}
                  className="input-bg-color appearance-none border w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[22px] font-[11px] mt-[1px]"
                  onChange={(e) => {
                    setSales([]);
                    setCollections([]);
                    setDateToFilter(e.target.value);
                  }}
                />
              </div>
            </div>

            <div
              className="flex flex-row sm:justify-center mt-1 sm:mt-0 pl-0 sm:pl-8 w-[60%] sm:w-[50%] lg:w-[69%] mt-2.5 h-[75px] mr-0 lg:mr-[8%] overflow-x-auto overflow-y-hidden overflow-visible"
              style={{ margin: "0px auto" }}
            >
              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {
                    setSales([]);
                    setCollections([]);
                    fetchReport();
                  }}
                >
                  <img src={search} className="w-8" />
                  CONSULTAR
                </button>
              </div>

              <div className="flex flex-col items-center h-[70px]">
                <DownloadTableExcel
                  filename={"REPORTE_COBROS_" + new Date().toLocaleDateString()}
                  sheet="REPORTE-COBROS"
                  currentTableRef={tableRef.current}
                >
                  <button
                    type="button"
                    className="hidden sm:inline-flex flex-col w-[85px] h-[70px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px] mr-[2px]"
                  >
                    <img src={xls} className="w-6" />
                    PLANILLA ELECTRONICA
                  </button>
                </DownloadTableExcel>
              </div>

              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="hidden sm:inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {}}
                >
                  <img src={printer} className="w-8" />
                  IMPRIMIR
                </button>
              </div>

              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="hidden sm:inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {}}
                >
                  <img src={pdf} className="w-8" />
                  PDF
                </button>
              </div>

              <div className="flex flex-col items-center h-[70px]">
                <button
                  type="button"
                  className="inline-flex flex-col w-[85px] h-[70px] mr-[2px] justify-center items-center rounded ternary-bg-color p-4 text-xs text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-400 sm:mt-0 !text-[11px]"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img src={door} className="w-6" />
                  SALIR
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`w-[98%] p-3 pb-0 mx-auto overflow-auto max-h-[400px] relative`}
        >
          <table
            className="w-[98%] table-auto mx-auto w-full text-xs sales-report-table relative"
            ref={tableRef}
          >
            {(sales.length > 0 || collections.length > 0) && !isFetching && (
              <thead className="bg-gray-600 !text-white hidden sm:table-header-group">
                <tr className="w-full">
                  <th className="!text-white uppercase w-[40%]" style={{border:"1px solid"}}>NRO. DOC.</th>
                  <th className="!text-white uppercase">FECHA</th>
                  <th className="!text-white uppercase">CLIENTE</th>
                  <th className="!text-white uppercase w-[20%]">MONTO</th>
                </tr>
              </thead>
            )}

            <tbody>
              {isFetching && (
                <tr>
                  <td></td>
                  <td colSpan={20}>
                    <Lottie
                      animationData={loadingAnimation}
                      style={{ width: 100, height: 100, margin: "0 auto" }}
                      loop={true}
                    />
                  </td>
                </tr>
              )}

              {(sales.length > 0 || collections.length > 0) && !isFetching && (
                <>
                  {type == 1 ||
                    (type == 0 && (
                      <>
                        <tr>
                          <td></td>
                          <td
                            className="underline text-[14px] whitespace-nowrap text-left"
                            colSpan={2}
                          >
                            <b>FACTURAS CONTADO</b>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>

                        {sales.map((sale) => {
                          let total =
                            Number(sale.vat5) +
                            Number(sale.taxed5) +
                            Number(sale.vat10) +
                            Number(sale.taxed10) +
                            Number(sale.exempt);

                          return (
                            <tr class="text-[11px] sm:text-[12px]">
                              <td className="w-[30%] text-right">
                                {sale.expeditionPoint} {sale.number}
                              </td>
                              <td>
                                {sale.updatedAt
                                  .substr(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </td>
                              <td className="text-left whitespace-nowrap">
                                {sale?.client?.name}
                              </td>
                              <td className="text-center">
                                {numberWithCommas(total)}
                              </td>
                            </tr>
                          );
                        })}

                        <tr class="text-[11px] sm:text-[12px]">
                          <td></td>
                          <td
                            className="text-right whitespace-nowrap"
                            colSpan={2}
                          >
                            <b>TOTAL FACTURAS CONTADO: </b>
                          </td>
                          <td className="text-center border-t-[1px] border-gray-400">
                            <b>{numberWithCommas(salesTotal)}</b>
                          </td>
                        </tr>
                      </>
                    ))}
                  <tr>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                  {type == 2 ||
                    (type == 0 && (
                      <>
                        <tr>
                          <td></td>
                          <td
                            className="underline text-[14px] whitespace-nowrap text-left"
                            colSpan={2}
                          >
                            <b>RECIBOS DE DINERO</b>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>

                        {collections.map((collection) => {
                          return (
                            <tr className="text-[11px] sm:text-[12px]">
                              <td className="w-[30%] text-right">
                                {collection.expeditionPoint} {collection.number}
                              </td>
                              <td>
                                {collection.createdAt
                                  .substr(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                              </td>
                              <td className="text-left whitespace-nowrap">
                                {collection.client.name}
                              </td>
                              <td className="text-right">
                                {numberWithCommas(collection.amount)}
                              </td>
                            </tr>
                          );
                        })}
                        <tr className="text-[11px] sm:text-[12px]">
                          <td></td>
                          <td
                            className="text-right whitespace-nowrap"
                            colSpan={2}
                          >
                            <b>TOTAL RECIBOS DE DINERO: </b>
                          </td>
                          <td className="text-center border-t-[1px] border-gray-400">
                            <b>{numberWithCommas(collectionsTotal)}</b>
                          </td>
                        </tr>
                      </>
                    ))}
                </>
              )}

              {(sales.length > 0 || collections.length > 0) && !isFetching && (
                <>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr style={{ lineHeight: "24px" }}>
                    <td></td>
                    <td className="text-right text-[15px]" colSpan={2}>
                      <b>TOTAL GENERAL: </b>
                    </td>
                    <td className="text-center text-[15px] border-t-[1px] border-b-[1px] border-gray-400">
                      <b>{numberWithCommas(salesTotal + collectionsTotal)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="border-t-[1px] border-gray-400"></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="border-t-[1px] border-gray-400"></td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </Layout>
    </>
  );
};

export default CollectionsReport;
