// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKOsjxD941ULMCwga4qBbiuKNaTduZW_E",
  authDomain: "ocus-febf6.firebaseapp.com",
  projectId: "ocus-febf6",
  storageBucket: "ocus-febf6.appspot.com",
  messagingSenderId: "79262879196",
  appId: "1:79262879196:web:c45b537cdd83b16501d319",
  measurementId: "G-YEMMRPK1FF"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };